import React from "react";
import '../../styles/mobileVersion/Mcurrency.css'
import {Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

function MCurrency(){
    //translation
    const {t} = useTranslation()

    const user = useSelector((state: any) => state.user)
    return (
        <>
            <div
                className="w-full flex flex-col justify-start items-center gap-[20px] bg-mobileBackground bg-center bg-cover">
                <div
                    className="w-full flex flex-col justify-center items-center gap-[20px] h-[200px] px-[20px] relative">
                    <h1 className="text-[white] text-[30px] font-[400]">
                        {t('mobile.currency')}
                    </h1>
                </div>
            </div>
            <div className="w-full flex flex-col justify-start items-center px-[20px] pt-[20px] pb-[100px] gap-[10px]">
                {user.user.currency.map((currency: any, index: number) => (
                    <Button
                        key={index}
                        fullWidth
                        size='large'
                        variant='contained'
                        color='white'
                    >
                        <div className="w-full flex justify-between">
                            <p>{currency.name}</p>
                            <p>{currency.value} <span>{currency.code}</span></p>
                        </div>
                    </Button>
                ))
                }
            </div>
        </>
    );
}

export default MCurrency;