import React, {useEffect, useState} from 'react';
import '../../../styles/desktopVersion/notification.css'
import {
    DataGrid,
    GridFooterContainer,
} from "@mui/x-data-grid";
import {IconButton, Pagination} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {useTranslation} from "react-i18next";
import {DeliveryService} from "../../../services/DeliveryService";
import moment from "moment";
import {DeliveryTableI} from "../../../models/DeliveryTableI";
import {DeliveryI} from "../../../models/DeliveryI";
import {useNavigate} from "react-router-dom";

const tableFilterInitialState = {
    page: 1,
    updateTable: false,
}

function Delivery() {
    //translation
    const {t} = useTranslation()
    const navigate = useNavigate()

    const [table, setTable] = useState<DeliveryTableI>({
        selectedRows: [],
        filter: tableFilterInitialState,
        rows: [],
        columns:
            [
                {field: 'id', headerName: 'ID', flex: 1, sortable: false},
                {field: 'created_at', headerName: `${t('order_table.created_at')}`, flex: 1, sortable: false},
                {field: 'phone', headerName: `${t('order_table.phone')}`, flex: 1, sortable: false},
                {field: 'address', headerName: `${t('order_table.address')}`, flex: 1, sortable: false},
                {
                    field: 'status',
                    headerName: `${t('order_table.status')}`,
                    flex: 1,
                    sortable: false,
                    renderCell: (params) => (
                        params.row.status.name
                    )
                },
                {
                    field: 'actions',
                    headerName: `${t('order_table.actions')}`,
                    width: 100,
                    sortable: false,
                    renderCell: (params) => (
                        params.row.status.status !== 'atTheCourier' &&
                        <IconButton color="secondary" aria-label="add an alarm" onClick={() => {
                            DeliveryService.DeleteDelivery(params.row.id).then(() => {
                                setTable({
                                    ...table,
                                    filter:{
                                        ...table.filter,
                                        updateTable: !table.filter.updateTable,
                                    }
                                })
                            })
                        }}>
                            <DeleteIcon style={{color: "#B9B9B9"}}/>
                        </IconButton>
                    )
                },
            ]
    })

    const deliveryList = DeliveryService.GetDeliveryList(table.filter)

    useEffect(() => {
        if (!deliveryList.loading && !deliveryList.error) {
            const data: DeliveryI[] = []
            for (let i = 0; i < deliveryList.result?.data.results.length; i++) {
                let delivery = deliveryList.result?.data.results[i]
                data[i] = {
                    id: delivery.id,
                    created_at: moment(delivery.created_at).format('DD.MM.YYYY').toString(),
                    phone: delivery.phone,
                    address: delivery.address,
                    status: delivery.status,
                }
            }
            setTable((prevState) => ({
                ...prevState,
                rows: data
            }))
        }
    }, [deliveryList.error, deliveryList.loading, deliveryList.result?.data.results])

    return (
            <>
                <h1 className='text-[30px] text-black font-[400] mb-[50px]'>{t('delivery.title')}</h1>
                <div className="notificationPage__mainContainer w-full">
                    <DataGrid
                        rows={table.rows}
                        columns={table.columns}
                        disableColumnFilter
                        disableColumnMenu
                        loading={deliveryList.loading}
                        autoHeight
                        keepNonExistentRowsSelected
                        disableSelectionOnClick
                        localeText={{
                            noRowsLabel: "Нет информации",
                          }}
                        onRowDoubleClick={(params)=>{navigate(`/delivery/${params.row.id}`)}}
                        components={{
                            Footer: () => (
                                <GridFooterContainer>
                                    <Pagination
                                        count={!deliveryList.loading && !deliveryList.error ? deliveryList.result?.data.total_pages : 1}
                                        page={!deliveryList.loading && !deliveryList.error ? deliveryList.result?.data.current_page : 1}
                                        onChange={(event, value: number) => {
                                            setTable({
                                                ...table,
                                                filter:{
                                                    ...table.filter,
                                                    page: value,
                                                }
                                            })
                                        }}
                                    />
                                </GridFooterContainer>
                            )
                        }}
                    />
                </div>
            </>
    );
}

export default Delivery;