import React, {useState} from 'react';
import CalculateIcon from "@mui/icons-material/Calculate";
import {Button, FormControl, FormControlLabel, IconButton, Modal, Switch, TextField} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const Calculator = ({address, variant, mobile}: any) => {
    const modalInitialValues = {
        open: false,
        values: {
            tariff: address?.tariff,
            delivery_period: address?.delivery_period,
            weight: '',
            length: '',
            width: '',
            height: '',
        },
        cube: false,
        submitted: false,
    };
    const [modal, setModal] = useState<any>(modalInitialValues);

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        setModal({
            ...modal,
            submitted: true,
        })
    }

    return (
        <>
            <Button size={mobile ? 'small' : 'large'} startIcon={<CalculateIcon />}
                    onClick={()=>{
                        setModal({
                            ...modalInitialValues,
                            open: true,
                        })
                    }}
                    variant={variant}
            >
                Калькулятор
            </Button>

            <Modal
                open={modal.open}
                onClose={() => setModal(modalInitialValues)}
                sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingX: '20px'}}
            >
                <div className={mobile ? `relative w-full flex flex-col justify-start items-center gap-[10px] bg-white p-[20px]` : 'mainModal relative flex flex-col justify-start items-center'}>
                    <IconButton
                        sx={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                        }}
                        onClick={() => setModal(modalInitialValues)}
                    >
                        <CloseIcon/>
                    </IconButton>
                    <h2 className="text-[30px] font-[600] text-[#1E1C2A] mb-[40px]">
                        Калькулятор
                    </h2>
                    <form onSubmit={handleFormSubmit} className='w-full flex flex-col justify-start items-start gap-[20px]'>
                        <FormControlLabel
                            control={
                                <Switch checked={modal.cube} onClick={()=>{
                                    setModal({
                                        ...modal,
                                        cube: !modal.cube,
                                        values: {
                                            ...modal.values,
                                            weight: '',
                                            length: '',
                                            width: '',
                                            height: '',
                                        },
                                        submitted: false
                                    })
                                }} />
                            }
                            label="Крупногабаритные посылки"
                        />
                        {modal.cube
                            ?
                            <div className={mobile ? `w-full grid grid-cols-2 gap-[20px]` : 'w-full flex items-center gap-[10px] mb-[30px]'}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Длина"
                                        fullWidth
                                        type='number'
                                        size='small'
                                        required={modal.cube}
                                        value={modal.values.length}
                                        onChange={(event) => {
                                            setModal({
                                                ...modal,
                                                values: {
                                                    ...modal.values,
                                                    length: event.target.value,
                                                }
                                            })
                                        }}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Ширина"
                                        fullWidth
                                        type='number'
                                        size='small'
                                        required={modal.cube}
                                        value={modal.values.width}
                                        onChange={(event) => {
                                            setModal({
                                                ...modal,
                                                values: {
                                                    ...modal.values,
                                                    width: event.target.value,
                                                }
                                            })
                                        }}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Высота"
                                        fullWidth
                                        type='number'
                                        size='small'
                                        required={modal.cube}
                                        value={modal.values.height}
                                        onChange={(event) => {
                                            setModal({
                                                ...modal,
                                                values: {
                                                    ...modal.values,
                                                    height: event.target.value,
                                                }
                                            })
                                        }}
                                    />
                                </FormControl>
                                <TextField
                                    fullWidth
                                    label="См"
                                    disabled
                                    size='small'
                                    sx={{minWidth: 60}}
                                />
                            </div>
                            :
                            <div className='w-full flex items-center gap-[10px]'>
                                <TextField
                                    label="Вес"
                                    type='number'
                                    fullWidth
                                    size='small'
                                    required={!modal.cube}
                                    value={modal.values.weight}
                                    onChange={(event) => {
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                weight: event.target.value,
                                            }
                                        })
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    label="Кг"
                                    disabled
                                    size='small'
                                    sx={{width: 60}}
                                />
                            </div>
                        }
                        {!modal.submitted
                            ?
                            <div className="w-full flex justify-center items-center">
                                <Button
                                    variant='contained'
                                    color='primary'
                                    type='submit'
                                >
                                    Рассчитать стоимость
                                </Button>
                            </div>
                            :
                            <div className="w-full flex flex-col justify-start items-start gap-[20px]">
                                <div className="w-full flex flex-col justify-start items-start gap-[20px]">
                                    <div className="w-full flex justify-between items-center gap-[30px]">
                                        <p className="text-[#E52EC7] text-[14px] font-[400]">
                                            {modal.values.delivery_period}
                                        </p>
                                        <div className="text-[Итого:] text-[20px] font-[500] gap-[5px] flex items-center"><p>Итого:</p>
                                            <p>{modal.cube
                                                ? (modal.values.tariff * ((modal.values.width * modal.values.height * modal.values.length) / 6000)).toFixed(1)
                                                : (modal.values.tariff * modal.values.weight).toFixed(1)
                                            }</p>
                                            <p>USD</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </form>
                </div>
            </Modal>
        </>
    );
};

export default Calculator;