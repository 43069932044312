import React, {useEffect, useState} from 'react';
import '../../../styles/desktopVersion/home.css'
import {createSearchParams, useNavigate} from "react-router-dom";
import {
    Button,
    FormControl, IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Pagination,
    Select,
    Stack,
    TextField
} from "@mui/material";
import Shop2Icon from "@mui/icons-material/Shop2";
import CircularProgress from "@mui/material/CircularProgress";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import {DataGrid, GridFooterContainer} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import {useTranslation} from 'react-i18next'
import {getCookie} from "typescript-cookie";
import {OrderService} from "../../../services/OrderService";
import {AddressesService} from "../../../services/AddressesService";
import {StoresService} from "../../../services/StoresService";
import {OrdersTableI} from "../../../models/OrdersTableI";
import {OrderI} from "../../../models/OrderI";

const tableFilterInitialState = {
    date_from: '',
    date_to: '',
    country: '',
    store: '',
    search: '',
    page: 1,
    status__status: 'showAll',
    updateTable: false,
}

function Orders() {
    //language code
    const currentLanguageCode = getCookie('i18next') || 'ru'

    //translation
    const {t}: any = useTranslation();

    const navigate = useNavigate();

    const [ordersTitle, setOrdersTitle] = useState(currentLanguageCode === 'en' ? 'Show all' : 'Показать все')

    // table info
    const [table, setTable] = useState<OrdersTableI>({
        selectedRows: [],
        filter: tableFilterInitialState,
        rows: [],
        columns: [
            {field: 'id', headerName: 'ID', width: 80, hide: true, sortable: false},
            {field: 'created_at', headerName: t('order_table.created_at'), sortable: false, flex: 1},
            {field: 'store', headerName: t('order_table.store'), sortable: false, flex: 1},
            {field: 'weight', headerName: t('order_table.weight'), sortable: false, flex: 1},
            {
                field: 'type_of_transport',
                headerName: t('order_table.type_of_transport'),
                sortable: false,
                flex: 1,
                renderCell: (params) => (
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <img src={params.row.type_of_transport} alt="icon"/>
                    </div>
                )
            },
            {field: 'country', headerName: t('order_table.country'), sortable: false, flex: 1},
            {field: 'tracking_number', headerName: t('order_table.tracking_number'), sortable: false, flex: 1},
            {
                field: 'status',
                headerName: t('order_table.status'),
                sortable: false,
                flex: 1,
                renderCell: (params) => (
                    <div style={{width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}
                         onClick={(event) => {
                             event.stopPropagation()
                         }}>
                        <img src={params.row.status.icon} alt="icon"/>
                        {params.row.status.status === 'inProcess' &&
                            <IconButton color="secondary" aria-label="add an alarm" onClick={() => {
                                navigate({
                                    pathname: "/orders/details",
                                    search: createSearchParams({
                                        action: "edit",
                                        order_id: params.row.id,
                                    }).toString(),
                                });
                            }}>
                                <EditIcon style={{color: "#B9B9B9"}}/>
                            </IconButton>
                        }
                        {params.row.status.status === 'inProcess' &&
                            <IconButton color="secondary" aria-label="add an alarm" onClick={() => {
                                OrderService.DeleteOrder(params.row.id).then(() => {
                                    window.location.reload()
                                })
                            }}>
                                <DeleteIcon style={{color: "#B9B9B9"}}/>
                            </IconButton>
                        }
                    </div>
                )
            },
        ]
    })

    //requests
    const orders = OrderService.GetOrders(table.filter)
    const getOrderStatuses = OrderService.GetOrderStatusButtons()
    const getCountries = AddressesService.GetCountries()
    const getStores = StoresService.GetStores()

    useEffect(() => {
        if (!orders.loading && !orders.error) {
            const data: OrderI[] = []
            for (let i = 0; i < orders.result?.data.results.length; i++) {
                let order = orders.result?.data.results[i]
                data[i] = {
                    id: order.id,
                    created_at: (moment(order.created_at)).format('DD.MM.YYYY').toString(),
                    store: order.store?.name,
                    weight: (order.rounded_weight && `${order.rounded_weight} кг`),
                    type_of_transport: order.type_of_transport.icon,
                    country: order.country.name,
                    tracking_number: order.tracking_number,
                    status: order.status,
                }
            }
            setTable((prevState) => ({
                ...prevState,
                rows: data
            }))
        }
    }, [orders.error, orders.loading, orders.result?.data.results])

    return (
        <>
            <div className='w-full flex items-center justify-start gap-[20px] mb-[40px] mt-[40px]'>
                <Button variant='contained' color='blue' onClick={() => {
                    navigate({
                        pathname: "/orders/details",
                        search: createSearchParams({
                            action: "add",
                        }).toString(),
                    });
                }}>
                    {t('button.add_order')}
                </Button>
                <Button variant='contained'  color='purple' onClick={() => {
                    navigate('/delivery/add')
                }}>
                    {t('button.arrange_delivery')}
                </Button>
            </div>
            <div className="homePage__orderStatus">
                <h1 className='text-[30px] text-black font-[400] mb-[50px]'>{t('main.status_buttons_title')}</h1>
                <div className="orderStatus__buttons">
                    <div className={table.filter?.status__status === 'showAll'
                        ? `orderStatus__button orderStatus__buttonAll orderStatus__buttonSelected`
                        : `orderStatus__button orderStatus__buttonAll`}
                         onClick={() => {
                             setTable({
                                 ...table,
                                 filter: {
                                     ...table.filter,
                                     status__status: 'showAll',
                                     page: 1,
                                 }
                             })
                             setOrdersTitle(currentLanguageCode === 'en' ? 'Show all' : 'Показать все')
                         }}>
                        <Shop2Icon sx={{color: '#48A5D1'}}/>
                        <p className="orderStatus__buttonText">{t('main.show_all_button')}</p>
                        <p className="orderStatus__buttonNum">
                            {!getOrderStatuses.loading && !getOrderStatuses.error && getOrderStatuses.result?.data.map((result: any) => result.count).reduce((partialSum: any, a: any) => partialSum + a, 0)}
                        </p>
                    </div>
                    {getOrderStatuses.loading
                        ? <CircularProgress/>
                        : getOrderStatuses.error
                            ? <div>Error</div>
                            : getOrderStatuses.result?.data.map((item: any) => (
                                <div className={table.filter?.status__status === item.status
                                    ? `orderStatus__button  orderStatus__buttonSelected`
                                    : `orderStatus__button`}
                                     id={item.id}
                                     key={item.id}
                                     onClick={() => {
                                         setTable({
                                             ...table,
                                             filter: {
                                                 ...table.filter,
                                                 status__status: item.status,
                                                 page: 1,
                                             }
                                         })
                                         setOrdersTitle(item.name)
                                     }}>
                                    <img src={item.icon} alt="icon"/>
                                    <p className="orderStatus__buttonText">{item.name}</p>
                                    <p className="orderStatus__buttonNum">{item.count}</p>
                                </div>
                            ))
                    }
                </div>
            </div>
            <div className="homePage__orderTableBox">
                <h1 className='text-[30px] text-black font-[400] mb-[50px]'>{ordersTitle}</h1>
                <div className="orderTableBox__filterBox">
                    <div className="filterBox__filter">
                        <Stack direction="row" spacing={2}>
                            <DatePicker
                                label={t('filter.date_from')}
                                renderInput={(params: any) =>
                                    <TextField sx={{width: '160px'}} size="small" {...params} />}
                                value={table.filter?.date_from}
                                onChange={(value) => {
                                    setTable({
                                        ...table,
                                        filter: {
                                            ...table.filter,
                                            date_from: moment(value).format('YYYY-MM-DD').toString(),
                                            page: 1,
                                        }
                                    })
                                }}
                            />
                            <DatePicker
                                label={t('filter.date_to')}
                                renderInput={(params: any) =>
                                    <TextField sx={{width: '160px'}} size="small" {...params} />}
                                value={table.filter?.date_to}
                                onChange={(value) => {
                                    setTable({
                                        ...table,
                                        filter: {
                                            ...table.filter,
                                            date_to: moment(value).format('YYYY-MM-DD').toString(),
                                            page: 1,
                                        }
                                    })
                                }}
                            />
                            <FormControl sx={{width: '160px'}} size="small">
                                <InputLabel id="country-select-label">{t('filter.country')}</InputLabel>
                                <Select
                                    labelId="country-select-label"
                                    id="country-select"
                                    value={table.filter?.country}
                                    label={t('filter.country')}
                                    onChange={(event) => {
                                        setTable({
                                            ...table,
                                            filter: {
                                                ...table.filter,
                                                country: event.target.value,
                                                page: 1,
                                            }
                                        })
                                    }}
                                    defaultValue=''
                                >
                                    <MenuItem value=''><p
                                        style={{fontStyle: 'italic'}}>{t('filter.filter_delete')}</p>
                                    </MenuItem>
                                    {getCountries.loading
                                        ? <div>loading</div>
                                        : getCountries.error
                                            ? <div>Error</div>
                                            : getCountries.result?.data.map((item: any) => (
                                                <MenuItem key={item.id} id={item.id}
                                                          value={item.id}>{item.name}</MenuItem>
                                            ))
                                    }
                                </Select>
                            </FormControl>
                            <FormControl sx={{width: '160px'}} size="small">
                                <InputLabel id="shop-select-label">{t('filter.store')}</InputLabel>
                                <Select
                                    labelId="shop-select-label"
                                    id="shop-select"
                                    value={table.filter?.store}
                                    label={t('filter.store')}
                                    onChange={(event) => {
                                        setTable({
                                            ...table,
                                            filter: {
                                                ...table.filter,
                                                store: event.target.value,
                                                page: 1,
                                            }
                                        })
                                    }}
                                    defaultValue=''
                                >
                                    <MenuItem value=''><p
                                        style={{fontStyle: 'italic'}}>{t('filter.filter_delete')}</p>
                                    </MenuItem>
                                    {getStores.loading
                                        ? <div>loading</div>
                                        : getStores.error
                                            ? <div>Error</div>
                                            : getStores.result?.data.map((item: any) => (
                                                <MenuItem key={item.id} id={item.id}
                                                          value={item.id}>{item.name}</MenuItem>
                                            ))
                                    }
                                </Select>
                            </FormControl>
                        </Stack>
                    </div>
                    <div className="filterBox__search">
                        <TextField
                            label={t('filter.search')}
                            id="search-bar"
                            value={table.filter?.search}
                            sx={{width: '340px'}}
                            size='small'
                            InputProps={{
                                endAdornment: <InputAdornment position="start">
                                    <SearchIcon style={{fill: "#48A5D1"}}/>
                                </InputAdornment>,
                            }}
                            onChange={(event) => {
                                setTable({
                                    ...table,
                                    filter: {
                                        ...table.filter,
                                        search: event.target.value,
                                        page: 1,
                                    }
                                })
                            }}
                        />
                    </div>
                </div>
                <div style={{width: '100%', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)'}}>
                    <DataGrid
                        rows={table.rows}
                        columns={table.columns}
                        checkboxSelection
                        disableColumnFilter
                        disableColumnMenu
                        autoHeight
                        keepNonExistentRowsSelected
                        loading={orders.loading}
                        disableSelectionOnClick
                        localeText={{
                            noRowsLabel: "Нет информации",
                          }}
                        isRowSelectable={(params) => params.row.status_def === 'inProcess'}
                        onRowDoubleClick={(params) => {
                            navigate(`/orders/${params.row.id}`)
                        }}
                        selectionModel={table.selectedRows}
                        onSelectionModelChange={(selectionModel) => setTable({...table, selectedRows: selectionModel})}
                        components={{
                            Footer: () => (
                                <GridFooterContainer>
                                    <Pagination
                                        count={!orders.loading && !orders.error ? orders.result?.data.total_pages : 1}
                                        page={!orders.loading && !orders.error ? orders.result?.data.current_page : 1}
                                        onChange={(event, value: number) => {
                                            setTable({
                                                ...table,
                                                filter: {
                                                    ...table.filter,
                                                    page: value,
                                                }
                                            })
                                        }}
                                    />
                                    <Button
                                        onClick={() => {
                                            OrderService.DeleteSelectedOrders(table.selectedRows).then(() => {
                                                setTable({
                                                    ...table,
                                                    filter: {
                                                        ...table.filter,
                                                        page: 1,
                                                    }
                                                })
                                            })
                                        }}
                                        disabled={!(table.selectedRows.length > 0)}
                                        variant='contained'
                                        sx={{background: '#FF8080', margin: '0 10px 0 0'}}
                                    >
                                        {t('button.delete')}
                                    </Button>
                                </GridFooterContainer>
                            )
                        }}
                    />
                </div>
            </div>
        </>
    );
}

export default Orders;

