import React from "react";
import '../../../styles/mobileVersion/MmyOrders.css'
import {Button, IconButton, Menu, MenuItem, Pagination} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import {useTranslation} from "react-i18next";
import {DeliveryService} from "../../../services/DeliveryService";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment/moment";
import {EmptyData} from "../../../helpers/helpers";
import {DeliveryEmptyIcon} from "../../../common/common";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const tableInitialValues = {
    filter: {
        page: 1,
    }
}
export default function MDelivery() {
    //translation
    const {t} = useTranslation()
    const navigate = useNavigate()

    const [table, setTable] = React.useState<any>(tableInitialValues)

    const deliveryList = DeliveryService.GetDeliveryList(table.filter)

    return (
        <>
            <div
                className="w-full flex flex-col justify-start items-center gap-[20px] bg-mobileBackground bg-center bg-cover">
                <div
                    className="w-full flex flex-col justify-center items-center gap-[20px] h-[200px] px-[20px] relative">
                    <h1 className="text-[white] text-[30px] font-[400]">
                        {t('mobile.my_delivery')}
                    </h1>
                </div>
            </div>
            <div className="w-full flex flex-col justify-start items-center px-[20px] pt-[20px] pb-[100px] gap-[10px]">
                {deliveryList.loading
                    ? <CircularProgress style={{marginTop: '20px', marginBottom: '20px'}}/>
                    : deliveryList.error
                        ? <div>Error</div>
                        : deliveryList.result?.data.results.length === 0
                            ? <EmptyData
                                text={t('mobile.no_data')}
                                icon={<DeliveryEmptyIcon/>}
                            />
                            :
                            deliveryList.result?.data.results.map((item: any, index: number) => (
                                <Link
                                    key={index}
                                    to={`/delivery/${item.id}`}
                                    className="w-full flex flex-col rounded-[4px] shadow-md p-[10px]"
                                >
                                    <div className="w-full flex justify-between items-start gap-[20px] pb-[10px] mb-[10px]" style={{borderBottom: '1px solid gray'}}>
                                        <div className="flex flex-col justify-start items-start gap-[7px]">
                                            <p className='text-[] text-[12px] leading-3'>{t('header.delivery')} № <span
                                                className='text-[#48A5D1]'>{item.id}</span></p>
                                            <p className=' text-[12px] leading-3'>{t('order_table.status')}: <span className='text-[#48A5D1]'>{item.status?.name}...</span></p>
                                            <p className=' text-[12px] leading-3'>{t('arrange_delivery.payment_type')}: <span
                                                className='text-[#48A5D1]'>
                                                {item.payment?.payment}
                                            </span></p>

                                        </div>

                                        {item.status.status !== 'atTheCourier' &&
                                            <div onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault()
                                            }}>
                                                <PopupState variant="popover" popupId="demo-popup-menu">
                                                {(popupState) => (
                                                        <React.Fragment>
                                                            <IconButton size='small' {...bindTrigger(popupState)}
                                                                        sx={{padding: 0}}>
                                                                <MoreVertIcon/>
                                                            </IconButton>
                                                            <Menu {...bindMenu(popupState)}>
                                                                <MenuItem
                                                                    onClick={() => {
                                                                        DeliveryService.DeleteDelivery(item.id).then(() => {
                                                                            deliveryList.execute()
                                                                        })
                                                                    }}
                                                                    disableRipple
                                                                >
                                                                    <Button size='small'
                                                                            startIcon={<DeleteIcon
                                                                                fontSize='small'/>}>
                                                                        <p className='text-[12px]'>{t('button.delete')}</p>
                                                                    </Button>
                                                                </MenuItem>
                                                            </Menu>
                                                        </React.Fragment>
                                                    )}
                                                </PopupState>
                                            </div>
                                        }

                                    </div>

                                    <div className="w-full flex justify-between items-start gap-[10px]">
                                        <p className='text-[12px] text-[#48A5D1] font-[400]'>{t('order_table.created_at')}: {(moment(item.created_at)).format('DD.MM.YYYY').toString()}</p>
                                    </div>
                                </Link>
                            ))
                }
                <Pagination
                    style={{marginTop: '20px'}}
                    count={!deliveryList.loading && !deliveryList.error ? deliveryList.result?.data.total_pages : 1}
                    page={!deliveryList.loading && !deliveryList.error ? deliveryList.result?.data.current_page : 1}
                    onChange={(event, value: number) => {
                        setTable({
                            ...table,
                            filter: {
                                ...table.filter,
                                page: value
                            }
                        })
                    }}
                />
            </div>
        </>
    );
}

