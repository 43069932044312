import React from "react";
import "../../styles/mobileVersion/MnotificationsView.css";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment/moment";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { NotificationsService } from "../../services/NotificationsService";

function MNotificationsView() {
  //translation
  const { t } = useTranslation();

  let { id } = useParams();
  let notificationsInfo =
    NotificationsService.GetNotificationsInfoByUseAsync(id);

  return (
    <>
      <div className="w-full flex flex-col justify-start items-center gap-[20px] bg-mobileBackground bg-center bg-cover">
        <div className="w-full flex flex-col justify-center items-center gap-[20px] h-[200px] px-[20px] relative">
          <h1 className="text-[white] text-[30px] font-[400]">
            {t("notification.title")}
          </h1>
        </div>
      </div>
      <div className="w-full flex flex-col justify-start items-center px-[20px] pt-[20px] pb-[100px] gap-[10px]">
        {notificationsInfo.loading ? (
          <CircularProgress
            style={{ marginTop: "20px", marginBottom: "20px" }}
          />
        ) : (
          !notificationsInfo.error && (
            <div className="w-full flex flex-col justify-start items-start">
              <div className="MNotificationsView__title">
                <p>{notificationsInfo.result?.data.notification.title}</p>
                <span>
                  {moment(
                    notificationsInfo.result?.data?.notification?.created_at
                  )
                    .format("DD.MM.YYYY HH:mm")
                    .toString()}
                </span>
              </div>
              <div className="MNotificationsView__text">
                {parse(notificationsInfo.result?.data.notification.message)}
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
}

export default MNotificationsView;
