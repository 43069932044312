import React from "react";
import "../../styles/desktopVersion/footer.css";
import logo from "../../assets/images/GlobalExpress-logo-footer.png";

import instagram from "../../assets/images/socials-instagram.png";
import telegram from "../../assets/images/socials-telegram.png";
import tiktok from "../../assets/images/socials-tiktok.png";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="w-full flex flex-col justify-start items-start" id="footer">
      <div className="footer__upperContainer px-[24px]">
        <div className="footer__upperContainerWrapper">
          <div className="footer__logoBox">
            <img src={logo} alt="logo" className="footer__logo h-[60px]" />

            <p className="footer__logoText">{t("footer.text")}</p>
          </div>
          <div className="footer__contactBox">
            <p className="footer__contactTitle">{t("footer.contacts")}</p>
            <span className="footer__contactLink">
              г. Бишкек, улица Байтик Баатыра 29
            </span>
            <a className="footer__contactLink" href="tel:+996500004468">
              +996 500 004 468
            </a>
            <a className="footer__contactLink" href="tel:+996995004468">
              +996 995 004 468
            </a>
            <a className="footer__contactLink" href="tel:+996997000123">
              +996 997 000 123
            </a>
          </div>
          <div className="footer__scheduleBox">
            <p className="footer__scheduleTitle">{t("footer.work_schedule")}</p>
            <p className="footer__scheduleText">
              {t("footer.without_weekend")}
            </p>
            <p className="footer__scheduleText">
              {t("footer.from")} 10:00 {t("footer.to")} 20:00
            </p>
          </div>
          <div className="footer__socialMediaBox">
            <a
              href="https://www.instagram.com/global_express_kg?igsh=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr"
              className="footer__socialMediaLink"
            >
              <img src={instagram} alt="img" />
            </a>
            <a
              href="https://t.me/globalexpresskg"
              className="footer__socialMediaLink"
            >
              <img src={telegram} alt="img" />
            </a>
            <a
              href="https://www.tiktok.com/@global.express?_t=8lZiJjiIFIg&_r=1"
              className="footer__socialMediaLink"
            >
              <img src={tiktok} alt="img" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer__subContainer">
        <p>
          Developed by{" "}
          <a
            href="https://wa.me/996999099001"
            className="text-[white] ml-[5px] underline"
          >
            {" "}
            Upwards
          </a>
        </p>
        <p
          className="text-[white] cursor-pointer"
          onClick={() => navigate("/agreement")}
        >
          {t("input_labels.user_agreement")}
        </p>
      </div>
    </div>
  );
}

export default Footer;
