import React from "react";
import '../styles/agreement.css'
function Agreement(){

    return (
        <div className='agreement' id='agreement'>
            <div className="agreement__wrapper">
                <div className='agreement_mainBox' >
                    <>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "0cm",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      Глава 1. ОБЩИЕ УСЛОВИЯ
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      1. Нижеперечисленные положения и условия определяют и регулируют
      правоотношения, возникающие у Сторон при предоставлении услуг,
      предусмотренных веб-сайтом (далее Сайт) компании&nbsp;
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      Global
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      &nbsp;
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      Express
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      &nbsp;(далее Компания, Мы, Глобал).&nbsp;
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      2. Осуществляя вход на Сайт Компании, пользуясь любыми услугами Сайта или
      загружая как Пользователь (далее Пользователь, Вы, Вам, Ваш, Клиент) любые
      сведения о себе полностью или частично, а также иную информацию
      необходимое для оказания услуги, Вы подтверждаете, что полностью
      прочитали, ознакомлены и соглашаетесь с условиями пользования Сайтом, так
      же с размещенными на Сайте условиями предоставления услуг, а также
      положениями публичного агентского &nbsp;договора (далее Условия или
      Договор) и безоговорочно принимаете их, несмотря на то, что Вы клиент,
      зарегистрированный пользователь или посетитель Сайта.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      3.&nbsp;Компания оставляет за собой право без предварительного
      уведомления, в одностороннем порядке, в любой момент изменить условия,
      предусмотренные Договором. При изменении Условий Компания размещает на
      Сайте новые условия, при этом отметив дату последних внесенных изменений.
      Кроме этого, Компания не обязана уведомлять Пользователей Сайта о вносимых
      или внесенных изменениях, которое является обязанностью Пользователя, в
      связи с чем последний обязуется, самостоятельно посещать сайт и проверять
      изменения и актуальность Условий. Новые условия будут иметь юридическую
      силу и будут распространяться на обязательства Сторон со дня размещения на
      Сайте.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      4.&nbsp;В случае несогласия Пользователя с перечисленными Условиями,
      Пользователь вправе отказаться от использования Сайта. Пребывание на
      сайте, открытие счета, регистрация в качестве Пользователя и использование
      услуг Компании, является Вашим согласием (Акцептом) и подтверждением, что
      вы безоговорочно принимаете эти Условия.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "0cm",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      &nbsp;
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "0cm",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      Глава 2. ДЕЕСПОСОБНОСТЬ ПРИ ПОДПИСАНИИ ДОГОВОРА.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "0cm",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      &nbsp;
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      5.&nbsp;Соглашаясь с Условиями, вы подтверждаете, что в соответствии с
      Законами Вашей страны вы полностью дееспособны и имеете право пользоваться
      Услугами. Если по Законам Вашей страны Вы являйтесь ограниченно
      дееспособным и не можете самостоятельно пользоваться услугами, то Вы
      должны получить согласие Ваших родителей или других компетентных лиц,
      чтобы пользоваться Услугами Компании. В случае, если Вы представляете
      интересы юридического лица, то Вы обязаны удостоверить, наличие документов
      подтверждающие Ваши полномочия, предоставляемые юридическим лицом, а также
      пользоваться услугами в соответствии с Законами страны, где
      зарегистрировано юридическое лицо. Соглашаясь с настоящими Условиями, Вы
      принимайте во внимание, что Мы не имеем возможности проверить информацию и
      сведения предоставленные Вами на подтверждение их подлинности, в связи с
      чем, при любом нарушении с Вашей стороны данного пункта всю
      ответственность несете самостоятельно.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
                            <strong>
      <span
          style={{
              fontSize: 19,
              fontFamily: '"Times New Roman",serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0cm"
          }}
      >
        6.&nbsp;
      </span>
                            </strong>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm"
                                }}
                            >
      Право иностранных физических лиц на пользование Услугами&nbsp;
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm"
                                }}
                            >
      Global
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm"
                                }}
                            >
      &nbsp;
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm"
                                }}
                            >
      Express
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm"
                                }}
                            >
      : в соответствии с Кодексом КР от 24 апреля 2019 года № 52 «О таможенном
      регулировании в Кыргызской Республике», иностранное физическое лицо может
      выступать декларантом в отношении товаров для личного пользования только
      при документальном подтверждении своего постоянного или временного
      проживания на территории КР.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      7.&nbsp;Перечень документов, один из которых обязано предоставить
      иностранное физическое лицо для таможенного оформления:
    </span>
                        </p>
                        <ul style={{ listStyleType: "disc", marginLeft: "69.5px" }}>
                            <li>
      <span
          style={{
              fontFamily: '"Times New Roman",serif',
              fontSize: "14.0pt",
              color: "black",
              border: "none windowtext 1.0pt"
          }}
      >
        Вид на жительство
      </span>
                            </li>
                            <li>
      <span
          style={{
              fontFamily: '"Times New Roman",serif',
              fontSize: "14.0pt",
              color: "black",
              border: "none windowtext 1.0pt"
          }}
      >
        Удостоверение беженца
      </span>
                            </li>
                            <li>
      <span
          style={{
              fontFamily: '"Times New Roman",serif',
              fontSize: "14.0pt",
              color: "black",
              border: "none windowtext 1.0pt"
          }}
      >
        Справка о временной регистрации иностранца
      </span>
                            </li>
                            <li>
      <span
          style={{
              fontFamily: '"Times New Roman",serif',
              fontSize: "14.0pt",
              color: "black",
              border: "none windowtext 1.0pt"
          }}
      >
        Удостоверение лица без гражданства
      </span>
                            </li>
                            <li>
      <span
          style={{
              fontFamily: '"Times New Roman",serif',
              fontSize: "14.0pt",
              color: "black",
              border: "none windowtext 1.0pt"
          }}
      >
        Виза, выданное загранучреждениями Кыргызской Республики.
      </span>
                            </li>
                        </ul>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      В случае отсутствия указанных документов, Глобал оставляет за собой право
      отказа в предоставлении услуг и таможенном оформлении товара. При этом,
      при превышении порога на беспошлинный ввоз товара для личного пользования,
      иностранное физическое лицо обязуется предоставить Глобалу персональный
      идентификационный номер (ПИН/ИНН) присвоенный на территории Республики
      Казахстан. &nbsp;&nbsp;
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "12.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      &nbsp;
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "0cm",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      Глава 3. УСЛУГИ ПРЕДОСТАВЛЯЕМЫЕ&nbsp;
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm"
                                }}
                            >
      GLOBAL
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm"
                                }}
                            >
      &nbsp;
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm"
                                }}
                            >
      EXPRESS
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm"
                                }}
                            >
      &nbsp;И ПОРЯДОК ПРЕДОСТАВЛЕНИЯ УСЛУГ
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      8.&nbsp;Глобал предоставляет своим Пользователям услуги направленные для
      совершения покупок (далее Заказ) через онлайн магазины от имени и за счет
      Клиента и доставляет их Клиенту (далее услуги).
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      9.&nbsp;Чтобы воспользоваться всеми услугами, предоставляемыми Компанией,
      Вы должны зарегистрироваться на Сайте и открыть свой индивидуальный счет
      пользователя (далее счет пользователя или счет). Каждый Пользователь может
      иметь только один счет. Пользователи не имеют право использовать счета
      других Пользователя, а равно и передавать свои счета другим лицам.
      Открывая счет, Пользователь обязуется предоставить всю достоверную
      информацию необходимую для регистрации. Пользователи сами ответственны за
      хранение своих данных, доступа к счету и обязуются самостоятельно нести
      ответственность за действия/бездействия и деятельность по счету.
      Пользователь обязан немедленно сообщить Компании, если уверен или имеется
      вероятность, что на его счет был осуществлен неразрешенный вход или
      взломали. В таком случае Компания имеет право немедленно заблокировать
      счет Пользователя до полного выяснения всех обстоятельств. При любых
      обстоятельствах, Компания не несет какой-либо ответственности за действия
      других лиц по счету Пользователя. За нанесенный ущерб Компании или третьим
      лицам действиями, указанными выше, всю ответственность несет Пользователь,
      которому принадлежит счет.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      10.&nbsp;Услуги, предоставляемые Компанией включают в себя, но не
      ограничиваются следующим:
      <br />
        &nbsp;Покупка товаров из интернет магазинов от имени Пользователя и за его
      счет на основании заключенного Сторонами агентского договора. Организация
      транспортировки/доставки заказов клиента, купленные через Компанию.
      <br />
        &nbsp;Организация транспортировки/доставки заказов, купленные Клиентами
      самостоятельно.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      11.&nbsp;При заказе, Пользователь должен иметь необходимые денежные
      средства для приобретения данной покупки.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      12.&nbsp;Глобал, оставляет за собой право, самостоятельного выбора способа
      доставки посылок Пользователей предпочтительным для Компании путем, а
      также отказывать физическим и юридическим лицам в предоставлении услуг по
      перевозке документов или посылок не относящиеся к интернет покупкам. В
      случае изменения способа доставки посылок из-за форс-мажорных
      обстоятельств, Компания имеет право&nbsp;в одностороннем порядке
      произвести&nbsp;перерасчет тарифов.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      13.&nbsp;В случаях, если размер/вес Заказа Пользователя, будет
      являться/послужит причиной смены Компанией авиаперевозчика, или если Заказ
      может относится к опасным грузам или препятствовать отправке других
      Заказов, Компания имеет право отказаться от доставки Заказа или установить
      новый тариф для конкретного Заказа. При возникновении случаев, указанных в
      настоящем пункте, Компания имеет право потребовать от Пользователя оплаты
      всей стоимости доставки или предоставленных Услуг до отправки Заказа с
      международного склада по указанному Вами адресу. Компания оставляет за
      собой право отказаться от дальнейшей транспортировки посылок, принятых для
      доставки, в любое время, если эти посылки каким-либо образом ставят под
      угрозу или препятствуют транспортировке других отправлений, или если они
      представляют опасность для работников или оборудования, участвующих в
      процессе транспортировки, а также, если содержимое посылки запрещено
      Законом или правилами третьей стороны, используемой для перевозки.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      14. Глобал имеет право в период, когда общий объем заказов растет
      (праздничные дни, дисконтные программы или мероприятия и т.д.) изменить
      сроки поставки заказов, а также приостановить предоставление определенных
      услуг, в связи с чем Пользователь соглашается с настоящим пунктом и не
      имеет и не будет иметь каких-либо претензий к Глобалу.&nbsp;
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      15.&nbsp;Глобал оставляет за собой право приостановить предоставление
      Услуг, включая замораживание возможности распоряжения суммой, доступной на
      вашем Счете, в том числе доставку и передачу ваших Заказов, в случаях,
      когда транзакции на вашем счете вызовут сомнения относительно
      происхождения сумм на счете или законного использования Заказа. В таких
      случаях приостановление Услуг будет продолжаться до тех пор, пока Вы не
      обеспечите правовое происхождение средств, имеющихся на счете или
      назначение Заказа или завершение соответствующих процедур, предусмотрено
      законодательством КР.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "0cm",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      &nbsp;
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "0cm",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      Глава 4. ПРИЕМ ЗАКАЗОВ И ПОРЯДОК АДРЕСАЦИИ
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      16.&nbsp;Принятые к доставке посылки делятся на два класса: внутренние и
      международные.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      17.&nbsp;Внутренними посылками транспортируются товары, доставка которых
      не запрещена на территории Кыргызской Республики.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      18.&nbsp;Международными посылками транспортируются/доставляются товары, не
      запрещенные: к доставке на территорию какой-либо страны,&nbsp;к вывозу с
      территории какой-либо страны и доставке в страну назначения
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      19.&nbsp;Глобал имеет право отказать в приеме посылки, если она не
      упакована или не оформлена должным образом
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      20.&nbsp;Посылки принимаются к транспортировке в упакованном виде. Тем не
      менее, Компания имеет право проверить содержимое упакованной посылки с
      целью исключить наличие запрещенных к перевозке предметов.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      21.&nbsp;Упаковка посылки является исключительной ответственностью
      Пользователя услугами. Компания не несет ответственности за ущерб,
      причиненный по причине неправильной или некачественной упаковки.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      22.&nbsp;Глобал оставляет за собой право переупаковать заказ,
      деконсолидировать, консолидировать сохраняя нормы и требования
      безопасности.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      23.&nbsp;Глобал не несет ответственность за повреждение, порчу,
      уничтожение стеклянных и/или хрупких товаров как в момент получения товара
      в месте отправки, так и в период доставки в страну назначения или в место
      получения посылки.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      24.&nbsp;Клиент осознает, что вес Заказа может отличаться в местах
      доставки и приема, в результате вес заказа будет округлен до&nbsp;100
      граммов точно в большую сторону.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      25.&nbsp;Каждый заказ должен быть с обязательным указанием данных
      отправителя и получателя. Адресные данные по просьбе Пользователя
      записываются в сопроводительных документах.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      26.&nbsp;Адреса отправителя и адресата посылки, доставляемой по территории
      одного государства, должны записываться на посылке на официальном языке
      указанного государства.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      27.&nbsp;На международных посылках адреса пишутся латинскими буквами и
      арабскими цифрами. Допускается написание адреса получателя на языке страны
      назначения с условием, что название страны назначения будет также написано
      и на кыргызском или русском языке.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      28.&nbsp;Пользователь обязуется самостоятельно и за свой счет нести
      расходы, связанные с хранением Заказа на таможенном складе, а в случае,
      если расходы за хранение были оплачены за счет Глобала, Пользователь
      обязуется компенсировать/возместить указанные расходы.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      29.&nbsp;Если ваш Заказ останется на хранении на складе более 60
      (шестьдесят) дней, Пользователь обязуется выплачивать по 100 (сто) сом за
      каждый день хранения свыше срока, указанного в настоящем пункте. Хранение
      посылки на заграничном складе Компании или партнеров запрещено и при
      получении Заказа от продавца или курьера, Заказ формируется для целей
      транспортировки.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      30.&nbsp;В целях соблюдения таможенного законодательства и таможенного
      оформления Заказа, Вы обязаны вместе с трек-номером (tracking) Вашего
      заказа или новым трек-номером предоставленным Глобалом для Вашего Заказа,
      указать цену Вашего заказа на Сайте Глобала в течение 48 часов после
      получения уведомления о доставке Заказа.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "0cm",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      Вам известно, и Вы признаете, что в случае, если вы не укажете цену Заказа
      на сайте Глобала, то в целях соблюдения таможенного законодательства и для
      обеспечения получения Вами вашего заказа, Глобал или таможенные органы
      имеют право самостоятельно определить и указать сумму/таможенную стоимость
      Заказа, при этом Вы самостоятельно несете полную ответственность за все
      сопутствующие последствия.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      31.&nbsp;Данные отправителя и адресата отмечаются на посылке следующим
      образом
    </span>
                        </p>
                        <ul style={{ listStyleType: "disc", marginLeft: 38 }}>
                            <li>
      <span
          style={{
              fontFamily: '"Times New Roman",serif',
              fontSize: "14.0pt",
              color: "black",
              border: "none windowtext 1.0pt",
              background: "white"
          }}
      >
        имя (фамилия, имя отчество физического лица или наименование
        юридического лица),улица, номер здания, дома и квартиры, название
        населенного пункта (города, области, деревни и т.д.)
      </span>
                            </li>
                            <li>
      <span
          style={{
              fontFamily: '"Times New Roman",serif',
              fontSize: "14.0pt",
              color: "black",
              border: "none windowtext 1.0pt",
              background: "white"
          }}
      >
        номер городского или мобильного телефона (если таковой имеется),
        название страны (для международных посылок).
      </span>
                            </li>
                        </ul>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      32. При покупке Заказа вы должны предоставить продавцу идентификационный
      код: доступный на вашей личной странице Сайта в разделе адреса: и, в
      случае, если Вы получите трэк-номер от продавца, сразу же
      объявить/разместить его на веб-сайте Глобала, в соответствующем поле на
      Вашем аккаунте. Вышеуказанные два номера - являются идентификационными
      номерами для Вас и определяют метод доставки Вашего заказа. Если на Заказе
      отсутствует одна из вышеуказанных идентификационных номеров или номер
      несовместим с введенными номерами, то Компания, если это возможно,
      попробует связаться с Вами по номеру предоставленный Вами при регистрации
      на Сайте, и в случае, если до Вас не возможно будет дозвониться или
      связаться &nbsp;в течении того же дня, посылка будет доставлена по методу
      доставки, доступному при объявлении/размещении введенного вами
      трэк-номера, и в странице того получателя, на чьей странице был введен
      номер. Пользователь признает, что в случае несоблюдения условий настоящего
      параграфа все риски, связанные с Заказом, покрываются/возмещаются
      последним и Пользователь отказывается от права предъявлять любые претензии
      к Глобалу.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      33.&nbsp;
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      Global
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      &nbsp;
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      Express
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      &nbsp;
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      не предоставляет услуг по доставке посылок «до востребования» или на адрес
      абонентского ящика.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      34.&nbsp;При курьерской доставке посылок Пользователям, с которыми
      Компанией заключены соответствующие договоры, заполняется форма, которая
      подписывается отправителем или его доверенным лицом. Курьер подтверждает
      количество принятых посылок. Доверенным лицом считается любое лицо,
      передающее посылку от лица отправителя. Один экземпляр формы остается у
      отправителя. Глобал не обязана проверять полномочия лиц, передающие
      посылки для отправок и не несет какой-либо ответственности перед
      Пользователями.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      35.&nbsp;В некоторых случаях, независимо от Компании, Форма, заполняемая
      для каждой международной посылки, имеет специальный идентификационный
      номер, с помощью которого клиент может отслеживать передвижение посылки в
      интернете, вплоть до момента ее передачи адресату. Этот номер также
      указывается на свободном месте посылки, что позволит быстро восстановить
      адресные данные в случае утери накладной.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      36.&nbsp;Компания уведомляет Пользователя о том, что в соответствии с
      законодательством Кыргызской Республики, в зависимости от
      характера,&nbsp;веса, стоимости, количества товаров, а также частоты
      перемещения товаров, ввозимых Пользователем на территорию Таможенного
      союза,&nbsp;к товарам для личного пользования&nbsp;могут вводиться
      ограничения на основании Решений Евразийского экономической комиссии.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "12.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      Пользователь перед приобретением товара, обязуется самостоятельно
      ознакомиться со всеми запретами, ограничениями по товарам для личного
      пользования, во избежание уплаты таможенных пошлин или направления
      претензий к Глобалу.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "12.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      В случае не соблюдения требований и ограничений предусмотренным
      законодательством КР и Решениями ЕЭК, посылка будет относится к товарам
      предпринимательской деятельности, в связи с чем, Пользователь
      самостоятельно и за свой счет осуществляет таможенное оформление посылки с
      оплатой определенных таможенных платежей и налогов, подлежащей
      обязательной уплате в соответствие с законодательством КР.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "0cm",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      &nbsp;
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "0cm",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      Глава 5. ПОРЯДОК ОПЛАТЫ ПРЕДОСТАВЛЕННЫХ УСЛУГ
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "0cm",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      &nbsp;
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      37. Оплата за оказанные услуги взимается с получателя услугами при
      получении посылки в соответствии с действующим тарифом. Если нет иных
      договоренностей, услуги международных почтовых отправлений не включают
      таможенные сборы и налоги, так же как и любые иные расходы, связанные с
      таможенным декларированием. Посылку можно получить в офисе&nbsp;
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      Global
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      &nbsp;
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      Express
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      &nbsp;или курьерской доставкой по адресу, указанному отправителем.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      38. Действующие тарифы размещаются на Сайте Глобала, при этом Компания
      оставляет за собой право без предварительного уведомления, в одностороннем
      порядке, в любой момент изменить Тарифы по оказываемым услугам. При
      утверждении Тарифов Компания размещает на Сайте новые Тарифы, при этом
      отметив дату последних внесенных изменений. Кроме этого, Компания не
      обязана уведомлять Пользователей Сайта о вносимых или внесенных
      изменениях, которое является обязанностью Пользователя, в связи с чем
      последний обязуется, самостоятельно посещать сайт и проверять изменения и
      актуальность Тарифов. Новые условия будут иметь юридическую силу и будут
      распространяться на обязательства Сторон со дня размещения на Сайте.&nbsp;
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      39.&nbsp;Клиент понимает и принимает, что в некоторых случаях,
      установленных законодательством, Заказ подлежит таможенному оформлению, за
      что несет ответственность Получатель. При этом Клиент принимает, что
      Глобал не оказывает услуги по предоставлению консультаций связанные с
      таможенным оформлением. Это обязанность клиента - самостоятельно выяснить
      порядок таможенного оформления. Клиент обязан выполнить все шаги,
      необходимые для таможенного оформления и получения Заказа в порядке,
      предусмотренном законодательством КР. В случае, если во время
      осуществления таможенного оформления в результате действий/бездействий,
      небрежности клиента, Глобал несет потери, клиент обязан возместить ущерб.
      В случае, когда Заказ, подлежащий таможенному оформлению, не принимается
      клиентом в течение 30 (тридцати) дней, то Компания имеет право
      организовать возврат Заказа обратно Отправителю, при этом Клиент обязуется
      возместить Компании все расходы и платежи, связанные с возвратом Заказа.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      40.&nbsp;Клиент обязуется самостоятельно выяснить нужна ли ему специальная
      лицензия или разрешение для импорта, определенных товаров. Клиент за свой
      счет и своими силами получает лицензии, разрешения и иные документы
      необходимые для импорта определенных товаров, в том числе и по оплате
      соответствующих платежей.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      41. Бонусные очки, предоставляемые Компанией в связи с началом
      осуществления деятельности Компании на территории КР, а также &nbsp;другие
      проводимые акции, считаются недействительными, если Клиент не осуществляет
      доставку/покупку товаров в течении 3 (трех) месяцев подряд.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      42. Клиент понимает и принимает во внимание, что Глобал оказывает услуги в
      разных странах, которые имеют различные (собственные национальные) Валюты,
      поэтому в некоторых случаях стоимость доставки Заказа или другие котировки
      цен могут быть указаны в долларах США или в других валютах, однако в
      случае оплаты наличными в соответствии с законодательством страны
      получателя{" "}
        <strong>
        оплата должна подлежать исполнению в валюте этой страны в соответствии с
        обменным курсом валюты определенным Глобалом на день оплаты
      </strong>
      .
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      43. Клиент принимает во внимание и признает, что средства, доступные на
      его Счете, могут быть выражены в других иностранных валютах, а так же
      признает, что уже доступные средства в иностранной валюте могут быть
      выражены в национальной валюте Сом. Кроме этого, Клиент согласен с тем,
      что валюта может быть выражена в национальной валюте Сом по валютному
      курсу, <strong>определенным Глобалом на день оплаты</strong>.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      44. Для заказов на доставку в КР, в некоторых случаях цены на Заказы или
      перевозку могут быть указаны в долларах США или в других валютах, но
      платежи осуществляются в национальной валюте Сом по курсу продажи
      иностранной валюты <strong>определенным Глобалом на день оплаты</strong>.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      45.&nbsp;Клиент несет ответственность за законность происхождения средств,
      внесенные на его счет. В случае, если третьи лица подают заявление в связи
      с законностью происхождения средств Глобалу или другой компании или
      выполняется принудительный возврат средств, то Клиент обязан возместить
      убытки причиненные Глобалу в размере вычитаемых сумм. До возмещения ущерба
      Глобал оставляет за собой право хранить и не передавать Вам ваши заказы.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      46.&nbsp;Клиент соглашается, что во время предоставления ему услуг, могут
      потребоваться дополнительные услуги. В связи с чем, Клиент обязуется
      оплатить дополнительный платеж, выставленный Компанией, и может быть
      помещен в сервис центрах Компании и на странице «Часто задаваемые вопросы»
      сайта или в любом другом разделе.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      47.&nbsp;Клиент должен оплатить полную стоимость оказания услуги до
      получения Заказа. Прежде чем вы получите Заказ, Клиент обязуется погасить
      все обязательства перед Глобалу. При этом Глобал, оставляет за собой право
      на удержание Заказа в качестве гарантии уплаты соответствующих платежей,
      включая оплаты, связанные с дополнительными Услугами.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "0cm",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      &nbsp;
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "0cm",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      Глава 6. ПОРЯДОК ВРУЧЕНИЯ ПОСЫЛКИ
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      48.&nbsp;Посылка доставляется по адресу, указанному в сопровождающих
      документах. Курьер вручает посылку адресату или его доверенному лицу под
      роспись.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      49.&nbsp;Если заказ доставляется Получателю сотрудником Глобала или
      другими субъектами сотрудничающим с Компанией через услугу, доставка на
      дом, то лицо принявшее посылку, подтверждает факт получения и указывает на
      сопровождающем документе:
      <br />
        &nbsp;имя, фамилию и отчество, дату и время получения посылки, а также
      ставит свою подпись.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      50. Порядок передачи посылок, юридическим лицам, определяется в
      соответствии с договором, заключенным между юридическим лицом и&nbsp;
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      Global
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      &nbsp;
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      Express
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      .
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      51.&nbsp;В случае если передача посылок адресату (законному представителю)
      невозможна, посылки хранятся в Глобале в течение 30 (тридцати) календарных
      дней. Отправитель или адресат могут посредством письменной заявки продлить
      срок хранения посылок до трех месяцев. За продление услуги, по хранению
      посылки, оплачивается стороной, от которой поступило соответствующее
      заявление.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      52.&nbsp;В соответствии с законодательством КР и в порядке, установленном
      международным договором, посылка может быть переправлена на адрес
      отправителя
    </span>
                        </p>
                        <ul style={{ listStyleType: "disc", marginLeft: 38 }}>
                            <li>
      <span
          style={{
              fontFamily: '"Times New Roman",serif',
              fontSize: "14.0pt",
              color: "black",
              border: "none windowtext 1.0pt",
              background: "white"
          }}
      >
        на основании заявления отправителя, до момента передачи адресату,
      </span>
                            </li>
                            <li>
      <span
          style={{
              fontFamily: '"Times New Roman",serif',
              fontSize: "14.0pt",
              color: "black",
              border: "none windowtext 1.0pt",
              background: "white"
          }}
      >
        в случае отказа адресата (законного представителя) принять посылку,
      </span>
                            </li>
                            <li>
      <span
          style={{
              fontFamily: '"Times New Roman",serif',
              fontSize: "14.0pt",
              color: "black",
              border: "none windowtext 1.0pt",
              background: "white"
          }}
      >
        в случае отсутствия получателя по указанному адресу,
      </span>
                            </li>
                            <li>
      <span
          style={{
              fontFamily: '"Times New Roman",serif',
              fontSize: "14.0pt",
              color: "black",
              border: "none windowtext 1.0pt",
              background: "white"
          }}
      >
        в случае нечитабельности адреса получателя (стерт, оторван, размыто и
        т.д.),
      </span>
                            </li>
                            <li>
      <span
          style={{
              fontFamily: '"Times New Roman",serif',
              fontSize: "14.0pt",
              color: "black",
              border: "none windowtext 1.0pt",
              background: "white"
          }}
      >
        по причине других обстоятельств, которые исключают возможность
        выполнения Компании обязательств по договору на оказание услуг
      </span>
                            </li>
                        </ul>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      53.&nbsp;Глобал в соответствии с Законом КР от 20 июня 2001 года N 52 «О
      почтовой связи» не является оператором почты, но руководствуясь Правилами
      предоставления услуг почтовой связи и Гражданским кодексом КР, по не
      врученным Заказам адресату в течение 30 (тридцати) календарных дней со для
      прибытия Заказа на склад Глобала, имеет право совершать следующие
      действия:
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "12.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      1)&nbsp;Требовать оплаты услуги по хранению Заказа в размере 100 (сто) сом
      за каждый день хранения.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "12.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      2)&nbsp;Хранить на складе Заказ адресата не более 90 (девяносто)
      календарных дней.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "12.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      3)&nbsp;По истечению 90 (девяносто) календарных дней, в одностороннем
      внесудебном порядке удержать Заказ адресата в счет оплаты услуг за
      хранение Заказа. При этом удержание производится независимо от стоимости
      Заказа.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "12.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      4)&nbsp;Удержанный Заказ Глобала имеет право по своему выбору
      утилизировать или реализовать. При этом Клиент предоставляет свое согласие
      на совершение любых действий в отношении не полученного в срок Заказа и в
      последующем не будет иметь никаких претензий к Глобалу.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "12.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      5) Если в случае реализации Заказа, полученной суммы недостаточно для
      погашения задолженности по услуге хранения Заказа, то Клиент обязуется
      компенсировать оставшуюся задолженность. &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;&nbsp;
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      54. Таможенный контроль и таможенное оформление международных посылок,
      транспортируемых через таможенную границу данной страны, а также выдача
      адресату (законному представителю) международных посылок оператором&nbsp;
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      Global
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      &nbsp;
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      Express
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      &nbsp;осуществляются в соответствии с требованиями таможенного
      законодательства данной страны.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      55.&nbsp;
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      Global
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      &nbsp;
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      Express
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      &nbsp;
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      не несет ответственности за таможенные декларации независимо от способа их
      заполнения, равно как за решения, принятые таможенной службой в процессе
      таможенного досмотра посылки, также при определении таможенной стоимости
      товара.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "0cm",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      &nbsp;
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "0cm",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      Глава 7. ДОКУМЕНТЫ, НЕОБХОДИМЫЕ ДЛЯ ПОЛУЧЕНИЯ ПОСЫЛКИ
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      56.&nbsp;Посылка выдается адресату при наличии документа, удостоверяющего
      личность. Посылки, адресованные лицам, не достигшим 16 лет, выдаются
      адресату на основании свидетельства о рождении или законному представителю
      на основании соответствующего документа.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      57.&nbsp;Посылки выдаются только при предоставлении оригинального
      документа или копии документа, нотариально заверенной в соответствии с
      законодательством Республики Казахстан.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      58.&nbsp;Условия компенсации и материальной ответственности в случае утери
      посылки, определяются законодательством КР.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      59.&nbsp;Посылка также может быть передана доверенному лицу Клиента, если
      тот имеет нотариальную оформленную доверенность и удостоверение личности
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      60.&nbsp;Заказ считается доставленным должным образом, если Клиент или
      другое лицо представляет документы, удостоверяющие личность адресата
      Заказа, и на этой основе Заказ был выдан Клиенту, адресату Заказа или
      другому лицу предоставившее документы, удостоверяющие личность адресата
      Заказа. Представление документа, удостоверяющего Вашу личность Вами или
      любым другим лицом, Мы сочтем надлежащим разрешением получить Заказ.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "0cm",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      &nbsp;
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "0cm",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      Глава 8. ДОКУМЕНТЫ, НА ОСНОВЕ КОТОРЫХ ОСУЩЕСТВЛЯЕТСЯ
      ВОЗВРАТ&nbsp;ЗАЧИСЛЕННЫХ НА СЧЕТ ДЕНЕГ
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "0cm",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      &nbsp;
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      61.&nbsp;Если пользователи открыли на веб-сайте Компании счета и заполнили
      их, то данная сумма может быть возвращена пользователям следующим образом:
    </span>
                        </p>
                        <ul style={{ listStyleType: "disc", marginLeft: 38 }}>
                            <li>
      <span
          style={{
              fontFamily: '"Times New Roman",serif',
              fontSize: "14.0pt",
              color: "black",
              border: "none windowtext 1.0pt",
              background: "white"
          }}
      >
        Если сумма была внесена электронным переводом денежных средств, то сумма
        или остаток суммы возвращается на тот же счет, при этом, если сумма была
        перечислена 120 (сто двадцать) и более календарных дней назад, то
        возврат суммы осуществляется на основании запроса, по форме
        утвержденному внутренними требованиями Глобала.
      </span>
                            </li>
                            <li>
      <span
          style={{
              fontFamily: '"Times New Roman",serif',
              fontSize: "14.0pt",
              color: "black",
              border: "none windowtext 1.0pt",
              background: "white"
          }}
      >
        Если сумма была внесена в офисе Партнера Компании или при помощи
        терминала, то сумма или остаток суммы возвращается в соответствии
        законодательством данной страны, при этом Пользователем должны быть
        предоставлены письменные доказательства (квитанция, кассовый чек и др.
        документы, подтверждающие факт оплаты или перевода)
      </span>
                            </li>
                        </ul>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "0cm",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      &nbsp;
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "0cm",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      Глава 9. ПРЕДЪЯВЛЕНИЕ И ОБСУЖДЕНИЕ ПРЕТЕНЗИИ, ВОЗМЕЩЕНИЕ ПРИЧИНЕННОГО
      УЩЕРБА
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      62. В случае невыполнения или ненадлежащего выполнения&nbsp;
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      Global
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      &nbsp;
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      Express
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      &nbsp;
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      или партнерами Компании обязательств по предоставлению услуг, Пользователи
      услуг имеют право предъявить претензию (требование, жалобу) в письменном
      виде, в том числе с требованием возмещения причиненного фактического
      ущерба в случае утери Заказа.
    </span>
                        </p>
                        <p
                            style={{
                                marginTop: "0cm",
                                marginRight: "0cm",
                                marginBottom: ".0001pt",
                                marginLeft: "30.0pt",
                                lineHeight: "19.2pt",
                                fontSize: 15,
                                fontFamily: '"Calibri",sans-serif',
                                textAlign: "justify",
                                textIndent: "-18.0pt",
                                background: "white",
                                verticalAlign: "baseline"
                            }}
                        >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      63. Претензия о недоставленной посылке, а также порче или утере посылки
      предъявляется в&nbsp;
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      Global
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      &nbsp;
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      Express
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                    background: "white"
                                }}
                            >
      &nbsp;
    </span>
                            <span
                                style={{
                                    fontSize: 19,
                                    fontFamily: '"Times New Roman",serif',
                                    color: "black",
                                    border: "none windowtext 1.0pt",
                                    padding: "0cm",
                                background: "white"
                            }}
                        >
      в течение 30 (тридцати) календарных дней со дня получения посылки, но не
      более чем в течение 2 (двух) месяцев с момента получения уведомления о
      доставке посылки в КР.
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "30.0pt",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            textIndent: "-18.0pt",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      64.&nbsp;Претензия о поиске международных посылок в случае их утери
      принимаются и обсуждаются в соответствии и в сроки, определенные
      законодательством КР и международными договорами.
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "30.0pt",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            textIndent: "-18.0pt",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      65.&nbsp;Претензия подлежит регистрации в соответствии с Законодательством
      КР. При подаче письменного претензии пользователь услугами (законный
      представитель) должен предоставить документ, удостоверяющий личность.
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "30.0pt",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            textIndent: "-18.0pt",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      66. К претензии прилагаются копия договора о предоставлении услуг&nbsp;
    </span>
                        <span
                            style={{
                                fontSize: 19,
                                fontFamily: '"Times New Roman",serif',
                                color: "black",
                                border: "none windowtext 1.0pt",
                                padding: "0cm",
                                background: "white"
                            }}
                        >
      Global
    </span>
                        <span
                            style={{
                                fontSize: 19,
                                fontFamily: '"Times New Roman",serif',
                                color: "black",
                                border: "none windowtext 1.0pt",
                                padding: "0cm",
                                background: "white"
                            }}
                        >
      &nbsp;
    </span>
                        <span
                            style={{
                                fontSize: 19,
                                fontFamily: '"Times New Roman",serif',
                                color: "black",
                                border: "none windowtext 1.0pt",
                                padding: "0cm",
                                background: "white"
                            }}
                        >
      Express
    </span>
                        <span
                            style={{
                                fontSize: 19,
                                fontFamily: '"Times New Roman",serif',
                                color: "black",
                                border: "none windowtext 1.0pt",
                                padding: "0cm",
                                background: "white"
                            }}
                        >
      &nbsp;или Партнеров компании, и (или) копия квитанции, и (или) копия описи
      содержимого посылки, пояснение оператора курьерской службы о причинах
      невыполнения или ненадлежащего выполнения обязанностей, установленных
      договором, а также сведения о размере причиненного ущерба в случае
      предъявления претензии о возмещении причиненного ущерба.
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "30.0pt",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            textIndent: "-18.0pt",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      69.&nbsp;В письменном требовании (жалобе) должны содержаться:
      <br />
        &nbsp;сведения из документов, удостоверяющих личность заявителя (законного
      представителя)суть требования (жалобы).
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "30.0pt",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            textIndent: "-18.0pt",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      70.&nbsp;
    </span>
                        <span
                            style={{
                                fontSize: 19,
                                fontFamily: '"Times New Roman",serif',
                                color: "black",
                                border: "none windowtext 1.0pt",
                                padding: "0cm",
                                background: "white"
                            }}
                        >
      Global
    </span>
                        <span
                            style={{
                                fontSize: 19,
                                fontFamily: '"Times New Roman",serif',
                                color: "black",
                                border: "none windowtext 1.0pt",
                                padding: "0cm",
                                background: "white"
                            }}
                        >
      &nbsp;
    </span>
                        <span
                            style={{
                                fontSize: 19,
                                fontFamily: '"Times New Roman",serif',
                                color: "black",
                                border: "none windowtext 1.0pt",
                                padding: "0cm",
                                background: "white"
                            }}
                        >
      Express
    </span>
                        <span
                            style={{
                                fontSize: 19,
                                fontFamily: '"Times New Roman",serif',
                                color: "black",
                                border: "none windowtext 1.0pt",
                                padding: "0cm",
                                background: "white"
                            }}
                        >
      &nbsp;изучает письменные требования (жалобы) и письменно предоставляет
      ответ в сроки, установленные законодательством КР.
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "30.0pt",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            textIndent: "-18.0pt",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      71.&nbsp;В случае, если не будет разрешен спор по письменному требованию
      (жалобе), Пользователи услугами имеют право обратиться в соответствующий
      суд.
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "30.0pt",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            textIndent: "-18.0pt",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      72.&nbsp;Выплата денежных средств, составляющих возмещение причиненного
      ущерба, осуществляется Глобалом в соответствии и в сроки, определенные
      законодательством КР и международным договором.
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "30.0pt",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            textIndent: "-18.0pt",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      73.&nbsp;Страхование Заказа и другие связанные с этим расходы,
      осуществляется Клиентом самостоятельно&nbsp;и за свой счет.
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "30.0pt",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            textIndent: "-18.0pt",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      74.&nbsp;В случае обнаружения посылки, после выплаты заявителю возмещения
      за его утрату посылка вручается адресату или возвращается отправителю. При
      этом с заявителя (адресата или отправителя) взыскивается выплаченная сумма
      возмещения за вычетом суммы возмещения за задержку отправки посылки, если
      такая сумма была предоставлена. При отказе заявителя от возвращения суммы
      возмещения, посылка хранится в Компании в качестве не выданных Заказов.
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "30.0pt",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            textIndent: "-18.0pt",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      75.&nbsp;Компания не несет ответственности, за комплектность и качество
      заказа, доставленного магазином или иным лицом на его зарубежный склад.
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "30.0pt",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            textIndent: "-18.0pt",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      76.&nbsp;Обязанностью Пользователя является подтверждение целостности
      Заказа непосредственно при его получении. Компания не несет
      ответственности за недостатки и дефекты, которые будут выявлены после
      получения заказа и которые были не отмечены Пользователем при получении.
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "0cm",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      &nbsp;
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "0cm",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      &nbsp;
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "0cm",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      Глава 10. САЙТЫ, ПРОДУКТЫ И РЕКЛАМЫ ТРЕТЬИХ ЛИЦ
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "0cm",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      &nbsp;
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "30.0pt",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            textIndent: "-18.0pt",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      77.&nbsp;Веб сайт Компании может содержать ссылки или гиперссылки других
      сайтов (далее Другие Сайты), статьи, фото, картинки, продукты, графические
      картинки, звуковые и видео, информацию, программы и так далее, которые
      размещены или принадлежат Третьим лицам (далее материалы Третьих Лиц).
      Компания не является представителем и не имеет какого-либо отношения к
      указанным Третьим лицам, Компания не обязана проверять целостность,
      правдивость и подлинность размещенных материалов и не несет
      ответственности за материалы Третьих Лиц или Других Сайтов.
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "30.0pt",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            textIndent: "-18.0pt",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      78.&nbsp;Компания не несет ответственности за предлагаемые или
      предоставленные услуги Третьими Лицами через наш сайт.
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "30.0pt",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            textIndent: "-18.0pt",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      79.&nbsp;Компания не несет ответственности за качество, исправность,
      целостность продуктов приобретенных на сайтах третьих сторон&nbsp;в том
      числе за соответствие продуктов вашим ожиданиям. Вы несете полную личную
      ответственность за получение таких Заказов, их таможенную очистку, ведение
      переговоров с третьими лицами, возврата Заказа и любых других действий,
      связанные с Заказом.
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "0cm",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      &nbsp;
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "0cm",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      &nbsp;
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "0cm",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      Глава 11. ЗАВЕРШЕНИЕ ИЛИ РАСТОРЖЕНИЕ ДОГОВОРА
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "30.0pt",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            textIndent: "-18.0pt",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      80.&nbsp;Вы имеете право в любое время расторгнуть Договор, подписанный с
      Компанией, при этом Пользователь обязуется исполнить все платежные
      обязательства перед Компанией. В случае наличии просроченных обязательств
      с Вашей стороны Договор не может считаться расторгнутым.
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "30.0pt",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            textIndent: "-18.0pt",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      81.&nbsp;В целях прекращения Договора и закрытии текущего счета в
      Глоббинге, Клиент обязуется представить заявление о расторжении Договора и
      закрытии счета в офисе Компании отметив электронную почту предоставленную
      во время регистрации.
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "30.0pt",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            textIndent: "-18.0pt",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      82.&nbsp;Подача заявления на расторжение Договора подтверждает, что Клиент
      отказывается от бонусов имеющиеся на счете и ознакомлен с условиями
      возврата положительного баланса.
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "30.0pt",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            textIndent: "-18.0pt",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      83.&nbsp;Компания имеет право в одностороннем порядке расторгнуть договор,
      если Клиент своими действиями распространяет недостоверную/ложную
      информацию об Услугах, предоставляемых Компанией, или своими действиями
      пытается сформировать негативное мнение о Компании у других субъектов, или
      в других случаях, когда Компания считает, что действия Клиента могут
      нанести вред деловой репутации Компании. В случае расторжения Договора на
      основании указанных пунктов, все бонусные очки Клиента аннулируются.
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "30.0pt",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            textIndent: "-18.0pt",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      84.&nbsp;Если на момент расторжения Договора на счету Клиента имеется
      остаток денежных средств, то сумма подлежит возврату в соответствии с 8-ой
      главой Общих Условий.
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "30.0pt",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            textIndent: "-18.0pt",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm",
            background: "white"
        }}
    >
      85.&nbsp;В случае если в течение 90 (девяноста) календарных дней Клиент не
      предоставит квитанцию (например, кассовый чек) предусмотренной в главе 8
      Общих Условий, текущий счет баланса переходит в собственность компании.
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "0cm",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            fontFamily: '"Times New Roman",serif',
            color: "black",
            border: "none windowtext 1.0pt",
            padding: "0cm"
        }}
    >
      &nbsp;
    </span>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: ".0001pt",
                            marginLeft: "0cm",
                            lineHeight: "19.2pt",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify",
                            background: "white",
                            verticalAlign: "baseline"
                        }}
                    >
                        <strong>
      <span
          style={{
              fontSize: 13,
              fontFamily: '"Times New Roman",serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0cm"
          }}
      >
        Настоящим, соглашаясь с условиями Договора, используя сервис и обращаясь
        к услугам Компании, Вы даете свое согласие на сбор, обработку и хранение
        Ваших персональных данных, для осуществления Компанией всех таможенных
        процедур, а также иных действий, указанных в Договоре.
      </span>
                        </strong>
                    </p>
                    <p
                        style={{
                            marginTop: "0cm",
                            marginRight: "0cm",
                            marginBottom: "8.0pt",
                            marginLeft: "0cm",
                            lineHeight: "107%",
                            fontSize: 15,
                            fontFamily: '"Calibri",sans-serif',
                            textAlign: "justify"
                        }}
                    >
    <span
        style={{
            fontSize: 19,
            lineHeight: "107%",
            fontFamily: '"Times New Roman",serif'
        }}
    >
      &nbsp;
    </span>
                    </p>
                </>

            </div>
            </div>
        </div>
    );
}

export default Agreement;