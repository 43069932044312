import React, { useState, useEffect } from "react";
import "../../styles/mobileVersion/Mhome.css";
import MHeader from "../../components/mobileVersion/MHeader";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { useSelector } from "react-redux";
import { AddressesService } from "../../services/AddressesService";
import pen from "../../assets/images/pen.png";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { IconButton, Modal, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { OrderService } from "../../services/OrderService";
import moment from "moment";
import { Pagination } from "@mui/material";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const modalInitialValues2 = {
  open: false,
  values: {
    pick_up_point: "",
  },
};

function MHome() {
  //translation
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user);

  const links = [
    {
      startIcon: <LocalGroceryStoreIcon className="MFooter-link__icon" />,
      endIcon: <ArrowForwardIosIcon />,
      label: t("mobile.my_orders"),
      route: "/orders",
    },
    {
      startIcon: <LocationOnIcon className="MFooter-link__icon" />,
      endIcon: <ArrowForwardIosIcon />,
      label: t("mobile.addresses"),
      route: "/address",
    },
    {
      startIcon: <LocalShippingIcon className="MFooter-link__icon" />,
      endIcon: <ArrowForwardIosIcon />,
      label: t("button.delivery"),
      route: "/delivery",
    },
  ];

  const pointsList = AddressesService.GetPointsList();

  const [modal2, setModal2] = useState<any>(modalInitialValues2);

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setModal2({
      ...modal2,
      requested: true,
    });

    AddressesService.CreatePointsList(modal2.values)
      .then(() => {
        setModal2(modalInitialValues2);
        window.location.reload();
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (user.user?.pick_up_point === null) {
      timeout = setTimeout(() => {
        setModal2({
          ...modalInitialValues2,
          open: true,
        });
      }, 3000);
    }

    return () => clearTimeout(timeout);
  }, [user.user.pick_up_point?.id, modal2.open]);

  //   *********************

  const [filterValue, setFilterValue] = React.useState<any>({
    search: "",
  });

  const [historyData, setHistoryData] = useState<any>([]);

  let orderTracking = OrderService.GetOrdersList(filterValue);

  useEffect(() => {
    if (!orderTracking.loading && !orderTracking.error) {
      setHistoryData(
        orderTracking.result?.data.results.map((order: any) => ({
          id: order.id,
          tracking_number: order.tracking_number,
          created_at: moment(order.created_at).format("DD.MM.YYYY").toString(),
          full_name: order.user?.full_name,
          status: order.status?.name,
        }))
      );
    }
  }, [
    orderTracking.error,
    orderTracking.loading,
    orderTracking.result?.data.results,
  ]);

  const handleSearch = () => {
    
    setShowDataGrid(true);
  };

  const [showDataGrid, setShowDataGrid] = useState<boolean>(false);

  return (
    <>
      <div className="w-full flex flex-col justify-start items-center gap-[20px] bg-mobileBackground bg-center bg-cover">
        <MHeader />

        <div className="flex flex-col gap-[20px]">
          <Button variant="contained" color="blue">
            <p className="text-[24px]">
              {t("header.code")}: {user.user.id}
            </p>
          </Button>

          <div className="bg-gradient text-white px-[20px] py-[10px] rounded-[10px] shadow-md">
            <div className="flex flex-col text-[16px] gap-[8px] relative">
              <div className="flex flex-col items-center">
                <p>Ваш ПВЗ: </p>
                <span className="font-bold mt-[10px]">
                  {user.user?.pick_up_point?.name}
                </span>
              </div>

              <img
                onClick={() => {
                  setModal2({
                    ...modalInitialValues2,
                    open: true,
                  });
                }}
                className="h-[16px] w-[16px] cursor-pointer absolute right-0 top-0"
                src={pen}
                alt="img"
              />
            </div>
          </div>
        </div>

        <div className="w-full flex justify-center items-center gap-[20px] mb-[30px] px-[20px]">
          <Button fullWidth variant="outlined" color="purple">
            <p className="text-[white]">
              {t("personal.points")} {Math.floor(user.user.bonus)}
            </p>
          </Button>
          <Button
            fullWidth
            variant="outlined"
            color="purple"
            onClick={() => navigate("/currency")}
          >
            <p className="text-[white]">
              {`${t("header.exchange")} USD:
                                    ${
                                      user.user.currency.find(
                                        (currentValue: any) =>
                                          currentValue.code === "USD"
                                      ) &&
                                      user.user.currency.find(
                                        (currentValue: any) =>
                                          currentValue.code === "USD"
                                      ).value
                                    }`}
            </p>
          </Button>
        </div>
      </div>

      <div className="bg-white p-[20px] rounded-[10px] shadow-sm w-full">
        <div className="order-tracking flex flex-col items-center justify-center gap-[20px]">
          <TextField
            className="w-full"
            label={t("filter.search")}
            id="search-bar"
            value={filterValue.search}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ fill: "#48A5D1" }} />
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              const searchText = event.target.value;
              setFilterValue({
                ...filterValue,
                search: searchText,
              });
              setShowDataGrid(searchText.trim() === "123456789");
            }}
          />

          <Button
            onClick={handleSearch}
            className="w-full bg-gradient"
            variant="contained"
            color="blue"
          >
            {t("order_tracking.search_button")}
          </Button>
        </div>

        {showDataGrid && (
          <div className="mb-[70px] mt-[40px]">
            {historyData.map((order: any, index: any) => (
              <div
                key={index}
                className="shadow-block p-[20px] bg-white rounded-[10px] flex justify-between mb-[40px]"
              >
                <div className="flex flex-col gap-[5px] text-[14px]">
                  <h4 className="text-[16px] font-bold">
                    {order.tracking_number}
                  </h4>
                  <span>{order.full_name}</span>
                </div>
                <div className="flex flex-col gap-[5px] text-[14px]">
                  <span className="font-semibold">{order.created_at}</span>
                  <span className="text-[#48A5D1]">{order.status}</span>
                </div>
              </div>
            ))}

            <Pagination
              count={
                !orderTracking.loading && !orderTracking.error
                  ? orderTracking.result?.data.total_pages
                  : 1
              }
              page={
                !orderTracking.loading && !orderTracking.error
                  ? orderTracking.result?.data.current_page
                  : 1
              }
              onChange={(event, value: number) => {
                setFilterValue({
                  ...filterValue,
                  page: value,
                });
              }}
            />
          </div>
        )}
      </div>
      <div className="w-full flex flex-col justify-start items-center pt-[20px] pb-[100px] px-[20px] gap-[10px]">
        {links.map((item, index: number) => (
          <Button
            key={index}
            fullWidth
            size="large"
            variant="contained"
            color="white"
            endIcon={item.endIcon}
            onClick={() => navigate(item.route)}
          >
            <div className="w-full flex justify-start">
              <div className="MFooter-link__iconBox">{item.startIcon}</div>
              <div className="MFooter-link__textBox">
                <p>{item.label}</p>
              </div>
            </div>
          </Button>
        ))}
      </div>

      <Modal open={modal2.open} onClose={() => setModal2(modalInitialValues2)}>
        <div className="mainModal w-[90%] mainModalMobile relative flex flex-col justify-start items-center">
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
            onClick={() => setModal2(modalInitialValues2)}
          >
            <CloseIcon />
          </IconButton>

          <h2 className="text-[24px] font-[600] text-[#1E1C2A] mb-[14px] text-center w-[210px]">
            Сменить пункт выдачи заказа
          </h2>

          <ul className="text-[12px] mt-[8px]">
            <li>
              {" "}
              1. Оплата:
              Оплата производится до получения посылок. Убедитесь, что вы оплатили за посылку до того, как направились
              в ПВЗ.
            </li>
            <li>
              {" "}
              2. Выбор ПВЗ:
              При указании неверного ПВЗ, вывоз посылки осуществляется самостоятельно за счет клиента.
              {" "}
            </li>
            <li>
              {" "}
              3. Хранение:
              Срок бесплатного хранения в ПВЗ 1 месяц.
            </li>
            <li>
              {" "}
              4. Получение посылок:
              Все полученные посылки на ваше имя отправляются разом. Услуги перепаковки посылок не предоставляются.
            </li>
            <li>
              {" "}
              5. Доставка:
              Мы осуществляем бесплатную доставку только до пункта выдачи заказов.
              Доставка по городу не осуществляется.
            </li>
            <li>
              {" "}
              6. Вес посылок:
              К отправке допускаются посылки общим весом до 20 кг и размером не более 50*50*50.
            </li>
          </ul>

          <div className="flex flex-col gap-[30px] w-full mt-[30px]">
            <FormControl className="w-[100%]">
              <InputLabel>Выберите ПВЗ</InputLabel>
              <Select
                  label="Выберите ПВЗ"
                  value={
                      modal2.values.pick_up_point ||
                      user.user.pick_up_point?.id ||
                      ""
                  }
                  onChange={(event: any) => {
                    setModal2({
                      ...modal2,
                      values: {
                        ...modal2.values,
                        pick_up_point: event.target.value,
                      },
                    });
                  }}
              >
                {!pointsList.loading &&
                    !pointsList.error &&
                    pointsList.result?.data.map((country: any, index: number) => (
                        <MenuItem key={index} value={country.id}>
                          {country.name}
                        </MenuItem>
                    ))}
              </Select>
            </FormControl>

            <Button
                className="w-[100%] h-[50px] bg-gradient"
                variant="contained"
                color="blue"
                onClick={handleFormSubmit}
            >
              {t("main.button")}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default MHome;
