import React, {useEffect, useState} from 'react'
import '../../../styles/desktopVersion/arrangeDelivery.css'
import {DataGrid, GridColDef, GridFooterContainer} from "@mui/x-data-grid";
import {
    Autocomplete,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {DeliveryService} from "../../../services/DeliveryService";
import {UserService} from "../../../services/UserService";
import {PaymentService} from "../../../services/PaymentService";
import moment from "moment/moment";
import {OrderI} from "../../../models/OrderI";
import {DeliveryI} from "../../../models/DeliveryI";
import {useTranslation} from "react-i18next";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';


const initialDeliveryInfo = {
    address: '',
    phone: '',
    payment: '',
    comment: '',
    orders: [],
}

export default function DeliveryView() {
    let navigate = useNavigate();
    let {id} = useParams();
    let {t} = useTranslation()

    const addresses = UserService.GetUserAddresses()
    const phones = UserService.GetUserPhones()
    const payments = PaymentService.GetPayments()

    const [deliveryOrdersInfo, setDeliveryOrdersInfo] = useState<DeliveryI>(initialDeliveryInfo)

    const deliveryOrdersColumns: GridColDef[] = [
        {field: 'id', headerName: 'ID', flex: 1, hide: true},
        {field: 'created_at', headerName: 'Дата', flex: 1},
        {
            field: 'country', headerName: 'Страна', flex: 1, renderCell: (params) => (
                params.row.country.name
            )
        },
        {field: 'tracking_number', headerName: 'Трек', flex: 1},
        {field: 'rounded_weight', headerName: 'Вес', flex: 1},
        {field: 'total_price', headerName: 'Цена', flex: 1},
    ];

    const delivery = DeliveryService.GetDelivery(id)

    useEffect(() => {
        if (!delivery.loading && !delivery.error) {
            const data: OrderI[] = []
            for (let i = 0; i < delivery.result?.data.orders.length; i++) {
                let order = delivery.result?.data.orders[i]
                data[i] = {
                    id: order.id,
                    created_at: moment(order.created_at).format('DD.MM.YYYY').toString(),
                    country: order.country,
                    tracking_number: order.tracking_number,
                    rounded_weight: order.rounded_weight,
                    total_price: order.total_price,
                }
            }
            setDeliveryOrdersInfo((prevState) => ({
                ...prevState,
                orders: data,
                address: delivery.result?.data.address,
                phone: delivery.result?.data.phone,
                payment: delivery.result?.data.payment.id,
                comment: delivery.result?.data.comment,
            }))
        }
    }, [delivery.error, delivery.loading, delivery.result?.data.orders])

    return (
        <>
            <h1 className='text-[30px] text-black font-[400] mb-[50px]'>{t('arrange_delivery.titleView')}</h1>
            <form className="arrangeDelivery__mainContainer" onSubmit={(event) => {
                event.preventDefault()
            }}>
                <h1 className="arrangeDelivery-mainContainer__title">
                    {t('arrange_delivery.orders')}
                </h1>
                <div style={{width: '100%', marginBottom: '50px'}}>
                    <DataGrid
                        rows={deliveryOrdersInfo.orders ? deliveryOrdersInfo.orders : []}
                        columns={deliveryOrdersColumns}
                        disableColumnFilter
                        filterMode='server'
                        onRowDoubleClick={(params) => {
                            navigate(`/orders/${params.row.id}`)
                        }}
                        localeText={{
                            noRowsLabel: "Нет информации",
                          }}
                        loading={delivery.loading}
                        autoHeight
                        components={{
                            Footer: () => {
                                return (
                                    <GridFooterContainer>
                                        {/*<Pagination*/}
                                        {/*    count={!ordersForDelivery.loading && !ordersForDelivery.error ? ordersForDelivery.result?.data.total_pages : 1}*/}
                                        {/*    page={!ordersForDelivery.loading && !ordersForDelivery.error ? ordersForDelivery.result?.data.current_page : 1}*/}
                                        {/*    onChange={(event, value:number)=>{*/}
                                        {/*        setFilter({*/}
                                        {/*            ...filter,*/}
                                        {/*            page: value,*/}
                                        {/*        })*/}
                                        {/*    }}*/}
                                        {/*/>*/}
                                    </GridFooterContainer>
                                );
                            },
                        }}
                    />
                </div>
                <div style={{
                    width: '100%',
                    display: "flex",
                    flexDirection: 'column',
                    gap: '20px',
                    justifyContent: "center",
                    marginBottom: '50px'
                }}>
                    <Stack width={'100%'} spacing={'20px'} direction={'row'}>
                        <div className='arrangeDelivery__selectBox'>
                            <h1 className="arrangeDelivery-selectBox__title">
                                {t('arrange_delivery.address')}
                            </h1>
                            <Autocomplete
                                freeSolo
                                options={addresses.loading ? [] : addresses.result?.data.map((option: any) => option.address)}
                                value={deliveryOrdersInfo.address}
                                readOnly={true}
                                onChange={(event: any, newValue: string | null) => {
                                    setDeliveryOrdersInfo({
                                        ...deliveryOrdersInfo,
                                        address: newValue !== null ? newValue : '',
                                    });
                                }}
                                inputValue={deliveryOrdersInfo.address}
                                onInputChange={(event, newInputValue) => {
                                    setDeliveryOrdersInfo({
                                        ...deliveryOrdersInfo,
                                        address: newInputValue !== null ? newInputValue : '',
                                    });
                                }}
                                id="controllable-states-demo"
                                loading={addresses.loading}
                                sx={{width: '100%'}}
                                size="small"
                                renderInput={(params) => <TextField {...params} required
                                                                    label={t('arrange_delivery.address')}/>}
                            />
                        </div>
                        <div className='arrangeDelivery__selectBox'>
                            <h1 className="arrangeDelivery-selectBox__title">
                                {t('arrange_delivery.phone')}
                            </h1>
                            <Autocomplete
                                freeSolo
                                readOnly={true}
                                options={phones.loading ? [] : phones.result?.data.map((option: any) => option.phone)}
                                value={deliveryOrdersInfo.phone}
                                onChange={(event: any, newValue: string | null) => {
                                    setDeliveryOrdersInfo({
                                        ...deliveryOrdersInfo,
                                        phone: newValue !== null ? newValue : '',
                                    });
                                }}
                                inputValue={deliveryOrdersInfo.phone}
                                onInputChange={(event, newInputValue) => {
                                    setDeliveryOrdersInfo({
                                        ...deliveryOrdersInfo,
                                        phone: newInputValue !== null ? newInputValue : '',
                                    });
                                }}
                                sx={{width: '100%'}}
                                loading={phones.loading}
                                size="small"
                                renderInput={
                                    (params) => <TextField {...params} required label={t('arrange_delivery.phone')}/>
                                }
                            />
                        </div>
                        <div className='arrangeDelivery__selectBox'>
                            <h1 className="arrangeDelivery-selectBox__title">
                                {t('arrange_delivery.payment_type')}
                            </h1>
                            <FormControl sx={{width: '100%', marginBottom: '20px'}} size="small">
                                <InputLabel id="firm-select-label"> {t('arrange_delivery.payment_type')}</InputLabel>
                                <Select
                                    labelId="firm-select-label"
                                    id="demo-select-small"
                                    readOnly={true}
                                    value={deliveryOrdersInfo.payment}
                                    label={t('arrange_delivery.payment_type')}
                                    onChange={(event) => {
                                        setDeliveryOrdersInfo((prev: any) => {
                                            return {
                                                ...prev,
                                                payment: event.target.value
                                            }
                                        })
                                    }
                                    }
                                    required={true}
                                    defaultValue=""
                                >
                                    {payments.loading
                                        ? <div>loading</div>
                                        : payments.error
                                            ? <div>Error</div>
                                            : payments.result?.data.map((item: any) => (
                                                <MenuItem key={item.id}
                                                          value={item.id}>{item.payment}</MenuItem>
                                            ))
                                    }
                                </Select>
                            </FormControl>
                        </div>
                    </Stack>
                    <div style={{width: '100%'}}>
                        <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                            <ChatBubbleOutlineIcon/><p>{deliveryOrdersInfo.comment}</p>
                        </div>
                    </div>
                </div>
                <div style={{width: '100%', display: "flex", justifyContent: "center"}}>
                    <Stack spacing={'20px'} direction={"row"}>
                        <Button variant="contained"
                                className='orderAdd-mainContainer__footerOrderCLoseButton'
                                onClick={() => {
                                    navigate('/delivery')
                                }}>
                            {t('button.back')}
                        </Button>
                    </Stack>
                </div>
            </form>
        </>
    )
}