import React from "react";
import '../../../styles/mobileVersion/MarrangeDelivery.css'
import {Link, useParams} from "react-router-dom";
import {DeliveryService} from "../../../services/DeliveryService";
import {useTranslation} from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import {EmptyData} from "../../../helpers/helpers";
import {OrdersEmptyIcon} from "../../../common/common";


export default function MDeliveryView() {
    let {t} = useTranslation()
    let {id} = useParams();

    const delivery = DeliveryService.GetDelivery(id)

    return (
        <>
            <div
                className="w-full flex flex-col justify-start items-center gap-[20px] bg-mobileBackground bg-center bg-cover">
                <div
                    className="w-full flex flex-col justify-center items-center gap-[20px] h-[200px] px-[20px] relative">
                    <h1 className="text-[white] text-[30px] font-[400]">
                        {t('arrange_delivery.titleView')}
                    </h1>
                </div>
            </div>
            <div className="w-full flex flex-col justify-start items-center px-[20px] pt-[20px] pb-[100px] gap-[10px]">
                {delivery.loading
                    ?
                    <div className="MMyOrdersList__mainContainerWrapper">
                        <CircularProgress style={{marginTop: '20px', marginBottom: '20px'}}/>
                    </div>
                    : delivery.error
                        ? delivery.error.message
                        :
                        <>
                            <div className="w-full mb-[20px]" style={{borderBottom: '1px solid #48A5D1'}}>
                                <h2 className="text-[20px]">{t('header.orders')}</h2>
                            </div>
                            {delivery.result?.data.orders.length === 0
                                ? <EmptyData text={t('mobile.no_data')} icon={<OrdersEmptyIcon/>}/>
                                : delivery.result?.data.orders.map((order: any, index: number) => (
                                    <Link
                                        key={index}
                                        to={`/orders/${order.id}`}
                                        className="w-full flex flex-col rounded-[4px] shadow-md p-[10px]"
                                    >
                                        <div
                                            className="w-full flex justify-between items-start gap-[20px] pb-[10px] mb-[10px]"
                                            style={{borderBottom: '1px solid gray'}}>
                                            <div className='flex justify-start items-start gap-[10px]'>
                                                <img src={order.country.icon} alt="icon" className='h-[30px]'/>
                                                <div className="flex flex-col justify-start items-start gap-[7px]">
                                                    <p className='text-[] text-[12px] leading-3'>{t('mobile.order')} № <span
                                                        className='text-[#48A5D1]'>{order.tracking_number}</span></p>
                                                    <p className='text-[#48A5D1] text-[12px] leading-3'>{order.status?.name}...</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-full flex justify-between items-start gap-[10px]">
                                            <p className='text-[12px] text-[#48A5D1] font-[400]'>{t('order_table.created_at')}: {(moment(order.created_at)).format('DD.MM.YYYY').toString()}</p>
                                            <p className='text-[12px] text-[#48A5D1] font-[400]'>
                                                {order.weight
                                                    ? order.total_price
                                                        ? `${order.weight} ${t('mobile.kilogram')}/${order.total_price}$`
                                                        : `${order.weight} ${t('mobile.kilogram')}`
                                                    : order.total_price
                                                        ? `${order.total_price}$`
                                                        : ``
                                                }
                                            </p>
                                        </div>
                                    </Link>
                                ))
                            }
                            <div
                                className="w-full mt-[20px] pt-[20px] flex flex-col justify-start items-start gap-[10px]"
                                style={{borderTop: '1px solid #48A5D1'}}>
                                <p className='text-[] text-[12px] leading-3'>{t('header.delivery')} № <span
                                    className='text-[#48A5D1]'>{delivery.result?.data.id}</span></p>
                                <p className=' text-[12px] leading-3'>{t('order_table.status')}: <span
                                    className='text-[#48A5D1]'>{delivery.result?.data.status?.name}...</span></p>
                                <p className=' text-[12px] leading-3'>{t('arrange_delivery.payment_type')}: <span
                                    className='text-[#48A5D1]'>{delivery.result?.data.payment?.payment}</span></p>
                                <p className=' text-[12px] leading-3'>{t('arrange_delivery.address')}: <span
                                    className='text-[#48A5D1]'>{delivery.result?.data.address}</span></p>
                                <p className=' text-[12px] leading-3'>{t('arrange_delivery.phone')}: <span
                                    className='text-[#48A5D1]'>{delivery.result?.data.phone}</span></p>
                                <p className=' text-[12px] leading-3'>{t('order_table.created_at')}: <span
                                    className='text-[#48A5D1]'>{(moment(delivery.result?.data.created_at)).format('DD.MM.YYYY').toString()}</span></p>
                            </div>
                        </>
                }
            </div>
        </>
    );
}


