import React from 'react'
import i18next from "i18next";
import {Button, Menu, MenuItem} from "@mui/material";
import {currentLanguageCode} from "../http";

interface ILanguageProps{
    open: string;
    close: string;
    select: string;
}
function Localize({style}:{style?: any}){
    //change language
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [lang, setLang] = React.useState<null | string>(currentLanguageCode);
    const open = Boolean(anchorEl);
    const handleLanguageClick = (prop: keyof ILanguageProps) => (e: React.MouseEvent<HTMLButtonElement>) => {
        if(prop === "open"){
            setAnchorEl(e.currentTarget);
        }
        if(prop === "close"){
            setAnchorEl(null);
        }
    };
    return(
        <div style={style}>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleLanguageClick('open')}
                sx={{color: '#fff', background:'#48A5D1', minWidth:'unset'}}
            >
                {lang}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleLanguageClick('close')}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={()=>{
                    setAnchorEl(null);
                    setLang("ru")
                    i18next.changeLanguage("ru")
                }} value="ru">ru</MenuItem>
                <MenuItem onClick={()=>{
                    setAnchorEl(null);
                    setLang("en")
                    i18next.changeLanguage("en")
                }} value="en">en</MenuItem>
            </Menu>
        </div>
    )
}
export default Localize
