import React from 'react'
import '../../../styles/desktopVersion/orderView.css'
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {useParams} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {OrderService} from "../../../services/OrderService";

export default function OrderView() {
    //translation
    const {t} = useTranslation()

    let {id} = useParams()
    const order = OrderService.GetOrderInfo(id)
    const orderProductsColumns: GridColDef[] = [
        {field: 'id', headerName: 'ID', flex: 1, sortable: false, hide: true},
        {field: 'name', headerName: `${t('order_table.name_of_product')}`, sortable: false, flex: 1},
        {field: 'quantity', headerName: `${t('order_table.quantity')}`, sortable: false, flex: 1},
        {field: 'price', headerName: `${t('order_table.price')}`, sortable: false, flex: 1},
    ];
    return (
        <>
            <h1 className='text-[30px] text-black font-[400] mb-[50px]'>{t('view_order.title')}</h1>
            {order.loading
                ? <div className='orderView__body' style={{alignItems: 'center'}}>
                    <CircularProgress style={{marginTop: '20px', marginBottom: '20px'}}/>
                </div>
                :
                !order.error &&
                <div className="orderView__mainContainer">
                    <div className="orderView__header">
                        <p>{t('view_order.order')}: {order.result?.data.tracking_number}</p>
                    </div>
                    <div className="orderView__body">
                        <div className="orderView__orderInfoBox">
                            <div className="orderView-orderInfoBox__infoItem">
                                <p className="orderInfoBox-infoItem__title">{t('input_labels.tracking_number')}:</p>
                                <span
                                    className="orderInfoBox-infoItem__text">{order.result?.data.tracking_number}</span>
                            </div>
                            <div className="orderView-orderInfoBox__infoItem">
                                <p className="orderInfoBox-infoItem__title">{t('input_labels.store')}:</p>
                                <span className="orderInfoBox-infoItem__text">{order.result?.data.store?.name}</span>
                            </div>
                            <div className="orderView-orderInfoBox__infoItem">
                                <p className="orderInfoBox-infoItem__title">{t('input_labels.type_of_transport')}:</p>
                                <span className="orderInfoBox-infoItem__text"><img
                                    src={order.result?.data.type_of_transport.icon} alt="img"/></span>
                            </div>
                            <div className="orderView-orderInfoBox__infoItem">
                                <p className="orderInfoBox-infoItem__title">{t('input_labels.weight')}:</p>
                                <span
                                    className="orderInfoBox-infoItem__text">{order.result?.data.rounded_weight && `${order.result?.data.rounded_weight} кг`}</span>
                            </div>
                            <div className="orderView-orderInfoBox__infoItem">
                                <p className="orderInfoBox-infoItem__title">{t('input_labels.price')}:</p>
                                <span
                                    className="orderInfoBox-infoItem__text">{order.result?.data.total_price && `${order.result?.data.total_price} USD`}</span>
                            </div>
                            <div className="orderView-orderInfoBox__infoItem">
                                <p className="orderInfoBox-infoItem__title">{t('input_labels.receiver')}:</p>
                                <span className="orderInfoBox-infoItem__text">{order.result?.data.user.full_name}</span>
                            </div>
                        </div>
                        <div style={{width: '100%', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)'}}>
                            <DataGrid
                                rows={order.result?.data.products.map((product: any) => (
                                    {
                                        id: `${product.id}`,
                                        name: `${product.title}`,
                                        quantity: `${product.quantity}`,
                                        price: `${product.price}`,
                                    }
                                ))}
                                columns={orderProductsColumns}
                                localeText={{
                                    noRowsLabel: "Нет информации",
                                  }}
                                pageSize={7}
                                rowsPerPageOptions={[7]}
                                disableColumnFilter
                                disableSelectionOnClick
                                checkboxSelection={false}
                                isRowSelectable={() => false}
                                disableColumnMenu
                                autoHeight
                            />
                        </div>
                    </div>
                    <div className="orderView__footer">
                        {order.result?.data.order_status_history.map((status: any, index: number) => (
                                <div className="orderView-orderFooter__infoItem" key={index}>
                                    <p className="orderInfoBox-footer__title">{status.status.name}:</p>
                                    <span
                                        className="orderInfoBox-footer__text">{(moment(status.created_at)).format('DD.MM.YYYY').toString()}</span>
                                </div>
                            )
                        )}
                    </div>
                </div>
            }
        </>
    )
}