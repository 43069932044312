import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  Avatar,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  checkModalResponse,
  MyTimer,
  VisuallyHiddenInput,
} from "../../../helpers/helpers";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import { UserService } from "../../../services/UserService";
import { AddressesService } from "../../../services/AddressesService";

const formInitialValues = {
  values: {
    first_name: "",
    last_name: "",
    email: "",
    country: "",
    password: "",
    confirm_password: "",
    otp: "",
    phone: "",
    inn: "",
    city: "",
    street: "",
    house_number: "",
    pick_up_point: "",
    passport_front_side: null,
    passport_back_side: null,
  },
  validation: {
    error: {
      first_name: false,
      last_name: false,
      email: false,
      country: false,
      password: false,
      confirm_password: false,
      otp: false,
      phone: false,
      inn: false,
      city: false,
      street: false,
      house_number: false,
      passport_front_side: false,
      passport_back_side: false,
    },
    message: {
      first_name: "",
      last_name: "",
      email: "",
      country: "",
      password: "",
      confirm_password: "",
      otp: "",
      phone: "",
      inn: "",
      city: "",
      street: "",
      house_number: "",
      passport_front_side: "",
      passport_back_side: "",
    },
  },
  showPassword: false,
  sendCode: false,
  // requested: false,
};

const RegisterModal = ({
  setAuthSteps,
  setAuthAgain,
}: {
  setAuthSteps: Dispatch<SetStateAction<string>>;
  setAuthAgain: Dispatch<SetStateAction<boolean>>;
}) => {
  //translation
  const { t } = useTranslation();

  const getPersonalCountries = UserService.GetPersonalCountries("customer");
  const [registerSteps, setRegisterSteps] = useState("registerPhoneModal");
  const [form, setForm] = useState<any>(formInitialValues);

  const [errorText, setErrorText] = useState<any[]>([]);

  const pointsList = AddressesService.GetPointsList();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (registerSteps === "registerPhoneModal") {
      setRegisterSteps("registerPassportModal");
    }
    if (registerSteps === "registerPassportModal") {
      setRegisterSteps("registerCredentialModal");
    }
    if (registerSteps === "registerCredentialModal") {
      setRegisterSteps("registerPasswordModal");
    }
    if (registerSteps === "registerPasswordModal") {
      const { password, confirm_password } = form.values;

      if (
        password.length < 6 ||
        password.length > 32 ||
        confirm_password.length < 6 ||
        confirm_password.length > 32
      ) {
        return;
      }

      setForm({
        ...form,
      });
      setLoading(true);
      UserService.PostUserRegisterData(form.values)
        .then(() => {
          setForm(formInitialValues);
          setAuthAgain(true);
          setAuthSteps("auth");
        })
        .catch((err: any) => {
          if (err.response && err.response.data) {
            const { email, confirm_password, password, phone, otp } =
              err.response.data;
            if (email) setErrorText(email);
            if (phone) setErrorText(phone);
            if (confirm_password) setErrorText(confirm_password);
            if (password) setErrorText(password);
            if (otp) setErrorText(otp);
            checkModalResponse(err.response.data, setForm, form);
          } else {
            console.error("Ошибка в ответе сервера:", err);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const handleResendCode = () => {
    setForm({
      ...form,
      sendCode: true,
      validation: {
        ...form.validation,
        message: {
          ...form.validation.message,
          code: `${t("auth.otp_sent")}`,
        },
      },
    });
    const time = new Date();
    time.setSeconds(time.getSeconds() + 59);
    currentTimeLeftForCode.restart(time);
    UserService.PostUserEmailData(form.values.email)
      .then(() => {})
      .catch(() => {
        setForm({
          ...form,
          sendCode: false,
          validation: {
            ...form.validation,
            message: {
              ...form.validation.message,
              code: "",
            },
          },
        });
      });
  };

  const time = new Date();
  const currentTimeLeftForCode = MyTimer(time);
  useEffect(() => {
    if (currentTimeLeftForCode.seconds === 0) {
      setForm({ ...form, sendCode: false });
    }
  }, [currentTimeLeftForCode.seconds]);

  return (
    <div className="registerModal" id="registerModal">
      <div className="registerModal__mainContainer">
        {registerSteps === "registerPhoneModal" && (
          <form onSubmit={handleSubmit} className="registerModal__form">
            <Button
              className="goBackItem"
              onClick={() => {
                setAuthSteps("auth");
              }}
            >
              <ArrowBackIosIcon style={{ color: "#48A5D1" }} />{" "}
              <span>{t("button.back")}</span>
            </Button>

            <Avatar className="Avatar">
              <LockOutlinedIcon />
            </Avatar>

            <p className="authModal-form__title">{t("auth.registration")}</p>

            <div className="w-full flex flex-col justify-start items-center gap-[20px] mt-[20px]">
              <div className="w-full flex items-start gap-[20px]">
                <TextField
                  fullWidth
                  label={t("input_labels.first_name")}
                  type="text"
                  variant="outlined"
                  value={form.values.first_name}
                  error={form.validation.error.first_name}
                  helperText={form.validation.message.first_name}
                  onChange={(event) => {
                    const input = event.target.value;
                    const isLatin = /^[a-zA-Z\s]*$/.test(input);

                    if (!isLatin) {
                      setForm({
                        ...form,
                        values: {
                          ...form.values,
                          first_name: "",
                        },
                        validation: {
                          ...form.validation,
                          message: {
                            ...form.validation.message,
                            first_name: `${t("auth.enter_in_latin")}`,
                          },
                          error: {
                            ...form.validation.error,
                            first_name: true,
                          },
                        },
                      });
                    } else {
                      setForm({
                        ...form,
                        values: {
                          ...form.values,
                          first_name: input,
                        },
                        validation: {
                          ...form.validation,
                          message: {
                            ...form.validation.message,
                            first_name: "",
                          },
                          error: {
                            ...form.validation.error,
                            first_name: false,
                          },
                        },
                      });
                    }
                  }}
                  required
                />

                <TextField
                  fullWidth
                  label={t("input_labels.last_name")}
                  type="text"
                  variant="outlined"
                  value={form.values.last_name}
                  error={form.validation.error.last_name}
                  helperText={form.validation.message.last_name}
                  onChange={(event) => {
                    const input = event.target.value;
                    const isLatin = /^[a-zA-Z\s]*$/.test(input);

                    if (!isLatin) {
                      setForm({
                        ...form,
                        values: {
                          ...form.values,
                          last_name: "",
                        },
                        validation: {
                          ...form.validation,
                          message: {
                            ...form.validation.message,
                            last_name: `${t("auth.enter_in_latin")}`,
                          },
                          error: {
                            ...form.validation.error,
                            last_name: true,
                          },
                        },
                      });
                    } else {
                      setForm({
                        ...form,
                        values: {
                          ...form.values,
                          last_name: input,
                        },
                        validation: {
                          ...form.validation,
                          message: {
                            ...form.validation.message,
                            last_name: "",
                          },
                          error: {
                            ...form.validation.error,
                            last_name: false,
                          },
                        },
                      });
                    }
                  }}
                  required
                />
              </div>
              <FormControl required fullWidth>
                <InputLabel>{t("input_labels.country")}</InputLabel>
                <Select
                  value={form.values.country}
                  error={form.validation.error.country}
                  label={t("input_labels.country")}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      values: {
                        ...form.values,
                        country: event.target.value,
                      },
                    });
                  }}
                >
                  {!getPersonalCountries.loading &&
                    !getPersonalCountries.error &&
                    getPersonalCountries.result?.data.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.country}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                  {form.validation.message.country}
                </FormHelperText>
              </FormControl>
            </div>

            <Button className="registerModal-form__submitButton" type="submit">
              {t("button.next")}
            </Button>

            <div
              className="authModal-form__subLinks"
              style={{ justifyContent: "flex-end" }}
            >
              <p>
                {t("auth.have_account")}
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setAuthSteps("auth");
                  }}
                >
                  {t("button.sign_in")}
                </span>
              </p>
            </div>
          </form>
        )}
        {registerSteps === "registerPassportModal" && (
          <form onSubmit={handleSubmit} className="registerModal__form">
            <Button
              className="goBackItem"
              onClick={() => {
                setRegisterSteps("registerPhoneModal");
              }}
            >
              <ArrowBackIosIcon style={{ color: "#48A5D1" }} />{" "}
              <span>{t("button.back")}</span>
            </Button>
            <Avatar className="Avatar">
              <LockOutlinedIcon />
            </Avatar>
            <p className="authModal-form__title">{t("auth.registration")}</p>

            <div className="w-full flex flex-col justify-start items-center gap-[20px] mt-[20px]">
              <TextField
                variant="outlined"
                fullWidth={true}
                disabled
                value={
                  form.values.passport_front_side === null
                    ? t("personal.passport_front_side")
                    : form.values.passport_front_side?.name
                }
                error={form.validation.error.passport_front_side}
                helperText={form.validation.message.passport_front_side}
                required={true}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {form.values.passport_front_side !== null ? (
                        <Button
                          onClick={() => {
                            setForm({
                              ...form,
                              values: {
                                ...form.values,
                                passport_front_side: null,
                              },
                            });
                          }}
                        >
                          <CloseIcon />
                        </Button>
                      ) : (
                        <Button
                          component="label"
                          role={undefined}
                          tabIndex={-1}
                          variant="contained"
                        >
                          <AttachFileIcon />
                          <VisuallyHiddenInput
                            required
                            type="file"
                            accept=".png, .jpg, .jpeg, .svg"
                            onChange={(event) => {
                              if (event.target.files) {
                                setForm({
                                  ...form,
                                  values: {
                                    ...form.values,
                                    passport_front_side: event.target.files[0],
                                  },
                                });
                              }
                            }}
                          />
                        </Button>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                variant="outlined"
                fullWidth={true}
                disabled
                value={
                  form.values.passport_back_side === null
                    ? t("personal.passport_back_side")
                    : form.values.passport_back_side?.name
                }
                error={form.validation.error.passport_back_side}
                helperText={form.validation.message.passport_back_side}
                required={true}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {form.values.passport_back_side !== null ? (
                        <Button
                          onClick={() => {
                            setForm({
                              ...form,
                              values: {
                                ...form.values,
                                passport_back_side: null,
                              },
                            });
                          }}
                        >
                          <CloseIcon />
                        </Button>
                      ) : (
                        <Button
                          component="label"
                          role={undefined}
                          tabIndex={-1}
                          variant="contained"
                        >
                          <AttachFileIcon />
                          <VisuallyHiddenInput
                            required
                            type="file"
                            accept=".png, .jpg, .jpeg, .svg"
                            onChange={(event) => {
                              if (event.target.files) {
                                setForm({
                                  ...form,
                                  values: {
                                    ...form.values,
                                    passport_back_side: event.target.files[0],
                                  },
                                });
                              }
                            }}
                          />
                        </Button>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <Button className="registerModal-form__submitButton" type="submit">
              {t("button.next")}
            </Button>
            <div className="authModal-form__subLinks">
              <p>
                {t("auth.have_account")}
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setAuthSteps("auth");
                  }}
                >
                  {t("button.sign_in")}
                </span>
              </p>
            </div>
          </form>
        )}
        {registerSteps === "registerCredentialModal" && (
          <form onSubmit={handleSubmit} className="registerModal__form">
            <Button
              className="goBackItem"
              onClick={() => {
                setRegisterSteps("registerPassportModal");
              }}
            >
              <ArrowBackIosIcon style={{ color: "#48A5D1" }} />{" "}
              <span>{t("button.back")}</span>
            </Button>
            <Avatar className="Avatar">
              <LockOutlinedIcon />
            </Avatar>
            <p className="authModal-form__title">{t("auth.registration")}</p>

            <div className="w-full flex flex-col justify-start items-center gap-[20px] mt-[20px]">
              <TextField
                variant="outlined"
                fullWidth
                type="number"
                label={t("input_labels.inn")}
                value={form.values.inn}
                error={form.validation.error.inn}
                helperText={form.validation.message.inn}
                required
                onChange={(event) => {
                  if (event.target.value.length <= 14) {
                    setForm({
                      ...form,
                      values: {
                        ...form.values,
                        inn: event.target.value,
                      },
                    });
                  }
                }}
              />
              <TextField
                variant="outlined"
                fullWidth
                type="text"
                label={t("input_labels.city")}
                value={form.values.city}
                error={form.validation.error.city}
                helperText={form.validation.message.city}
                required
                onChange={(event) => {
                  setForm({
                    ...form,
                    values: {
                      ...form.values,
                      city: event.target.value,
                    },
                  });
                }}
              />
              <TextField
                variant="outlined"
                fullWidth
                type="text"
                label={t("input_labels.street")}
                value={form.values.street}
                error={form.validation.error.street}
                helperText={form.validation.message.street}
                required
                onChange={(event) => {
                  setForm({
                    ...form,
                    values: {
                      ...form.values,
                      street: event.target.value,
                    },
                  });
                }}
              />
              <TextField
                variant="outlined"
                fullWidth
                type="text"
                label={t("input_labels.house_number")}
                value={form.values.house_number}
                error={form.validation.error.house_number}
                helperText={form.validation.message.house_number}
                required
                onChange={(event) => {
                  setForm({
                    ...form,
                    values: {
                      ...form.values,
                      house_number: event.target.value,
                    },
                  });
                }}
              />
              <FormControl className="w-full">
                <InputLabel>Выберите ПВЗ *</InputLabel>
                <Select
                  label="Выберите ПВЗ *"
                  required
                  value={form.values.pick_up_point}
                  onChange={(event: any) => {
                    setForm({
                      ...form,
                      values: {
                        ...form.values,
                        pick_up_point: event.target.value,
                      },
                    });
                  }}
                >
                  {!pointsList.loading &&
                    !pointsList.error &&
                    pointsList.result?.data.map(
                      (country: any, index: number) => (
                        <MenuItem key={index} value={country.id}>
                          {country.name}
                        </MenuItem>
                      )
                    )}
                </Select>
              </FormControl>
            </div>

            <Button className="registerModal-form__submitButton" type="submit">
              {t("button.next")}
            </Button>
            <div className="authModal-form__subLinks">
              <p>
                {t("auth.have_account")}
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setAuthSteps("auth");
                  }}
                >
                  {t("button.sign_in")}
                </span>
              </p>
            </div>
          </form>
        )}
        {registerSteps === "registerPasswordModal" && (
          <form onSubmit={handleSubmit} className="registerModal__form">
            <IconButton
              className="goBackItem"
              onClick={() => {
                setRegisterSteps("registerCredentialModal");
              }}
            >
              <ArrowBackIosIcon style={{ color: "#48A5D1" }} />{" "}
              <span>{t("button.back")}</span>
            </IconButton>

            <Avatar className="Avatar">
              <LockOutlinedIcon />
            </Avatar>
            <p className="authModal-form__title">{t("auth.registration")}</p>

            <div className="w-full flex flex-col justify-start items-center gap-[20px] mt-[20px]">
              <TextField
                label={t("input_labels.phone")}
                variant="outlined"
                fullWidth
                value={form.values.phone}
                onChange={(event) => {
                  setForm({
                    ...form,
                    values: {
                      ...form.values,
                      phone: event.target.value,
                    },
                  });
                }}
                error={form.validation.error.phone}
                helperText={form.validation.message.phone}
                required
              />
              <TextField
                label={t("input_labels.email")}
                variant="outlined"
                type="email"
                fullWidth
                required
                value={form.values.email}
                onChange={(event) => {
                  setForm({
                    ...form,
                    values: {
                      ...form.values,
                      email: event.target.value,
                    },
                  });
                }}
                error={form.validation.error.email}
                helperText={form.validation.message.email}
              />
              {form.values.email !== "" && (
                <TextField
                  label={t("input_labels.code")}
                  variant="outlined"
                  margin="normal"
                  fullWidth={true}
                  value={form.values.otp}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      values: {
                        ...form.values,
                        otp: event.target.value,
                      },
                    });
                  }}
                  error={form.validation.error.otp}
                  helperText={form.validation.message.otp}
                  required={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {!form.sendCode ? (
                          <Button onClick={handleResendCode}>
                            {t("input_labels.send_code")}
                          </Button>
                        ) : (
                          <Button>0:{currentTimeLeftForCode.seconds}</Button>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              <div className="w-full flex justify-between items-start gap-[20px]">
                {/* <TextField
                  label={t("input_labels.password")}
                  variant="outlined"
                  type={form.showPassword ? "text" : "password"}
                  value={form.values.password}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      values: {
                        ...form.values,
                        password: event.target.value,
                      },
                    });
                  }}
                  error={form.validation.error.password}
                  helperText={form.validation.message.password}
                  required={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setForm({
                              ...form,
                              showPassword: !form.showPassword,
                            });
                          }}
                        >
                          {form.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label={t("input_labels.repeat_password")}
                  variant="outlined"
                  type={form.showPassword ? "text" : "password"}
                  value={form.values.confirm_password}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      values: {
                        ...form.values,
                        confirm_password: event.target.value,
                      },
                    });
                  }}
                  error={form.values.password !== form.values.confirm_password}
                  helperText={
                    form.values.password !== form.values.confirm_password &&
                    t("input_labels.passwords_are_not_same")
                  }
                  required={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setForm({
                              ...form,
                              showPassword: !form.showPassword,
                            });
                          }}
                        >
                          {form.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                /> */}

                <TextField
                  label={t("input_labels.password")}
                  variant="outlined"
                  type={form.showPassword ? "text" : "password"}
                  value={form.values.password}
                  onChange={(event) => {
                    const newPassword = event.target.value;
                    if (newPassword.length >= 6 && newPassword.length <= 32) {
                      setForm({
                        ...form,
                        values: {
                          ...form.values,
                          password: newPassword,
                        },
                        validation: {
                          ...form.validation,
                          error: {
                            ...form.validation.error,
                            password: false,
                          },
                          message: {
                            ...form.validation.message,
                            password: "",
                          },
                        },
                      });
                    } else {
                      setForm({
                        ...form,
                        values: {
                          ...form.values,
                          password: newPassword,
                        },
                        validation: {
                          ...form.validation,
                          error: {
                            ...form.validation.error,
                            password: true,
                          },
                          message: {
                            ...form.validation.message,
                            password: t("min 6 characters max 32 characters"),
                          },
                        },
                      });
                    }
                  }}
                  error={form.validation.error.password}
                  helperText={form.validation.message.password}
                  required={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setForm({
                              ...form,
                              showPassword: !form.showPassword,
                            });
                          }}
                        >
                          {form.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  label={t("input_labels.repeat_password")}
                  variant="outlined"
                  type={form.showPassword ? "text" : "password"}
                  value={form.values.confirm_password}
                  onChange={(event) => {
                    const newPassword = event.target.value;
                    if (newPassword.length >= 6 && newPassword.length <= 32) {
                      setForm({
                        ...form,
                        values: {
                          ...form.values,
                          confirm_password: newPassword,
                        },
                        validation: {
                          ...form.validation,
                          error: {
                            ...form.validation.error,
                            password: form.values.password !== newPassword,
                          },
                          message: {
                            ...form.validation.message,
                            password:
                              form.values.password !== newPassword
                                ? t("passwords are not same")
                                : "",
                          },
                        },
                      });
                    } else {
                      setForm({
                        ...form,
                        values: {
                          ...form.values,
                          confirm_password: newPassword,
                        },
                        validation: {
                          ...form.validation,
                          error: {
                            ...form.validation.error,
                            password: true,
                          },
                          message: {
                            ...form.validation.message,
                            password: t("password length error"),
                          },
                        },
                      });
                    }
                  }}
                  error={form.values.password !== form.values.confirm_password}
                  helperText={
                    form.values.password !== form.values.confirm_password &&
                    t("input_labels.passwords_are_not_same")
                  }
                  required={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setForm({
                              ...form,
                              showPassword: !form.showPassword,
                            });
                          }}
                        >
                          {form.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <FormControlLabel
                control={<Checkbox required={true} />}
                label={t("input_labels.accept_with_user_agreement")}
              />
            </div>

            {errorText.length > 0 && (
              <p className="text-red-500 text-[12px] mt-1">{errorText}</p>
            )}

            <Button className="registerModal-form__submitButton" type="submit">
              {loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                t("button.sign_up")
              )}
            </Button>

            <div
              className="authModal-form__subLinks"
              style={{ justifyContent: "flex-end" }}
            >
              <p>
                {t("auth.have_account")}
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setAuthSteps("auth");
                  }}
                >
                  {t("button.sign_in")}
                </span>
              </p>
            </div>
            <div
              className="authModal-form__subLinks"
              style={{ justifyContent: "flex-end", marginTop: "10px" }}
            >
              <Link to="/agreement" target="_blank" rel="noopener noreferrer">
                <p>
                  <span>{t("auth.user_agreement")}</span>
                </p>
              </Link>
            </div>
          </form>
        )}
      </div>
      <div className="registerModal__imgContainer"></div>
    </div>
  );
};

export default RegisterModal;
