import React, {useState} from "react";
import '../../styles/mobileVersion/Mnotifications.css'
import {Button, IconButton, Menu, MenuItem, Pagination,} from "@mui/material";
import {useNavigate} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import {useTranslation} from "react-i18next";
import {NotificationsService} from "../../services/NotificationsService";
import {EmptyData} from "../../helpers/helpers";
import {NotificationsEmptyIcon} from "../../common/common";
import DeleteIcon from "@mui/icons-material/Delete";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const tableFilterInitialState = {
    page: 1,
}

function MNotifications() {
    //translation
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [table, setTable] = useState<any>({
        filter: tableFilterInitialState,
    })

    const notifications = NotificationsService.GetNotifications(table.filter)
    return (
        <>
            <div
                className="w-full flex flex-col justify-start items-center gap-[20px] bg-mobileBackground bg-center bg-cover">
                <div
                    className="w-full flex flex-col justify-center items-center gap-[20px] h-[200px] px-[20px] relative">
                    <h1 className="text-[white] text-[30px] font-[400]">
                        {t('notification.title')}
                    </h1>
                </div>
            </div>
            <div className="w-full flex flex-col justify-start items-center px-[20px] pt-[20px] pb-[100px] gap-[10px]">
                {notifications.loading
                    ? <CircularProgress style={{marginTop: '20px', marginBottom: '20px'}}/>
                    : notifications.error
                        ? <div>Error</div>
                        : notifications.result?.data.results.length === 0
                            ? <EmptyData text={t('mobile.no_data')} icon={<NotificationsEmptyIcon/>}/>
                            :
                            notifications.result?.data.results.map((notification: any, index: number) => (
                                <>
                                    <Button
                                        key={index}
                                        fullWidth
                                        size='large'
                                        variant='contained'
                                        color='white'
                                        onClick={() => navigate(`/notification/${notification.id}`)}
                                        endIcon={
                                            <div onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault()
                                            }}>
                                                <PopupState variant="popover" popupId="demo-popup-menu">
                                                    {(popupState) => (
                                                        <React.Fragment>
                                                            <IconButton {...bindTrigger(popupState)}>
                                                                <MoreVertIcon/>
                                                            </IconButton>
                                                            <Menu {...bindMenu(popupState)}>
                                                                <MenuItem
                                                                    onClick={() => {
                                                                        NotificationsService.DeleteNotifications(notification.id).then(() => {
                                                                            notifications.execute()
                                                                        })
                                                                    }}
                                                                    disableRipple
                                                                >
                                                                    <Button size='small'
                                                                            startIcon={<DeleteIcon
                                                                                fontSize='small'/>}>
                                                                        <p className='text-[12px]'>{t('button.delete')}</p>
                                                                    </Button>
                                                                </MenuItem>
                                                            </Menu>
                                                        </React.Fragment>
                                                    )}
                                                </PopupState>
                                            </div>

                                        }
                                    >
                                        <div className="w-full flex justify-between">
                                            {!notification.read
                                                ? <p style={{color: 'black'}}>{notification.notification.title}</p>
                                                : <p style={{color: 'grey'}}>{notification.notification.title}</p>
                                            }
                                        </div>
                                    </Button>
                                </>
                            ))
                }
                <Pagination
                    style={{marginTop: '20px'}}
                    count={!notifications.loading && !notifications.error ? notifications.result?.data.total_pages : 1}
                    page={!notifications.loading && !notifications.error ? notifications.result?.data.current_page : 1}
                    onChange={(event, value: number) => {
                        setTable({
                            ...table,
                            filter: {
                                ...table.filter,
                                page: value,
                            }
                        })
                    }}
                />
            </div>
        </>
    );
}

export default MNotifications;