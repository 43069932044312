import {useAsync} from "react-async-hook";
import {$axios} from "../http";
import {CreateSearchParams} from "../helpers/helpers";
import {createSearchParams} from "react-router-dom";

export const TransactionsService = {
    GetTransactions(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/customer/transactions/' + CreateSearchParams(searchParamsObj));
        }, [`?${createSearchParams(searchParamsObj)}`])
    }
}