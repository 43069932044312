import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Orders from "./pages/desktopVersion/orders/Orders";
import Layout from "./components/desktopVersion/Layout";
import Personal from "./pages/desktopVersion/Personal";
import Home from "./pages/desktopVersion/Home";
import Transactions from "./pages/desktopVersion/Transactions";
import Notification from "./pages/desktopVersion/Notification";
import History from "./pages/desktopVersion/History";
import OrdersDetails from "./pages/desktopVersion/orders/OrdersDetails";
import DeliveryAdd from "./pages/desktopVersion/deliveries/DeliveryAdd";
import OrderView from "./pages/desktopVersion/orders/OrderView";
import { useMediaQuery } from "usehooks-ts";
import Agreement from "./pages/Agreement";
import Delivery from "./pages/desktopVersion/deliveries/Delivery";
import DeliveryView from "./pages/desktopVersion/deliveries/DeliveryView";
import MiddleWare from "./http/MiddleWare";
import Auth from "./pages/desktopVersion/auth/Auth";
import { Address } from "./pages/desktopVersion/Address";

import MLayout from "./components/mobileVersion/MLayout";
import MHome from "./pages/mobileVersion/MHome";
import MCurrency from "./pages/mobileVersion/MCurrency";
import MOrdersDetails from "./pages/mobileVersion/orders/MOrdersDetails";
import MDeliveryAdd from "./pages/mobileVersion/deliveries/MDeliveryAdd";
import MDelivery from "./pages/mobileVersion/deliveries/MDelivery";
import MDeliveryView from "./pages/mobileVersion/deliveries/MDeliveryView";
import MNotifications from "./pages/mobileVersion/MNotifications";
import MNotificationsView from "./pages/mobileVersion/MNotificationsView";
import MTransactions from "./pages/mobileVersion/MTransactions";
import MPersonal from "./pages/mobileVersion/MPersonal";
import MAddresses from "./pages/mobileVersion/addresses/MAddresses";
import MContacts from "./pages/mobileVersion/MContacts";
import MAddressesView from "./pages/mobileVersion/addresses/MAddressesView";
import MAuthPage from "./pages/mobileVersion/auth/MAuth";
import MOrders from "./pages/mobileVersion/orders/MOrders";
import MOrderView from "./pages/mobileVersion/orders/MOrderView";
import OrderTracking from "./pages/desktopVersion/OrderTracking";
import MOrderTracking from "./pages/mobileVersion/MOrderTracking";

function App() {
  const isMobileDevice = useMediaQuery("(max-width: 720px)");
  const isDesktopDevice = useMediaQuery("(min-width: 721px)");
  return (
    <BrowserRouter>
      {isDesktopDevice && (
        <Routes>
          <Route
            path="login"
            element={
              <MiddleWare>
                <Auth />
              </MiddleWare>
            }
          />
          <Route
            path="/*"
            element={
              <MiddleWare>
                <Layout />
              </MiddleWare>
            }
          >
            <Route index element={<Home />} />
            <Route path="address/:id" element={<Address />} />
            <Route path="personal" element={<Personal />} />
            <Route path="delivery/*">
              <Route index element={<Delivery />} />
              <Route path=":id" element={<DeliveryView />} />
              <Route path="add" element={<DeliveryAdd />} />
            </Route>
            <Route path="orders/*">
              <Route index element={<Orders />} />
              <Route path=":id" element={<OrderView />} />
              <Route path="details" element={<OrdersDetails />} />
            </Route>
            <Route path="transactions" element={<Transactions />} />
            <Route path="notification" element={<Notification />} />
            <Route path="history" element={<History />} />
            <Route path="order-tracking" element={<OrderTracking />} />
          </Route>
          <Route path="agreement" element={<Agreement />} />
        </Routes>
      )}
      {isMobileDevice && (
        <Routes>
          <Route
            path="/*"
            element={
              <MiddleWare>
                <MLayout />
              </MiddleWare>
            }
          >
            <Route index element={<MHome />} />
            <Route path="orders/*">
              <Route index element={<MOrders />} />
              <Route path=":id" element={<MOrderView />} />
              <Route path="details" element={<MOrdersDetails />} />
            </Route>
            <Route path="delivery/*">
              <Route index element={<MDelivery />} />
              <Route path=":id" element={<MDeliveryView />} />
              <Route path="add" element={<MDeliveryAdd />} />
            </Route>
            <Route path="address/*">
              <Route index element={<MAddresses />} />
              <Route path=":id" element={<MAddressesView />} />
            </Route>
            <Route path="notification/*">
              <Route index element={<MNotifications />} />
              <Route path=":id" element={<MNotificationsView />} />
            </Route>
            <Route path="currency" element={<MCurrency />} />
            <Route path="transactions" element={<MTransactions />} />
            <Route path="personal" element={<MPersonal />} />
            <Route path="contacts" element={<MContacts />} />
            <Route path="order-tracking" element={<MOrderTracking />} />
          </Route>
          <Route
            path="login"
            element={
              <MiddleWare>
                <MAuthPage />
              </MiddleWare>
            }
          />
          <Route path="agreement" element={<Agreement />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;
