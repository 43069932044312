import {combineReducers, configureStore, Tuple} from '@reduxjs/toolkit'
import userReducer from './slices/userSlice'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import {thunk} from "redux-thunk";

const reducers = combineReducers({
    user: userReducer
});

const persistConfig = {
    key: 'globalClient',
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: () => new Tuple(thunk),
}, )