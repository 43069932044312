import React, {Dispatch, SetStateAction, useState} from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {Avatar, TextField, InputAdornment, IconButton, Button} from '@mui/material';
import {setCookie} from 'typescript-cookie'
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import {UserService} from "../../../services/UserService";
import jwtDecode from "jwt-decode";
import {useDispatch} from "react-redux";
import {access_token_name, refresh_token_name} from "../../../http";
import {login} from "../../../store/slices/userSlice";

const formInitialState = {
    values:{
        email: '',
        password: '',
    },
    validation: {
        errors:{
            email: false,
            password: false,
        },
        messages:{
            email: '',
            password: '',
        }
    },
    requested: false,
    showPassword: false,
}

const MAuthModal = ({setAuthSteps}: { setAuthSteps: Dispatch<SetStateAction<string>> }) => {
    //translation
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [form, setForm] = useState(formInitialState)
    const handleSubmit = (e: React.SyntheticEvent) =>{
        e.preventDefault()
        setForm({
            ...form,
            requested: true,
        });
        UserService.PostUserAuthData(form.values.email, form.values.password).then((res)=>{
            // Get the current time in seconds
            const currentTimeInSeconds = Math.floor(Date.now() / 1000);

            const accessDecode: any = jwtDecode(res.data.access)
            const refreshDecode: any = jwtDecode(res.data.refresh)

            const accessExpirationInSeconds = accessDecode.exp;
            const refreshExpirationInSeconds = refreshDecode.exp;

            // Calculate the difference in seconds
            const accessDifferenceInSeconds = accessExpirationInSeconds - currentTimeInSeconds;
            const refreshDifferenceInSeconds = refreshExpirationInSeconds - currentTimeInSeconds;

            // Convert the difference in seconds to days
            const accessDifferenceInDays = Math.ceil(accessDifferenceInSeconds / (60 * 60 * 24));
            const refreshDifferenceInDays = Math.ceil(refreshDifferenceInSeconds / (60 * 60 * 24));
            setCookie(access_token_name, res.data.access, {expires: accessDifferenceInDays})
            setCookie(refresh_token_name, res.data.refresh, {expires: refreshDifferenceInDays})

            dispatch(login({
                authed: true,
                user: {
                    ...res.data.user,
                    currency: res.data.currency
                }
            }))
        }).catch((err)=>{
            setForm({
                ...form,
                validation:{
                    ...form.validation,
                    errors:{
                        ...form.validation.errors,
                        email: true,
                        password: true
                    },
                    messages:{
                        ...form.validation.messages,
                        email: err.response.data.detail,
                        password: err.response.data.detail
                    },
                }
            })
        })
    }
    return (
        <div className='MAuthModal' id='MAuthModal'>
            <div className='MAuthModal__mainContainer'>
                <div className='MAuthModal__imgBox'>
                    <Avatar className='MAvatar'><LockOutlinedIcon/></Avatar>
                    <p className='MAuthModal-form__title'>{t('auth.authorization')}</p>
                </div>
                <form className='MAuthModal__form' onSubmit={handleSubmit}>
                    <TextField
                        label={t('input_labels.email')}
                        variant="outlined"
                        margin='normal'
                        type='email'
                        fullWidth={true}
                        value={form.values.email}
                        onChange={(e)=>{
                            setForm({
                                ...form,
                                values:{
                                    ...form.values,
                                    email: e.target.value
                                }
                            })
                        }}
                        error={form.validation.errors.email}
                        helperText={form.validation.messages.email}
                        required={true}
                    />
                    <TextField
                        label={t('input_labels.password')}
                        variant="outlined"
                        margin='normal'
                        fullWidth={true}
                        type={form.showPassword ? 'text' : 'password'}
                        value={form.values.password}
                        onChange={(e)=>{
                            setForm({
                                ...form,
                                values:{
                                    ...form.values,
                                    password: e.target.value
                                }
                            })
                        }}
                        error={form.validation.errors.password}
                        helperText={form.validation.messages.password}
                        required={true}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={()=>{
                                            setForm({
                                                ...form,
                                                showPassword: !form.showPassword,
                                            })
                                        }}
                                    >
                                        {form.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button disabled={form.requested} className='authModal-form__submitButton' type='submit'>
                        {!form.requested
                            ? t('button.sign_in')
                            : <CircularProgress sx={{color: 'white'}}/>
                        }
                    </Button>
                    <div className='w-full flex items-start justify-between gap-[5px] mt-[30px]'>
                        <p className='text-[#48A5D1] text-[12px] font-[400] cursor-pointer shrink-0 underline'
                           onClick={() => setAuthSteps('recover')}
                        >
                            {t('auth.forgot_password')}
                        </p>
                        <div className='flex justify-end items-center gap-[5px] flex-wrap'>
                            <p className='text-[#48A5D1] text-[12px] font-[400]'>{t('auth.no_account')}</p>
                            <p className='text-[#48A5D1] text-[12px] font-[400] cursor-pointer underline'
                               onClick={() => setAuthSteps('register')}
                            >
                                {t('button.sign_up')}
                            </p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default MAuthModal;

