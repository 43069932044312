import React, { useEffect, useState } from "react";
import "../../styles/desktopVersion/history.css";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridColDef, GridFooterContainer } from "@mui/x-data-grid";
import { Pagination } from "@mui/material";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { OrderService } from "../../services/OrderService";
import { TextField, InputAdornment, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

function MOrderTracking() {
  //translation
  const { t } = useTranslation();

  let navigate = useNavigate();

  const [filterValue, setFilterValue] = React.useState<any>({
    search: "",
  });

  const [historyData, setHistoryData] = useState<any>([]);

  let orderTracking = OrderService.GetOrdersList(filterValue);

  useEffect(() => {
    if (!orderTracking.loading && !orderTracking.error) {
      setHistoryData(
        orderTracking.result?.data.results.map((order: any) => ({
          id: order.id,
          tracking_number: order.tracking_number,
          created_at: moment(order.created_at).format("DD.MM.YYYY").toString(),
          full_name: order.user?.full_name,
          status: order.status?.name,
        }))
      );
    }
  }, [
    orderTracking.error,
    orderTracking.loading,
    orderTracking.result?.data.results,
  ]);

  const handleSearch = () => {
    setShowDataGrid(true);
  };

  const [showDataGrid, setShowDataGrid] = useState<boolean>(false);

  return (
    <>
      <div className="w-full flex flex-col justify-start items-center gap-[20px] bg-mobileBackground bg-center bg-cover">
        <div className="w-full flex flex-col justify-center items-center gap-[20px] h-[200px] px-[20px] relative">
          <h1 className="text-[white] text-[30px] font-[400]">
            {t("order_tracking.title")}
          </h1>
        </div>
      </div>

      <div className="bg-white p-[40px] rounded-[10px] shadow-sm w-full">
        <div className="order-tracking flex flex-col items-center justify-center gap-[20px]">
          <TextField
            className="w-full"
            label={t("filter.search")}
            id="search-bar"
            value={filterValue.search}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ fill: "#48A5D1" }} />
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              const searchText = event.target.value;
              setFilterValue({
                ...filterValue,
                search: searchText,
              });
              setShowDataGrid(searchText.trim() === "123456789");
            }}
          />

          <Button
            onClick={handleSearch}
            className="w-full bg-gradient"
            variant="contained"
            color="blue"
          >
            {t("order_tracking.search_button")}
          </Button>
        </div>

        {showDataGrid && (
          <div className="mb-[70px] mt-[40px]">
            {historyData.map((order: any, index: any) => (
              <div
                key={index}
                className="shadow-block p-[20px] bg-white rounded-[10px] flex justify-between mb-[40px]"
              >
                <div className="flex flex-col gap-[5px] text-[14px]">
                  <h4 className="text-[16px] font-bold">
                    {order.tracking_number}
                  </h4>
                  <span>{order.full_name}</span>
                </div>
                <div className="flex flex-col gap-[5px] text-[14px]">
                  <span className="font-semibold">{order.created_at}</span>
                  <span className="text-[#48A5D1]">{order.status}</span>
                </div>
              </div>
            ))}

            <Pagination
              count={
                !orderTracking.loading && !orderTracking.error
                  ? orderTracking.result?.data.total_pages
                  : 1
              }
              page={
                !orderTracking.loading && !orderTracking.error
                  ? orderTracking.result?.data.current_page
                  : 1
              }
              onChange={(event, value: number) => {
                setFilterValue({
                  ...filterValue,
                  page: value,
                });
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default MOrderTracking;
