import React, {useEffect, useState} from 'react';
import {DataGrid, GridColDef, GridFooterContainer} from "@mui/x-data-grid";
import '../../styles/desktopVersion/transactions.css'
import moment from "moment/moment";
import {Pagination} from "@mui/material";
import {useTranslation} from "react-i18next";
import {TransactionsService} from "../../services/TransactionsService";

function Transactions() {
    //translation
    const {t} = useTranslation()
    const [transactionsData, setTransactionsData] = useState<any>([])
    const transactionsColumns: GridColDef[] = [
        {field: 'id', headerName: 'ID', flex: 1, sortable: false, hide: true},
        {field: 'created_at', headerName: `${t('order_table.created_at')}`, sortable: false, flex: 1},
        {field: 'transaction', headerName: `${t('order_table.transaction')}`, sortable: false, flex: 1},
        {
            field: 'sum_of_transaction',
            headerName: `${t('order_table.sum_of_transaction')}`,
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.transaction_type === "replenishment_balance" &&
                            <p style={{color: 'green'}}>+{params.row.sum}</p>}
                        {params.row.transaction_type === "debiting_bonus" &&
                            <p style={{color: 'red'}}>-{params.row.sum}</p>}
                        {params.row.transaction_type === "debiting_balance" &&
                            <p style={{color: 'red'}}>-{params.row.sum}</p>}
                        {params.row.transaction_type === "replenishment_bonus" &&
                            <p style={{color: 'green'}}>+{params.row.sum}</p>}
                    </div>
                )
            }
        },
        {field: 'transaction_type', headerName: 'Тип транзакции', sortable: false, width: 200, hide: true},
    ];
    const [filterValue, setFilterValue] = React.useState<any>({
        page: 1,
    })
    let transactions = TransactionsService.GetTransactions(filterValue)

    useEffect(() => {
        if (!transactions.loading && !transactions.error) {
            setTransactionsData(transactions.result?.data.results.map((order: any) => ({
                id: order.id,
                created_at: (moment(order.created_at)).format('DD.MM.YYYY').toString(),
                transaction: order.type.name,
                sum_of_transaction: order.amount,
                transaction_type: order.type.slug,
            })))
        }
    }, [transactions.loading])
    return (
        <>
            <h1 className='text-[30px] text-black font-[400] mb-[50px]'>{t('transaction.title')}</h1>
            <div className="transactionsPage__mainContainer">
                <div style={{width: '100%', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)'}}>
                    <DataGrid
                        rows={transactionsData}
                        columns={transactionsColumns}
                        keepNonExistentRowsSelected
                        disableColumnFilter
                        disableSelectionOnClick
                        disableColumnMenu
                        autoHeight
                        loading={transactions.loading}
                        localeText={{
                            noRowsLabel: "Нет информации",
                          }}
                        components={{
                            Footer: () => {
                                return (
                                    <GridFooterContainer>
                                        <Pagination
                                            count={!transactions.loading && !transactions.error ? transactions.result?.data.total_pages : 1}
                                            page={!transactions.loading && !transactions.error ? transactions.result?.data.current_page : 1}
                                            onChange={(event, value: number) => {
                                                setFilterValue({
                                                    ...filterValue,
                                                    page: value,
                                                })
                                            }}
                                        />
                                    </GridFooterContainer>
                                );
                            },
                        }}
                    />
                </div>
            </div>
        </>
    );
}

export default Transactions;