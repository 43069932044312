import React, { useEffect, useState } from "react";
import "../../styles/desktopVersion/notification.css";
import { DataGrid, GridFooterContainer, GridRowParams } from "@mui/x-data-grid";
import { Button, IconButton, Pagination, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { NotificationsService } from "../../services/NotificationsService";
import { NotificationsTableI } from "../../models/NotificationsTableI";
import { NotificationsI } from "../../models/NotificationsI";

const tableFilterInitialState = {
  page: 1,
  updateTable: false,
};

function Notification() {
  //translation
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState("notifications");

  // notification detail info
  const [notificationsInfoData, setNotificationsInfoData] = useState<any>({
    id: "",
    title: "",
    message: "",
    date: "",
  });

  // table info
  const [table, setTable] = useState<NotificationsTableI>({
    selectedRows: [],
    filter: tableFilterInitialState,
    rows: [],
    columns: [
      {
        field: "id",
        headerName: "ID",
        width: 100,
        sortable: false,
        hide: true,
      },
      {
        field: "notification",
        headerName: `${t("order_table.notification")}`,
        sortable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <div
              style={{
                color: params.row.status ? "rgba(0, 0, 0, 0.36)" : "black",
              }}
            >
              {params.row.notification.title}
            </div>
          );
        },
      },
      {
        field: "read",
        headerName: "",
        width: 100,
        sortable: false,
        renderCell: (params) => {
          return (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              {params.row.read ? (
                <MarkEmailReadIcon
                  style={{
                    cursor: "pointer",
                    color: "#48A5D1",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <MarkEmailUnreadIcon
                  style={{
                    cursor: "pointer",
                    color: "#48A5D1",
                    marginRight: "10px",
                  }}
                />
              )}
              <IconButton
                color="secondary"
                aria-label="add an alarm"
                onClick={() => {
                  handleNotificationDeleteClick(params.row.id);
                }}
              >
                <DeleteIcon style={{ color: "#B9B9B9" }} />
              </IconButton>
            </div>
          );
        },
      },
    ],
  });

  const notifications = NotificationsService.GetNotifications(table.filter);

  const handleNotificationDeleteClick = (index: string) => {
    NotificationsService.DeleteNotifications(index).then(() => {
      setTable({
        ...table,
        filter: {
          ...table.filter,
          updateTable: !table.filter.updateTable,
        },
      });
    });
  };
  const handleNotificationRowClick = (params: GridRowParams) => {
    setCurrentPage("notificationsView");
    setNotificationsInfoData({
      ...notificationsInfoData,
      id: params.row.id,
      title: params.row.notification.title,
      message: params.row.notification.message,
      date: moment(params.row.notification.created_at)
        .format("DD.MM.YYYY HH:mm")
        .toString(),
    });
    NotificationsService.GetNotificationsInfo(params.row.id).then(() => {
      setTable({
        ...table,
        filter: {
          ...table.filter,
          updateTable: !table.filter.updateTable,
        },
      });
    });
  };
  const handleSelectedNotificationDelete = () => {
    NotificationsService.PostSelectedNotifications(
      table.selectedRows,
      "delete"
    ).then(() => {
      setTable({
        ...table,
        filter: {
          ...table.filter,
          updateTable: !table.filter.updateTable,
        },
      });
    });
  };
  const handleSelectedNotificationSetRead = () => {
    NotificationsService.PostSelectedNotifications(
      table.selectedRows,
      "update"
    ).then(() => {
      setTable({
        ...table,
        filter: {
          ...table.filter,
          updateTable: !table.filter.updateTable,
        },
      });
    });
  };

  useEffect(() => {
    if (!notifications.loading && !notifications.error) {
      const data: NotificationsI[] = [];
      for (let i = 0; i < notifications.result?.data.results.length; i++) {
        let notification = notifications.result?.data.results[i];
        data[i] = {
          id: notification.id,
          notification: notification.notification,
          read: notification.read,
        };
      }
      setTable((prevState) => ({
        ...prevState,
        rows: data,
      }));
    }
  }, [
    notifications.error,
    notifications.loading,
    notifications.result?.data.results,
  ]);

  return (
    <>
      <h1 className="text-[30px] text-black font-[400] mb-[50px]">
        {t("notification.title")}
      </h1>
      <div className="w-full">
        {currentPage === "notifications" && (
          <DataGrid
            rows={table.rows}
            columns={table.columns}
            checkboxSelection
            disableColumnFilter
            disableColumnMenu
            loading={notifications.loading}
            localeText={{
              noRowsLabel: "Нет информации",
            }}
            autoHeight
            keepNonExistentRowsSelected
            disableSelectionOnClick
            onRowClick={(params) => {
              handleNotificationRowClick(params);
            }}
            onSelectionModelChange={(selectionModel) =>
              setTable({
                ...table,
                selectedRows: selectionModel,
              })
            }
            components={{
              Footer: () => (
                <GridFooterContainer>
                  <Pagination
                    count={
                      !notifications.loading && !notifications.error
                        ? notifications.result?.data.total_pages
                        : 1
                    }
                    page={
                      !notifications.loading && !notifications.error
                        ? notifications.result?.data.current_page
                        : 1
                    }
                    onChange={(event, value: number) => {
                      setTable({
                        ...table,
                        filter: {
                          ...table.filter,
                          page: value,
                        },
                      });
                    }}
                  />
                  <Stack
                    direction={"row"}
                    spacing={"20px"}
                    margin={"0 10px 0 0"}
                  >
                    <Button
                      variant="contained"
                      disabled={!(table.selectedRows.length > 0)}
                      onClick={handleSelectedNotificationSetRead}
                    >
                      {t("button.set_read")}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ background: "#FF8080" }}
                      disabled={!(table.selectedRows.length > 0)}
                      onClick={handleSelectedNotificationDelete}
                    >
                      {t("button.delete")}
                    </Button>
                  </Stack>
                </GridFooterContainer>
              ),
            }}
          />
        )}
        {currentPage === "notificationsView" ? (
          notificationsInfoData.loading ? (
            <CircularProgress
              style={{ marginTop: "20px", marginBottom: "20px" }}
            />
          ) : (
            <div className="notificationsView__wrapper">
              <div className="notificationsView__wrapperTitleBar">
                <h1 className="notificationsView__title">
                  {notificationsInfoData.title}
                </h1>
                <span className="notificationsView__date">
                  {notificationsInfoData.date}
                </span>
              </div>
              <div className="notificationsView__wrapperTextBar">
                {parse(notificationsInfoData.message)}
              </div>
              <IconButton
                color="secondary"
                aria-label="add an alarm"
                style={{ position: "absolute", top: "23px", left: "15px" }}
                onClick={() => {
                  setCurrentPage("notifications");
                }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </div>
          )
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default Notification;
