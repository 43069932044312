import React, {FormEvent, useEffect, useState} from 'react'
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import '../../../styles/desktopVersion/orderAdd.css'
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {AddressesService} from "../../../services/AddressesService";
import {StoresService} from "../../../services/StoresService";
import {OrderService} from "../../../services/OrderService";
import {LoadingButton} from "@mui/lab";
import {checkModalResponse} from "../../../helpers/helpers";

const formInitialValues = {
    open: false,
    values: {
        id: "",
        country: '',
        store: '',
        type_of_transport: '',
        tracking_number: '',
        leave_gift_boxes: false,
        leave_shoe_boxes: false,
        products: [
            {
                id: '',
                title: '',
                price: '',
                new: true,
                quantity: '',
                description: '',
            }
        ],
    },
    validation: {
        error: {
            country: false,
            store: false,
            type_of_transport: false,
            tracking_number: false,
            leave_gift_boxes: false,
            leave_shoe_boxes: false,
        },
        message: {
            country: '',
            store: '',
            type_of_transport: '',
            tracking_number: '',
            leave_gift_boxes: '',
            leave_shoe_boxes: '',
        },
    },
    requested: false,
};

export default function OrdersDetails() {
    //translation
    const {t} = useTranslation()

    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const action = params.get("action");
    const order_id = params.get("order_id");

    const [form, setForm] = useState<any>({
        ...formInitialValues,
        action: action,
    });

    const countriesList = AddressesService.GetCountries()
    const stores = StoresService.GetStores()
    const getTransports = AddressesService.GetTransports({country: form.values.country})

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault()

        setForm({
            ...form,
            requested: true,
        })
        switch (action) {
            case 'add':
                OrderService.CreateOrder(form.values).then(() => {
                    navigate('/')
                }).catch((err) => {
                    checkModalResponse(err.response.data, setForm, form);
                })
                break;
            case 'edit':
                OrderService.UpdateOrder(form.values).then(() => {
                    navigate('/')
                }).catch((err) => {
                    checkModalResponse(err.response.data, setForm, form);
                })
                break;
        }
    }

    useEffect(()=>{
        if(action === 'edit' && order_id){
            OrderService.GetOrder(order_id).then((res: any)=>{
                const data = res.data
                setForm({
                    ...form,
                    values:{
                        ...form.values,
                        id: data.id,
                        country: data.country?.id,
                        store: data.store?.id,
                        type_of_transport: data.type_of_transport?.id,
                        tracking_number: data.tracking_number,
                        leave_gift_boxes: data.leave_gift_boxes,
                        leave_shoe_boxes: data.leave_shoe_boxes,
                        products: data.products,
                    }
                })
            })
        }
    },[action, order_id])
    return (
        <>
            <h1 className='text-[30px] text-black font-[400] mb-[50px]'>
                {action === 'add' && t('add_order.title')}
                {action === 'edit' && t('edit_order.title')}
            </h1>
            <form className="orderAdd__mainContainer" onSubmit={handleFormSubmit}>
                <div className="orderAdd-mainContainer__header">
                    <p className='orderAdd-mainContainer__headerText'>{t('add_order.note')}</p>
                </div>
                <div className="orderAdd-mainContainer__body">
                    <div className='orderAdd-mainContainer__bodySelectBox'>
                        <FormControl required fullWidth size="small">
                            <InputLabel>{t('input_labels.country')}</InputLabel>
                            <Select
                                label={t('input_labels.country')}
                                value={form.values.country}
                                error={form.validation.error.country}
                                onChange={(event) => {
                                    setForm({
                                        ...form,
                                        values: {
                                            ...form.values,
                                            country: event.target.value
                                        }
                                    })
                                }}
                            >
                                {countriesList.loading
                                    ? <div>loading</div>
                                    : countriesList.error
                                        ? <div>Error</div>
                                        : countriesList.result?.data.map((item: any) => (
                                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        ))
                                }
                            </Select>
                            <FormHelperText>{form.validation.message.country}</FormHelperText>
                        </FormControl>
                        <FormControl required fullWidth size="small">
                            <InputLabel>{t('input_labels.store')}</InputLabel>
                            <Select
                                label={t('input_labels.store')}
                                error={form.validation.error.store}
                                value={form.values.store}
                                onChange={(event) => {
                                    setForm({
                                        ...form,
                                        values: {
                                            ...form.values,
                                            store: event.target.value
                                        }
                                    })
                                }}
                            >
                                {stores.loading
                                    ? <div>loading</div>
                                    : stores.error
                                        ? <div>Error</div>
                                        : stores.result?.data.map((item: any) => (
                                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        ))
                                }
                            </Select>
                            <FormHelperText>{form.validation.message.store}</FormHelperText>
                        </FormControl>
                        <TextField
                            fullWidth
                            required
                            size="small"
                            label={t('input_labels.tracking_number')}
                            error={form.validation.error.tracking_number}
                            helperText={form.validation.message.tracking_number}
                            value={form.values.tracking_number}
                            onChange={(event) => {
                                setForm({
                                    ...form,
                                    values: {
                                        ...form.values,
                                        tracking_number: event.target.value
                                    }
                                })
                            }}
                        />
                        <FormControl required fullWidth size="small">
                            <InputLabel>{t('input_labels.type_of_transport')}</InputLabel>
                            <Select
                                label={t('input_labels.type_of_transport')}
                                error={form.validation.error.type_of_transport}
                                value={form.values.type_of_transport}
                                onChange={(event) => {
                                    setForm({
                                        ...form,
                                        values: {
                                            ...form.values,
                                            type_of_transport: event.target.value
                                        }
                                    })
                                }}
                            >
                                {getTransports.loading
                                    ? <div>loading</div>
                                    : getTransports.error
                                        ? <div>Error</div>
                                        : getTransports.result?.data.map((item: any) => (
                                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        ))
                                }
                            </Select>
                            <FormHelperText>{form.validation.message.type_of_transport}</FormHelperText>
                        </FormControl>
                    </div>
                    <div className="orderAdd-mainContainer__bodyOrders">
                        {form.values.products.map((product: any, index: number) => (
                            <div className="orderAdd-bodyOrders__orderBox" key={index}>
                                <div className="orderAdd-orderBox__header">
                                    <p className='orderAdd-orderBox__headerText'>
                                        {t('add_order.product')} {index + 1}
                                    </p>
                                    <Button variant='contained'
                                            sx={{background: '#FF8080'}}
                                            disabled={!(form.values.products.length > 1)}
                                            onClick={() => {
                                                const arr = form.values.products
                                                arr.splice(index, 1)
                                                setForm({
                                                    ...form,
                                                    values: {
                                                        ...form.values,
                                                        products: arr
                                                    }
                                                })
                                            }}
                                    >
                                        {t('button.delete')}
                                    </Button>
                                </div>
                                <div className="orderAdd-orderBox__body">
                                    <div className="orderAdd__packageProperties">
                                        <TextField
                                            fullWidth
                                            size="small"
                                            value={product.title}
                                            label={t('input_labels.name')}
                                            required
                                            onChange={(event) => {
                                                const arr = form.values.products
                                                arr[index].title = event.target.value
                                                setForm({
                                                    ...form,
                                                    values: {
                                                        ...form.values,
                                                        products: arr
                                                    }
                                                })
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            size="small"
                                            className='TextField-without-border-radius'
                                            value={product.price}
                                            label={t('input_labels.price')}
                                            type="number"
                                            required
                                            onChange={(event) => {
                                                const arr = form.values.products
                                                arr[index].price = event.target.value
                                                setForm({
                                                    ...form,
                                                    values: {
                                                        ...form.values,
                                                        products: arr
                                                    }
                                                })
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            type='number'
                                            size="small"
                                            label={t('input_labels.quantity')}
                                            value={product.quantity}
                                            required
                                            onChange={(event) => {
                                                const arr = form.values.products
                                                arr[index].quantity = event.target.value
                                                setForm({
                                                    ...form,
                                                    values: {
                                                        ...form.values,
                                                        products: arr
                                                    }
                                                })
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            type='number'
                                            label={t('input_labels.total_price')}
                                            size="small"
                                            value={+product.quantity * +product.price}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {!countriesList.loading && !countriesList.error && countriesList.result?.data.find((country: any) => form.values.country === country.id) && countriesList.result?.data.find((country: any) => form.values.country === country.id).code === 'USA' &&
                        <Stack direction='row' spacing='20px' width='100%' justifyContent='center' marginBottom='20px'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onClick={() => {
                                            setForm({
                                                ...form,
                                                values: {
                                                    ...form.values,
                                                    leave_shoe_boxes: !form.values.leave_shoe_boxes
                                                }
                                            })
                                        }}
                                        checked={form.values.leave_shoe_boxes}
                                    />
                                }
                                label={'Leave shoe boxes'}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onClick={() => {
                                            setForm({
                                                ...form,
                                                values: {
                                                    ...form.values,
                                                    leave_gift_boxes: !form.values.leave_gift_boxes
                                                }
                                            })
                                        }}
                                        checked={form.values.leave_gift_boxes}
                                    />
                                }
                                label={'Leave gift boxes (bulk weight)'}
                            />
                        </Stack>
                    }
                </div>
                <div className="orderAdd-mainContainer__footer">
                    <Button className='orderAdd-mainContainer__footerPackageAddButton' onClick={() => {
                        setForm({
                            ...form,
                            values: {
                                ...form.values,
                                products: [...form.values.products, {
                                    id: '',
                                    title: '',
                                    price: '',
                                    new: true,
                                    quantity: '',
                                    description: '',
                                }]
                            }
                        })
                    }}>
                        <AddCircleIcon style={{marginRight: '5px', color: '#E52EC7'}}/>{t('add_order.add_product')}
                    </Button>
                    <Stack spacing={'20px'} direction={"row"}>
                        <Button variant="outlined"
                                onClick={() => {
                                    navigate('/')
                                }}>
                            {t('button.cancel')}
                        </Button>
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            loading={form.requested}
                            disabled={form.requested}
                        >
                            {t('button.add_order')}
                        </LoadingButton>
                    </Stack>
                </div>
            </form>
        </>
    )
}