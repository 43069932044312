import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

const MiddleWare = ({ children }: any) => {
  const location = useLocation();
  const user = useSelector((state: any) => state.user);
  const isOnLoginPage = location.pathname.includes("login");

  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    if (user.authed || isOnLoginPage) {
      setAuthChecked(true);
    }
  }, [user.authed, isOnLoginPage]);

  //   if (!authChecked) {
  //     return <Navigate to="/login" replace />;
  //   }

  if (user.authed) {
    if (isOnLoginPage) {
      return <Navigate to="/" replace />;
    }
    return children;
  }

  if (!user.authed && !isOnLoginPage) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default MiddleWare;
