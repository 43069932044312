import React, {useEffect, useState} from "react";
import MAuthModal from "./MAuthModal";
import MRegisterModal from "./MRegisterModal";
import MRecoverModal from "./MRecoverModal";
import '../../../styles/mobileVersion/Mauth.css'
import {Alert} from "@mui/material";
import {useTranslation} from "react-i18next";
import Localize from "../../../locales/Localize"

function MAuthPage(){
    //translation
    const {t} = useTranslation()

    const [authSteps, setAuthSteps] = useState('auth');
    const [authAgain, setAuthAgain] = useState(false);
    const [authAgainAfterRecovery, setAuthAgainAfterRecovery] = useState(false);
    useEffect(()=>{
        if(authAgain){
            setTimeout(()=>{
                setAuthAgain(false)
            }, 5000)
        }
    }, [authAgain])
    useEffect(()=>{
        if(authAgainAfterRecovery){
            setTimeout(()=>{
                setAuthAgainAfterRecovery(false)
            }, 5000)
        }
    }, [authAgainAfterRecovery])
    return (
        <div className='authPage MAuthPage' id='authPage'>
            <Localize style={{position:'absolute', top: '20px', right: '20px', zIndex:'999'}}/>
            {authAgain &&
                <div style={{
                    position: 'absolute',
                    top: '100px',
                    zIndex: '9999'
                }}>
                    <Alert severity="success">{t('auth.registration_successfully')}</Alert>
                </div>
            }
            {authAgainAfterRecovery &&
                <div style={{
                    position: 'absolute',
                    top: '100px',
                    zIndex: '9999',
                }}>
                    <Alert severity="success">{t('auth.password_sent_to_email')}</Alert>
                </div>
            }
            {authSteps === 'auth' && <MAuthModal setAuthSteps={setAuthSteps}/>}
            {authSteps === 'register' && <MRegisterModal setAuthAgain={setAuthAgain} setAuthSteps={setAuthSteps}/>}
            {authSteps === 'recover' && <MRecoverModal setAuthAgainAfterRecovery={setAuthAgainAfterRecovery} setAuthSteps={setAuthSteps}/>}
        </div>
    );
}

export default MAuthPage;