import React from "react";
import "../../styles/mobileVersion/Mcontacts.css";
import { useTranslation } from "react-i18next";

import facebookIcon from "../../assets/images/Group 1230.png";
import youtubeIcon from "../../assets/images/Group 1232.png";
import twitterIcon from "../../assets/images/Group 1231.png";

import instagram from "../../assets/images/socials-instagram.png";
import telegram from "../../assets/images/socials-telegram.png";
import tiktok from "../../assets/images/socials-tiktok.png";

function MContacts() {
  //translation
  const { t } = useTranslation();

  return (
    <>
      <div className="w-full flex flex-col justify-start items-center gap-[20px] bg-mobileBackground bg-center bg-cover">
        <div className="w-full flex flex-col justify-center items-center gap-[20px] h-[200px] px-[20px] relative">
          <h1 className="text-[white] text-[30px] font-[400]">
            {t("footer.contacts")}
          </h1>
        </div>
      </div>
      <div className="w-full flex flex-col justify-start items-center px-[20px] pt-[20px] pb-[100px] gap-[10px]">
        <div className="w-full flex flex-col justify-start items-start gap-[10px] mb-[20px]">
          <p className="text-[#48A5D1] text-[16px] font-[600]">
            {t("footer.contacts")}
          </p>

          <div className="w-full flex flex-col justify-start items-start gap-[5px]">
            <p className="text-[12px] font-[600]">
              г. Бишкек, улица Байтик Баатыра 29
            </p>
            <a className="text-[12px] font-[600]" href="tel:+996500004468">
              +996 500 004 468
            </a>
            <a className="text-[12px] font-[600]" href="tel:+996995004468">
              +996 995 004 468
            </a>
            <a className="text-[12px] font-[600]" href="tel:+996997000123">
              +996 997 000 123
            </a>
          </div>
        </div>
        <div className="w-full flex flex-col justify-start items-start gap-[10px]">
          <p className="text-[#48A5D1] text-[16px] font-[600]">
            {t("footer.work_schedule")}
          </p>
          <div className="w-full flex flex-col justify-start items-start gap-[5px]">
            <p className="text-[12px] font-[600]">
              {t("footer.without_weekend")}
            </p>
            <p className="text-[12px] font-[600]">
              {t("footer.from")} 10:00 {t("footer.to")} 20:00
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default MContacts;
