import React, {useEffect, useState} from "react";
import AuthModal from "./AuthModal";
import RegisterModal from "./RegisterModal";
import RecoverModal from "./RecoverModal";
import '../../../styles/desktopVersion/auth.css'
import {Alert} from "@mui/material";
import Localize from "../../../locales/Localize";
import {useTranslation} from "react-i18next";

export default function Auth(){
    //translation
    const {t} = useTranslation()

    const [authSteps, setAuthSteps] = useState('auth');
    const [authAgain, setAuthAgain] = useState(false);
    const [authAgainAfterRecovery, setAuthAgainAfterRecovery] = useState(false);
    useEffect(()=>{
        if(authAgain){
            setTimeout(()=>{
                setAuthAgain(false)
            }, 5000)
        }
    }, [authAgain])
    useEffect(()=>{
        if(authAgainAfterRecovery){
            setTimeout(()=>{
                setAuthAgainAfterRecovery(false)
            }, 5000)
        }
    }, [authAgainAfterRecovery])
    return (
        <div className='authPage' id='authPage'>
            <Localize style={{position:'absolute', top: '20px', left: '20px'}}/>
            {authAgain &&
                <div style={{
                    position: 'absolute',
                    top: '100px',
                    zIndex: '9999',
                }}>
                    <Alert severity="success">{t('auth.registration_successfully')}</Alert>
                </div>
            }
            {authAgainAfterRecovery &&
                <div style={{
                    position: 'absolute',
                    top: '100px',
                    zIndex: '9999',
                }}>
                    <Alert severity="success">{t('auth.password_sent_to_email')}</Alert>
                </div>
            }
            {authSteps === 'auth' && <AuthModal setAuthSteps={setAuthSteps}/>}
            {authSteps === 'register' && <RegisterModal setAuthAgain={setAuthAgain} setAuthSteps={setAuthSteps}/>}
            {authSteps === 'recover' && <RecoverModal setAuthAgainAfterRecovery={setAuthAgainAfterRecovery} setAuthSteps={setAuthSteps}/>}
        </div>
    );
}

