import React, {useState} from "react";
import {
    Button,
    DialogTitle,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    Menu,
    MenuItem,
    Modal,
    Pagination,
    Select,
    TextField
} from "@mui/material";
import {createSearchParams, Link, useNavigate} from "react-router-dom";
import moment from "moment/moment";
import SearchIcon from "@mui/icons-material/Search";
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import {DatePicker} from "@mui/x-date-pickers";
import {MomentInput} from "moment";
import {EmptyData} from "../../../helpers/helpers";
import CircularProgress from "@mui/material/CircularProgress";
import {useTranslation} from "react-i18next";
import {OrderService} from "../../../services/OrderService";
import {AddressesService} from "../../../services/AddressesService";
import {StoresService} from "../../../services/StoresService";
import {OrdersEmptyIcon} from "../../../common/common";
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";


export default function MOrders() {
    //translation
    const {t} = useTranslation()

    let navigate = useNavigate();
    const [showFilter, setShowFilter] = useState(false)
    const [filterValue, setFilterValue] = React.useState<any>({
        date_from: null,
        date_to: null,
        country: '',
        store: '',
        page: 1,
        search: '',
        status__status: 'showAll',
    })
    const getOrders = OrderService.GetOrders({
        ...filterValue,
        ...filterValue.date_from !== null ? {date_from: moment(filterValue.date_from?.$d).format('YYYY-MM-DD')} : {},
        ...filterValue.date_to !== null ? {date_to: moment(filterValue.date_to?.$d).format('YYYY-MM-DD')} : {},
    })
    const getCountries = AddressesService.GetCountries()
    const orderStatuses = OrderService.GetOrderStatusButtons()
    const getStores = StoresService.GetStores()
    const handleFilterDateChange = (props: string) => (value: MomentInput) => {
        const dateValue = moment(value).format('YYYY-MM-DD').toString()
        setFilterValue({
            ...filterValue,
            page: 1,
            [props]: dateValue,
        })
    }
    return (
        <>
            <div
                className="w-full flex flex-col justify-start items-center gap-[20px] bg-mobileBackground bg-center bg-cover">
                <div
                    className="w-full flex flex-col justify-center items-center gap-[20px] h-[200px] px-[20px] relative">
                    <h1 className="text-[white] text-[30px] font-[400]">
                        {t('header.orders')}
                    </h1>
                    <TextField
                        fullWidth
                        placeholder={`${t('filter.search')}`}
                        value={filterValue.search}
                        sx={{width: '100%', background: 'white', borderRadius: '5px'}}
                        type='number'
                        size='small'
                        InputProps={{
                            startAdornment:
                                <InputAdornment position="start">
                                    <SearchIcon style={{fill: "#48A5D1"}}/>
                                </InputAdornment>,
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton onClick={() => {
                                        setShowFilter(true)
                                    }}>
                                        <SettingsInputComponentIcon/>
                                    </IconButton>
                                </InputAdornment>,
                        }}
                        onChange={(event) => {
                            setFilterValue({
                                ...filterValue,
                                search: event.target.value,
                            })
                        }}
                    />
                </div>
            </div>
            <div className="w-full flex flex-col justify-start items-center px-[20px] pt-[20px] pb-[100px] gap-[10px]">
                {getOrders.loading
                    ? <CircularProgress style={{marginTop: '20px', marginBottom: '20px'}}/>
                    : getOrders.error
                        ? <div>Error</div>
                        :
                        getOrders.result?.data.results.length === 0
                            ? <EmptyData text={t('mobile.no_data')} icon={<OrdersEmptyIcon/>}/>
                            :
                            getOrders.result?.data.results.map((order: any, index: number) => (
                                <Link
                                    key={index}
                                    to={`/orders/${order.id}`}
                                    className="w-full flex flex-col rounded-[4px] shadow-md p-[10px]"
                                >
                                    <div className="w-full flex justify-between items-start gap-[20px] pb-[10px] mb-[10px]" style={{borderBottom: '1px solid gray'}}>
                                        <div className='flex justify-start items-start gap-[10px]'>
                                            <img src={order.country.icon} alt="icon" className='h-[30px]'/>
                                            <div className="flex flex-col justify-start items-start gap-[7px]">
                                                <p className='text-[] text-[12px] leading-3'>{t('mobile.order')} № <span
                                                    className='text-[#48A5D1]'>{order.tracking_number}</span></p>
                                                <p className='text-[#48A5D1] text-[12px] leading-3'>{order.status?.name}...</p>
                                            </div>
                                        </div>

                                        {order.status.status === 'inProcess' &&
                                            <div onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault()
                                            }}>
                                                <PopupState variant="popover" popupId="demo-popup-menu">
                                                    {(popupState) => (
                                                        <React.Fragment>
                                                            <IconButton size='small' {...bindTrigger(popupState)}
                                                                        sx={{padding: 0}}>
                                                                <MoreVertIcon/>
                                                            </IconButton>
                                                            <Menu {...bindMenu(popupState)}>
                                                                <MenuItem
                                                                    onClick={() => {
                                                                        navigate({
                                                                            pathname: "/orders/details",
                                                                            search: createSearchParams({
                                                                                action: "edit",
                                                                                order_id: order.id,
                                                                            }).toString(),
                                                                        });
                                                                    }}
                                                                    disableRipple
                                                                >
                                                                    <Button size='small' startIcon={<EditIcon
                                                                        fontSize='small'/>}>
                                                                        <p className='text-[12px]'>{t('button.edit')}</p>
                                                                    </Button>
                                                                </MenuItem>
                                                                <MenuItem
                                                                    onClick={() => {
                                                                        OrderService.DeleteOrder(order.id).then(() => {
                                                                            getOrders.execute()
                                                                        })
                                                                    }}
                                                                    disableRipple
                                                                >
                                                                    <Button size='small'
                                                                            startIcon={<DeleteIcon
                                                                                fontSize='small'/>}>
                                                                        <p className='text-[12px]'>{t('button.delete')}</p>
                                                                    </Button>
                                                                </MenuItem>
                                                            </Menu>
                                                        </React.Fragment>
                                                    )}
                                                </PopupState>
                                            </div>
                                        }

                                    </div>

                                    <div className="w-full flex justify-between items-start gap-[10px]">
                                        <p className='text-[12px] text-[#48A5D1] font-[400]'>{t('order_table.created_at')}: {(moment(order.created_at)).format('DD.MM.YYYY').toString()}</p>
                                        <p className='text-[12px] text-[#48A5D1] font-[400]'>
                                            {order.weight
                                                ? order.total_price
                                                    ? `${order.weight} ${t('mobile.kilogram')}/${order.total_price}$`
                                                    : `${order.weight} ${t('mobile.kilogram')}`
                                                : order.total_price
                                                    ? `${order.total_price}$`
                                                    : ``
                                            }
                                        </p>
                                    </div>
                                </Link>
                            ))
                }
                <Pagination
                    style={{marginTop: '20px'}}
                    count={!getOrders.loading && !getOrders.error ? getOrders.result?.data.total_pages : 1}
                    page={!getOrders.loading && !getOrders.error ? getOrders.result?.data.current_page : 1}
                    onChange={(event, value: number) => {
                        setFilterValue({
                            ...filterValue,
                            page: value,
                        })
                    }}
                />
            </div>


            <Modal onClose={() => setShowFilter(false)} open={showFilter}
                   sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingX: '20px'}}>
                <div className='w-full flex flex-col justify-start items-center gap-[10px] bg-white p-[20px]'>
                    <DialogTitle>{t('filter.title')}</DialogTitle>
                    <div className="w-full grid grid-cols-2 gap-[20px] mb-[30px]">
                        <div className="w-full col-start-1 col-end-3">
                            <FormControl fullWidth size='small'>
                                <InputLabel>{t('order_table.status')}</InputLabel>
                                <Select
                                    value={filterValue.status__status}
                                    label={t('order_table.status')}
                                    onChange={(event) => {
                                        setFilterValue({...filterValue, page: 1, status__status: event.target.value})
                                    }}
                                >
                                    <MenuItem value={'showAll'}>{t('mobile.show_all')}</MenuItem>
                                    {!orderStatuses.loading && !orderStatuses.error && orderStatuses.result?.data.map((item: any, index: number) => (
                                        <MenuItem key={index} value={item.status}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <DatePicker
                            label={t('filter.date_from')}
                            value={filterValue.date_from}
                            onChange={handleFilterDateChange('date_from')}
                            renderInput={(params) => <TextField fullWidth size='small' {...params}/>}
                        />
                        <DatePicker
                            label={t('filter.date_to')}
                            value={filterValue.date_to}
                            onChange={handleFilterDateChange('date_to')}
                            renderInput={(params) => <TextField fullWidth size='small' {...params}/>}
                        />
                        <FormControl fullWidth size='small'>
                            <InputLabel>{t('input_labels.country')}</InputLabel>
                            <Select
                                value={filterValue.country}
                                label={t('input_labels.country')}
                                onChange={(event) => {
                                    setFilterValue({...filterValue, page: 1, country: event.target.value,})
                                }}
                            >
                                {!getCountries.loading && !getCountries.error && getCountries.result?.data.map((item: any) => (
                                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth size='small'>
                            <InputLabel>{t('input_labels.store')}</InputLabel>
                            <Select
                                value={filterValue.store}
                                label={t('input_labels.store')}
                                onChange={(event) => {
                                    setFilterValue({...filterValue, page: 1, store: event.target.value,})
                                }}
                            >
                                {!getStores.loading && !getStores.error && getStores.result?.data.map((item: any) => (
                                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                    <Button
                        onClick={() => setShowFilter(false)}
                        variant='contained'
                        fullWidth
                    >
                        {t('button.submit')}
                    </Button>
                </div>
            </Modal>
        </>
    );
}