import React from 'react';
import '../../styles/mobileVersion/Mheader.css'
import {Link, useNavigate} from 'react-router-dom'
import NotificationsIcon from '@mui/icons-material/Notifications';
import {Avatar, IconButton} from '@mui/material';
import {StringAvatar} from "../../helpers/helpers";
import Localize from "../../locales/Localize";
import {useSelector} from "react-redux";

function MHeader() {
    const user = useSelector((state: any) => state.user)
    const navigate = useNavigate()
    return (
        <div className='w-full px-[20px] py-[15px] flex justify-between items-center shrink-0'>
            <div className='flex items-center gap-[10px]'>
                <Link to='personal'>
                    <Avatar {...StringAvatar(`${user.user.user.first_name} ${user.user.user.last_name}`)}></Avatar>
                </Link>
                <p className='text-[white] text-[14px] font-[500]'> {`${user.user.user.first_name} ${user.user.user.last_name}`}</p>
            </div>
            <div className="flex items-center gap-[10px]">
                <Localize style={{}}/>
                <IconButton
                    onClick={()=>navigate('/notification')}
                >
                    <NotificationsIcon color='primary'/>
                </IconButton>
            </div>
        </div>
    );
}

export default MHeader;

