import { useAsync } from "react-async-hook";
import { $axios } from "../http";
import { CreateSearchParams } from "../helpers/helpers";

export const AddressesService = {
  GetCountries() {
    return useAsync(async () => {
      return await $axios.get("/countries/");
    }, []);
  },
  GetAddresses() {
    return useAsync(async () => {
      return await $axios.get("/addresses/country-additional-info/");
    }, []);
  },
  GetAddressInfo(address_id: string | undefined) {
    return useAsync(async () => {
      return await $axios.get(
        `/addresses/country-additional-info/${address_id}`
      );
    }, []);
  },
  GetCountriesInfo(id: any) {
    return useAsync(async () => {
      return await $axios.get(`/countries/${id}`);
    }, [`${id}`]);
  },

  GetTransports(searchParamsObj: any) {
    return useAsync(async () => {
      return await $axios.get(
        "/type-of-transports/" + CreateSearchParams(searchParamsObj)
      );
    }, [CreateSearchParams(searchParamsObj)]);
  },

  async CreatePointsList(points: object) {
    return await $axios.post("/customer/pick-up-point/change/", points);
  },

  GetPointsList() {
    return useAsync(async () => {
      return await $axios.get("/pick-up-points/");
    }, []);
  },
};
