import React, {useState} from 'react';
import '../../styles/desktopVersion/address.css'
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {AddressesService} from "../../services/AddressesService";
import {Skeleton} from "@mui/lab";
import {Button, IconButton} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Calculator from "../../components/Calculator";

export function Address() {
    //translation
    const {t} = useTranslation()
    const {id} = useParams()
    const user = useSelector((state: any) => state.user)
    const addressInfo = AddressesService.GetAddressInfo(id)
    const copy = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const targetToCopy: any = event.currentTarget.previousElementSibling
        targetToCopy.select()
        document.execCommand("copy");
    }
    const copyAll = () => {
        const targetsToCopy: any = document.getElementsByClassName('receiverBox-infoBox__textField')
        const allInputs: any = document.getElementById('SelectedInputs')
        const textToCopy = []
        for (let i = 0; i < targetsToCopy.length; i++) {
            textToCopy.push(targetsToCopy[i].children[1].value)
        }
        allInputs.value = textToCopy.toString()
        allInputs.select()
        document.execCommand("copy");
    }
    const replaceKeysToData = (text: string) => {
        return text
            .replace('{client_code}', user.user.id)
            .replace('{client_phone}', user.user.phone)
            .replace('{client_full_name}', user.user.full_name)
            .replace('{client_first_name}', user.user.user.first_name)
            .replace('{client_last_name}', user.user.user.last_name)
    }

    const [showText, setShowText] = useState({
        important_note: true,
        prohibited_items_note: false,
        filling_in: false,
    })
    return (
        <>
            <div
                className='py-[20px] px-[20px] rounded-[5px] text-[20px] bg-[#48A5D1] text-white flex items-center gap-[10px] mb-[30px]'>
                {t('header.code')}:
                <p className={'font-bold'}>{user.user.id}</p>
            </div>
            <h1 className='text-[30px] text-black font-[400] mb-[50px]'>{t('addresses.title')}</h1>

            {addressInfo.loading
                ? <Skeleton variant="rectangular" width={'100%'} height={500}/>
                : addressInfo.error
                    ? 'error'
                    :
                    <>
                        <div className="w-full py-[30px] px-[24px] bg-white rounded-[4px] shadow-md mb-[40px] flex flex-col items-start justify-start gap-[30px]">
                            <div className='w-full flex justify-start gap-[30px] items-center'>
                                <h4 className='text-[20px] text-[#141414] font-[500]'>Важно знать!</h4>

                                <IconButton
                                    onClick={()=>{
                                        setShowText({
                                            ...showText,
                                            important_note: !showText.important_note
                                        })
                                    }}
                                >
                                    <KeyboardArrowDownIcon/>
                                </IconButton>
                            </div>
                            {showText.important_note &&
                                <div dangerouslySetInnerHTML={{__html: addressInfo.result?.data.important_note}}></div>
                            }
                        </div>
                        <div className="w-full py-[30px] px-[24px] bg-white rounded-[4px] shadow-md mb-[40px] flex flex-col items-start justify-start gap-[30px]">
                            <div className='w-full flex justify-start gap-[30px] items-center'>
                                <h4 className='text-[20px] text-[#141414] font-[500]'>Запрещенные товары</h4>

                                <IconButton
                                    onClick={()=>{
                                        setShowText({
                                            ...showText,
                                            prohibited_items_note: !showText.prohibited_items_note
                                        })
                                    }}
                                >
                                    <KeyboardArrowDownIcon/>
                                </IconButton>
                            </div>

                            {showText.prohibited_items_note &&
                                <div dangerouslySetInnerHTML={{__html: addressInfo.result?.data.prohibited_items_note}}></div>
                            }
                        </div>
                        <div className="w-full py-[30px] px-[24px] bg-white rounded-[4px] shadow-md mb-[40px]">
                            <div className="w-full flex justify-start items-end gap-[20px] pb-[30px]" style={{borderBottom: '1px solid #D9D9D9'}}>
                                <div className='p-[12px] bg-[#48A5D1] flex gap-[10px] items-center rounded-[8px] shrink-0'>
                                    <img className='w-[70px] h-[40px] rounded-[8px]' src={addressInfo.result?.data.country.icon} alt="img"/>
                                    <div className="flex flex-col justify-center items-start">
                                        <p className='text-[18px] text-[#FFFFFF] font-[500]'>{t('addresses.client_code')}</p>
                                        <p className='text-[24px] text-[#FFFFFF] font-[700]'>{addressInfo.result?.data.prefix} {user.user.id}</p>
                                    </div>
                                </div>
                                <div className="text-[16px] text-[#E52EC7] font-[500] underline" dangerouslySetInnerHTML={{__html: addressInfo.result?.data.note}}></div>
                            </div>

                            <div className="w-full flex items-start justify-between p-[30px] gap-[30px]">
                                <div className="w-full flex flex-col justify-start items-start gap-[40px]">
                                    <div className="w-full flex flex-col justify-start items-start gap-[5px]">
                                        {addressInfo.result?.data.additional_fields.map((item: any, index: number) => (
                                            <div key={index} className='w-full flex justify-start items-center gap-[10px]'>
                                                <p className='w-[100px]'>{item.name}:</p>
                                                <input className='flex-1' type="text" readOnly={true}
                                                       value={replaceKeysToData(item.value)}
                                                />
                                                <Button onClick={copy}>{t('addresses.copy')}</Button>
                                            </div>
                                        ))}
                                    </div>

                                    <Button variant='contained' onClick={copyAll}>{t('addresses.copy_all')}</Button>
                                    <input type="text" readOnly={true} value='' id='SelectedInputs'
                                           style={{
                                               width: '1px',
                                               height: '1px',
                                               overflow: 'hidden'
                                           }}/>
                                </div>
                                <div className="w-full flex flex-col justify-start items-start gap-[20px]">
                                    <div className='p-[12px] bg-[#48A5D11A] flex gap-[10px] items-center rounded-[8px]'>
                                        <p className='text-[16px] text-[#48A5D1] font-[500]'>{t('addresses.delivery')}:</p>
                                        <p className='text-[] text-[#141414] font-[400]'>{addressInfo.result?.data.delivery_period}</p>
                                    </div>
                                    <div className='p-[12px] bg-[#48A5D11A] flex gap-[10px] items-center rounded-[8px]'>
                                        <p className='text-[16px] text-[#48A5D1] font-[500]'>{t('addresses.tariff')}:</p>
                                        <p className='text-[] text-[#141414] font-[400]'>{addressInfo.result?.data.tariff} USD</p>
                                    </div>
                                    <Calculator address={addressInfo.result?.data} variant='contained'/>
                                </div>
                            </div>
                        </div>
                        <div className='w-full py-[30px] px-[24px] bg-white rounded-[4px] shadow-md mb-[40px]'>
                            <div className='w-full flex justify-start gap-[30px] items-center'>
                                <h4 className='text-[20px] text-[#141414] font-[500]'>Примеры заполнения адресов</h4>

                                <IconButton
                                    onClick={()=>{
                                        setShowText({
                                            ...showText,
                                            filling_in: !showText.filling_in
                                        })
                                    }}
                                >
                                    <KeyboardArrowDownIcon/>
                                </IconButton>
                            </div>

                            {showText.filling_in &&
                                addressInfo.result?.data.filling_in.map((item: any, index: number) => (
                                    <div key={index} className='w-full pb-[20px] mb-[50px]' style={index + 1 !== addressInfo.result?.data.filling_in.length ? {borderBottom: '3px solid #D9D9D9'} : {}}>
                                        <h5 className='text-[20px] text-[#141414] font-[500] mb-[50px]'>{item.name}</h5>
                                        <div className="w-full flex justify-start items-start gap-[30px]">
                                            <p className='w-full text-[14px] text-[#141414] font-[400] mb-[50px] whitespace-pre-wrap'>{item.description}</p>
                                            {item.files.map((file: any, indexJ: number) => (
                                                <img width='500px' height='auto' key={indexJ} src={file.file} alt="img" className='mb-[10px]'/>
                                            ))}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </>
            }

        </>
    );
}

