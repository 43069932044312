import React, {useState} from "react";
import '../../../styles/mobileVersion/MaddressesView.css'
import {Button, IconButton, Skeleton,} from "@mui/material";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {AddressesService} from "../../../services/AddressesService";
import Calculator from "../../../components/Calculator";
import {useSelector} from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';


function MAddressesView(){
    //translation
    const {t} = useTranslation()
    const {id} = useParams()
    const user = useSelector((state: any) => state.user)
    const addressInfo = AddressesService.GetAddressInfo(id)
    const copy = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const targetToCopy: any = event.currentTarget.previousElementSibling
        targetToCopy.select()
        document.execCommand("copy");
    }
    const copyAll = () => {
        const targetsToCopy: any = document.getElementsByClassName('receiverBox-infoBox__textField')
        const allInputs: any = document.getElementById('SelectedInputs')
        const textToCopy = []
        for (let i = 0; i < targetsToCopy.length; i++) {
            textToCopy.push(targetsToCopy[i].children[1].value)
        }
        allInputs.value = textToCopy.toString()
        allInputs.select()
        document.execCommand("copy");
    }
    const replaceKeysToData = (text: string) => {
        return text
            .replace('{client_code}', user.user.id)
            .replace('{client_phone}', user.user.phone)
            .replace('{client_full_name}', user.user.full_name)
            .replace('{client_first_name}', user.user.user.first_name)
            .replace('{client_last_name}', user.user.user.last_name)
    }

    const [showText, setShowText] = useState({
        important_note: true,
        prohibited_items_note: false,
        filling_in: false,
    })
    return (
        <>
            <div className="w-full flex flex-col justify-start items-center gap-[20px] bg-mobileBackground bg-center bg-cover">
                <div className="w-full flex flex-col justify-center items-center gap-[20px] h-[200px] px-[20px] relative">
                    <h1 className="text-[white] text-[30px] font-[400]">
                        {t('addresses.title')}
                    </h1>
                </div>
            </div>
            <div className="w-full flex flex-col justify-start items-center px-[20px] pt-[20px] pb-[100px] gap-[10px]">
                {addressInfo.loading
                    ? <Skeleton variant="rectangular" width={'100%'} height={500}/>
                    : addressInfo.error
                        ? 'error'
                        :
                        <>

                            <div
                                className="w-full flex flex-col justify-start items-start gap-[20px] pb-[10px] mb-[20px]"
                                style={{borderBottom: '1px solid #D9D9D9'}}>
                                <div
                                    className='w-full p-[12px] bg-[#48A5D1] flex gap-[10px] items-center rounded-[4px] shrink-0'>
                                    <img className='w-[70px] h-[40px] rounded-[8px]'
                                         src={addressInfo.result?.data.country.icon} alt="img"/>
                                    <div className="flex justify-start items-center gap-[10px]">
                                        <p className='text-[14px] text-[#FFFFFF] font-[500]'>{t('addresses.client_code')}:</p>
                                        <p className='text-[18px] text-[#FFFFFF] font-[700]'>{addressInfo.result?.data.prefix} {user.user.id}</p>
                                    </div>
                                </div>

                                <div className="w-full flex justify-start items-start gap-[10px]">
                                    <div
                                        className='p-[12px] bg-[#48A5D11A] flex flex-col justify-start items-start rounded-[4px]'>
                                        <p className='text-[12px] text-[#48A5D1] font-[500]'>{t('addresses.delivery')}:</p>
                                        <p className='text-[12px] text-[#141414] font-[400]'>{addressInfo.result?.data.delivery_period}</p>
                                    </div>
                                    <div
                                        className='p-[12px] bg-[#48A5D11A] flex flex-col justify-start items-start rounded-[4px]'>
                                        <p className='text-[12px] text-[#48A5D1] font-[500]'>{t('addresses.tariff')}:</p>
                                        <p className='text-[12px] text-[#141414] font-[400]'>{addressInfo.result?.data.tariff} USD</p>
                                    </div>
                                </div>
                                <Calculator mobile={true} address={addressInfo.result?.data} variant='contained'/>

                                <div className="text-[16px] text-[#E52EC7] font-[500] underline"
                                     dangerouslySetInnerHTML={{__html: addressInfo.result?.data.note}}></div>
                            </div>

                            <div className="w-full flex flex-col justify-start items-start gap-[20px] pb-[10px] mb-[20px]" style={{borderBottom: '1px solid #D9D9D9'}}>
                                <div className="w-full flex flex-col justify-start items-start">
                                    {addressInfo.result?.data.additional_fields.map((item: any, index: number) => (
                                        <div key={index} className='w-full flex justify-start items-center gap-[10px]'>
                                            <p className='w-[80px] text-[12px]'>{item.name}:</p>
                                            <input className='flex-1 text-[12px] font-[600]' type="text" readOnly={true}
                                                   value={replaceKeysToData(item.value)}
                                            />
                                            <IconButton onClick={copy}><ContentPasteIcon color='primary'/></IconButton>
                                        </div>
                                    ))}
                                </div>

                                <Button variant='contained' size='small'
                                        onClick={copyAll}>{t('addresses.copy_all')}</Button>
                                <input type="text" readOnly={true} value='' id='SelectedInputs'
                                       style={{
                                           width: '1px',
                                           height: '1px',
                                           overflow: 'hidden'
                                       }}/>
                            </div>

                            <div
                                className="w-full p-[10px] rounded-[4px] shadow-md flex flex-col items-start justify-start gap-[20px]">
                                <div className='w-full flex justify-start gap-[30px] items-center'>
                                    <h4 className='text-[16px] text-[#141414] font-[700]'>Важно знать!</h4>

                                    <IconButton
                                        onClick={() => {
                                            setShowText({
                                                ...showText,
                                                important_note: !showText.important_note
                                            })
                                        }}
                                    >
                                        <KeyboardArrowDownIcon/>
                                    </IconButton>
                                </div>
                                {showText.important_note &&
                                    <div className='text-[12px]'
                                         dangerouslySetInnerHTML={{__html: addressInfo.result?.data.important_note}}></div>
                                }
                            </div>
                            <div
                                className="w-full p-[10px] rounded-[4px] shadow-md flex flex-col items-start justify-start gap-[20px]">
                                <div className='w-full flex justify-start gap-[30px] items-center'>
                                    <h4 className='text-[16px] text-[#141414] font-[700]'>Запрещенные товары</h4>

                                    <IconButton
                                        onClick={() => {
                                            setShowText({
                                                ...showText,
                                                prohibited_items_note: !showText.prohibited_items_note
                                            })
                                        }}
                                    >
                                        <KeyboardArrowDownIcon/>
                                    </IconButton>
                                </div>
                                {showText.prohibited_items_note &&
                                    <div className='text-[12px]'
                                         dangerouslySetInnerHTML={{__html: addressInfo.result?.data.prohibited_items_note}}></div>
                                }
                            </div>
                            <div className="w-full p-[10px] rounded-[4px] shadow-md flex flex-col items-start justify-start gap-[20px]">
                                <div className='w-full flex justify-start gap-[30px] items-center'>
                                    <h4 className='text-[16px] text-[#141414] font-[700]'>Примеры заполнения
                                        адресов</h4>

                                    <IconButton
                                        onClick={() => {
                                            setShowText({
                                                ...showText,
                                                filling_in: !showText.filling_in
                                            })
                                        }}
                                    >
                                        <KeyboardArrowDownIcon/>
                                    </IconButton>
                                </div>
                                {showText.filling_in &&
                                    addressInfo.result?.data.filling_in.map((item: any, index: number) => (
                                        <div key={index} className='w-full pb-[20px] mb-[30px]'
                                             style={index + 1 !== addressInfo.result?.data.filling_in.length ? {borderBottom: '3px solid #D9D9D9'} : {}}>
                                            <h5 className='text-[14px] text-[#141414] font-[500] mb-[30px]'>{item.name}</h5>
                                            <div className="w-full flex flex-col justify-start items-start gap-[20px]">
                                                <p className='w-full text-[14px] text-[#141414] font-[400] mb-[50px] whitespace-pre-wrap'>{item.description}</p>
                                                {item.files.map((file: any, indexJ: number) => (
                                                    <img width='100%' height='auto' key={indexJ} src={file.file}
                                                         alt="img"/>
                                                ))}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                }
            </div>
        </>
    );
}

export default MAddressesView;