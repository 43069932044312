import React from 'react';
import {Outlet} from "react-router-dom";
import '../../styles/mobileVersion/Mlayout.css'
import {motion as m} from "framer-motion";
import MFooter from "./MFooter";

function MLayout() {
    return (
        <m.section
            className={`w-full min-h-screen text-[#282828] relative flex flex-col items-center justify-start bg-white bg-center bg-cover`}
            initial={{scale: 0.9}}
            animate={{scale: 1}}
            transition={{duration: 0.3, ease: "easeOut"}}>
            <Outlet/>
            <MFooter/>
        </m.section>
    );
}

export default MLayout;