import React, {FormEvent, useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText, IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {useTranslation} from "react-i18next";
import {AddressesService} from "../../../services/AddressesService";
import {StoresService} from "../../../services/StoresService";
import {OrderService} from "../../../services/OrderService";
import {checkModalResponse} from "../../../helpers/helpers";
import {LoadingButton} from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";


const formInitialValues = {
    open: false,
    values: {
        id: "",
        country: '',
        store: '',
        type_of_transport: '',
        tracking_number: '',
        leave_gift_boxes: false,
        leave_shoe_boxes: false,
        products: [
            {
                id: '',
                title: '',
                price: '',
                new: true,
                quantity: '',
                description: '',
            }
        ],
    },
    validation: {
        error: {
            country: false,
            store: false,
            type_of_transport: false,
            tracking_number: false,
            leave_gift_boxes: false,
            leave_shoe_boxes: false,
        },
        message: {
            country: '',
            store: '',
            type_of_transport: '',
            tracking_number: '',
            leave_gift_boxes: '',
            leave_shoe_boxes: '',
        },
    },
    requested: false,
};

function MOrdersDetails() {
    //translation
    const {t} = useTranslation()

    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const action = params.get("action");
    const order_id = params.get("order_id");

    const [form, setForm] = useState<any>({
        ...formInitialValues,
        action: action,
    });

    const countriesList = AddressesService.GetCountries()
    const stores = StoresService.GetStores()
    const getTransports = AddressesService.GetTransports({country: form.values.country})

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault()

        setForm({
            ...form,
            requested: true,
        })
        switch (action) {
            case 'add':
                OrderService.CreateOrder(form.values).then(() => {
                    navigate('/')
                }).catch((err) => {
                    checkModalResponse(err.response.data, setForm, form);
                })
                break;
            case 'edit':
                OrderService.UpdateOrder(form.values).then(() => {
                    navigate('/')
                }).catch((err) => {
                    checkModalResponse(err.response.data, setForm, form);
                })
                break;
        }
    }

    useEffect(() => {
        if (action === 'edit' && order_id) {
            OrderService.GetOrder(order_id).then((res: any) => {
                const data = res.data
                setForm({
                    ...form,
                    values: {
                        ...form.values,
                        id: data.id,
                        country: data.country?.id,
                        store: data.store?.id,
                        type_of_transport: data.type_of_transport?.id,
                        tracking_number: data.tracking_number,
                        leave_gift_boxes: data.leave_gift_boxes,
                        leave_shoe_boxes: data.leave_shoe_boxes,
                        products: data.products,
                    }
                })
            })
        }
    }, [action, order_id])

    return (
        <>
            <div className="w-full flex flex-col justify-start items-center gap-[20px] bg-mobileBackground bg-center bg-cover">
                <div className="w-full flex justify-center items-center gap-[20px] h-[200px] px-[20px] relative">
                    <h1 className="text-[white] text-[30px] font-[400]">
                        {action === 'add' && t('add_order.title')}
                        {action === 'edit' && t('edit_order.title')}
                    </h1>
                </div>
            </div>
            <form onSubmit={handleFormSubmit}
                  className="w-full flex flex-col justify-start items-center pt-[20px] pb-[100px] gap-[10px]">
                <div className="w-full flex justify-start px-[20px] pb-[10px]"
                     style={{borderBottom: '1px solid #48A5D1'}}>
                    <p className='text-[#48A5D1] text-[12px] font-[400]'>{t('add_order.note')}</p>
                </div>
                <div className="w-full flex flex-col justify-start items-center gap-[10px] px-[20px] pt-[10px]">
                    <FormControl required fullWidth size="small">
                        <InputLabel>{t('input_labels.country')}</InputLabel>
                        <Select
                            label={t('input_labels.country')}
                            value={form.values.country}
                            error={form.validation.error.country}
                            onChange={(event) => {
                                setForm({
                                    ...form,
                                    values: {
                                        ...form.values,
                                        country: event.target.value
                                    }
                                })
                            }}
                        >
                            {countriesList.loading
                                ? <div>loading</div>
                                : countriesList.error
                                    ? <div>Error</div>
                                    : countriesList.result?.data.map((item: any) => (
                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                    ))
                            }
                        </Select>
                        <FormHelperText>{form.validation.message.country}</FormHelperText>
                    </FormControl>
                    <FormControl required fullWidth size="small">
                        <InputLabel>{t('input_labels.store')}</InputLabel>
                        <Select
                            label={t('input_labels.store')}
                            error={form.validation.error.store}
                            value={form.values.store}
                            onChange={(event) => {
                                setForm({
                                    ...form,
                                    values: {
                                        ...form.values,
                                        store: event.target.value
                                    }
                                })
                            }}
                        >
                            {stores.loading
                                ? <div>loading</div>
                                : stores.error
                                    ? <div>Error</div>
                                    : stores.result?.data.map((item: any) => (
                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                    ))
                            }
                        </Select>
                        <FormHelperText>{form.validation.message.store}</FormHelperText>
                    </FormControl>
                    <TextField
                        fullWidth
                        required
                        size="small"
                        label={t('input_labels.tracking_number')}
                        error={form.validation.error.tracking_number}
                        helperText={form.validation.message.tracking_number}
                        value={form.values.tracking_number}
                        onChange={(event) => {
                            setForm({
                                ...form,
                                values: {
                                    ...form.values,
                                    tracking_number: event.target.value
                                }
                            })
                        }}
                    />
                    <FormControl required fullWidth size="small">
                        <InputLabel>{t('input_labels.type_of_transport')}</InputLabel>
                        <Select
                            label={t('input_labels.type_of_transport')}
                            error={form.validation.error.type_of_transport}
                            value={form.values.type_of_transport}
                            onChange={(event) => {
                                setForm({
                                    ...form,
                                    values: {
                                        ...form.values,
                                        type_of_transport: event.target.value
                                    }
                                })
                            }}
                        >
                            {getTransports.loading
                                ? <div>loading</div>
                                : getTransports.error
                                    ? <div>Error</div>
                                    : getTransports.result?.data.map((item: any) => (
                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                    ))
                            }
                        </Select>
                        <FormHelperText>{form.validation.message.type_of_transport}</FormHelperText>
                    </FormControl>

                    {form.values.products.map((product: any, index: number) => (
                        <div className="w-full flex flex-col justify-start items-center" key={index}>
                            <div className="w-full flex justify-between items-center bg-[#48A5D1] rounded-t-[4px] px-[10px]">
                                <p className='text-[white] text-[12px] font-[400]'>
                                    {t('add_order.product')} {index + 1}
                                </p>
                                <IconButton
                                    size='small'
                                    disabled={!(form.values.products.length > 1)}
                                    onClick={() => {
                                        const arr = form.values.products
                                        arr.splice(index, 1)
                                        setForm({
                                            ...form,
                                            values: {
                                                ...form.values,
                                                products: arr
                                            }
                                        })
                                    }}
                                >
                                    <DeleteIcon fontSize='small'/>
                                </IconButton>
                            </div>
                            <div className="w-full flex flex-col justify-start items-center gap-[10px] p-[10px]" style={{border: '1px solid #48A5D1'}}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    value={product.title}
                                    label={t('input_labels.name')}
                                    required
                                    onChange={(event) => {
                                        const arr = form.values.products
                                        arr[index].title = event.target.value
                                        setForm({
                                            ...form,
                                            values: {
                                                ...form.values,
                                                products: arr
                                            }
                                        })
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    size="small"
                                    className='TextField-without-border-radius'
                                    value={product.price}
                                    label={t('input_labels.price')}
                                    type="number"
                                    required
                                    onChange={(event) => {
                                        const arr = form.values.products
                                        arr[index].price = event.target.value
                                        setForm({
                                            ...form,
                                            values: {
                                                ...form.values,
                                                products: arr
                                            }
                                        })
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    type='number'
                                    size="small"
                                    label={t('input_labels.quantity')}
                                    value={product.quantity}
                                    required
                                    onChange={(event) => {
                                        const arr = form.values.products
                                        arr[index].quantity = event.target.value
                                        setForm({
                                            ...form,
                                            values: {
                                                ...form.values,
                                                products: arr
                                            }
                                        })
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    type='number'
                                    label={t('input_labels.total_price')}
                                    size="small"
                                    value={+product.quantity * +product.price}
                                    disabled
                                />
                            </div>
                        </div>
                    ))}

                    {!countriesList.loading && !countriesList.error && countriesList.result?.data.find((country: any) => form.values.country === country.id) && countriesList.result?.data.find((country: any) => form.values.country === country.id).code === 'USA' &&
                        <div className="w-full flex justify-start items-center flex-wrap">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onClick={() => {
                                            setForm({
                                                ...form,
                                                values: {
                                                    ...form.values,
                                                    leave_shoe_boxes: !form.values.leave_shoe_boxes
                                                }
                                            })
                                        }}
                                        checked={form.values.leave_shoe_boxes}
                                    />
                                }
                                label={'Leave shoe boxes'}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onClick={() => {
                                            setForm({
                                                ...form,
                                                values: {
                                                    ...form.values,
                                                    leave_gift_boxes: !form.values.leave_gift_boxes
                                                }
                                            })
                                        }}
                                        checked={form.values.leave_gift_boxes}
                                    />
                                }
                                label={'Leave gift boxes (bulk weight)'}
                            />
                        </div>
                    }
                </div>

                <div className="w-full flex flex-col justify-start items-center gap-[20px] p-[10px]" style={{borderTop: '1px solid #48A5D1'}}>
                    <Button className='orderAdd-mainContainer__footerPackageAddButton' onClick={() => {
                        setForm({
                            ...form,
                            values: {
                                ...form.values,
                                products: [...form.values.products, {
                                    id: '',
                                    title: '',
                                    price: '',
                                    new: true,
                                    quantity: '',
                                    description: '',
                                }]
                            }
                        })
                    }}>
                        <AddCircleIcon style={{marginRight: '5px', color: '#E52EC7'}}/>{t('add_order.add_product')}
                    </Button>
                    <Stack spacing={'20px'} direction={"row"}>
                        <Button variant="outlined"
                                onClick={() => {
                                    navigate('/')
                                }}>
                            {t('button.cancel')}
                        </Button>
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            loading={form.requested}
                            disabled={form.requested}
                        >
                            {t('button.add_order')}
                        </LoadingButton>
                    </Stack>
                </div>
            </form>
        </>
    );
}


export default MOrdersDetails;