import {$axios} from "../http";
import {useAsync} from "react-async-hook";

export const UserService = {
    async UserProfileDelete() {
        return await $axios.get('/customer/profile/delete/')
    },
    GetUserInfo() {
        return useAsync(async () => {
            return await $axios.get('/customer/');
        }, [])
    },
    GetUserPhones() {
        return useAsync(async () => {
            return await $axios.get('/phones/');
        }, [])
    },
    GetUserAddresses() {
        return useAsync(async () => {
            return await $axios.get('/customer/addresses/');
        }, [])
    },
    async PasswordChange(data: any) {
        return await $axios.post('/customer/password/change/', data)
    },
    async PasswordRecovery(email: string, otp: string) {
        return await $axios.post('/customer/password/recovery/', {email: email, otp: otp,})
    },
    async PostUserAuthData(email: string, password: string) {
        return await $axios.post('/customer/token/', {email: email, password: password})
    },
    async PostUserEmailData(email: string) {
        return await $axios.post('/otp/', {email: email})
    },
    async PostUserRegisterData(values: any) {

        let form_data = new FormData()
        const data: any = values
        if (data.passport_back_side === null) {
            delete data.passport_back_side
        }
        if (data.passport_front_side === null) {
            delete data.passport_front_side
        }
        for (let key in data) {
            form_data.append(key, data[key]);
        }
        return await $axios.post('/register/', form_data)
    },
    async UpdateUserInfo(data: any) {
        return await $axios.put('/customer-update/', data)
    },
    async DeleteAddress(id: string) {
        return await $axios.delete(`/customer/addresses/${id}/`)
    },
    async UpdateUserPassport(photos: any) {
        let form_data = new FormData()
        for (let key in photos) {
            form_data.append(key, photos[key]);
        }
        return await $axios.put('/customer-passport-update/', form_data)
    },
    GetPersonalCountries(user_type: string) {
        return useAsync(async () => {
            return await $axios.get(`/accounts/countries/?user_type=${user_type}`,);
        }, [])
    },
}