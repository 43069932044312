import React, { useState, useEffect } from "react";
import "../../styles/desktopVersion/address.css";
import { useTranslation } from "react-i18next";
import { AddressesService } from "../../services/AddressesService";
import { Button, IconButton, Modal, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import Calculator from "../../components/Calculator";
import EmailIcon from "@mui/icons-material/Email";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const modalInitialValues = {
  open: false,
  values: {
    price_note: "",
  },
};
const modalInitialValues2 = {
  open: false,
  values: {
    pick_up_point: "",
  },
};

function Home() {
  //translation
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user);

  const addressesList = AddressesService.GetAddresses();
  const [modal, setModal] = useState<any>(modalInitialValues);
  const pointsList = AddressesService.GetPointsList();

  const [modal2, setModal2] = useState<any>(modalInitialValues2);

  const replaceKeysToData = (text: string) => {
    return text
      .replace("{client_code}", user.user.id)
      .replace("{client_phone}", user.user.phone)
      .replace("{client_full_name}", user.user.full_name)
      .replace("{client_first_name}", user.user.user.first_name)
      .replace("{client_last_name}", user.user.user.last_name);
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setModal2({
      ...modal2,
      requested: true,
    });

    AddressesService.CreatePointsList(modal2.values)
      .then(() => {
        setModal2(modalInitialValues2);
        window.location.reload();
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (user.user?.pick_up_point === null) {
      timeout = setTimeout(() => {
        setModal2({
          ...modalInitialValues2,
          open: true,
        });
      }, 3000);
    }

    return () => clearTimeout(timeout);
  }, [user.user.pick_up_point?.id, modal2.open]);

  return (
    <>
      <div className="flex gap-[20px] items-center mb-[40px]">
        <div className="py-[20px] px-[20px] rounded-[5px] text-[20px] bg-[#48A5D1] text-white flex items-center gap-[10px]">
          {t("header.code")}:<p className={"font-bold"}>{user.user.id}</p>
        </div>
        <div className="bg-white px-[20px] py-[10px] rounded-[10px] shadow-md">
          <div className="flex flex-col text-[16px] gap-[8px]">
            <div>
              {/* {t("header.code")} :{" "} */}
              Ваш ПВЗ:
              <span className={"font-bold"}>
                {user.user?.pick_up_point?.name}
              </span>
            </div>
            <p
              onClick={() => {
                setModal2({
                  ...modalInitialValues2,
                  open: true,
                });
              }}
              className="text-[14px] underline underline-offset-4 text-[#48A5D1] cursor-pointer hover:no-underline"
            >
              {/* {t("header.code")} */}
              Сменить
            </p>
          </div>
        </div>
      </div>

      <h1 className="addressPage__title">{t("addresses.title")}</h1>

      <div className="w-full grid grid-cols-2 gap-[30px] mb-[40px] mt-[40px]">
        {addressesList.loading ? (
          <>
            <Skeleton variant="rectangular" width={"100%"} height={342} />
            <Skeleton variant="rectangular" width={"100%"} height={342} />
            <Skeleton variant="rectangular" width={"100%"} height={342} />
            <Skeleton variant="rectangular" width={"100%"} height={342} />
            <Skeleton variant="rectangular" width={"100%"} height={342} />
            <Skeleton variant="rectangular" width={"100%"} height={342} />
          </>
        ) : addressesList.error ? (
          "error"
        ) : (
          addressesList.result?.data.map((address: any, index: any) => (
            <div
              key={index}
              className="bg-[white] w-full flex flex-col justify-start items-start shadow-md"
            >
              <div className="w-full p-[24px] bg-[#48A5D11A]">
                <div className="w-full flex justify-between items-center gap-[10px]">
                  <div className="flex items-center gap-[10px] text-[16px] text-[#000000] font-[500]">
                    <p>
                      {address.country.name} - {address.type_of_transport.name}
                    </p>
                    <img src={address.type_of_transport.icon} alt="img" />
                  </div>
                  <div className="p-[12px] bg-[#48A5D11A] flex gap-[10px] items-center rounded-[8px]">
                    <p className="text-[16px] text-[#48A5D1] font-[500]">
                      {t("addresses.tariff")}:
                    </p>
                    <p className="text-[] text-[#141414] font-[400]">
                      {address.tariff} USD
                    </p>
                  </div>
                </div>
              </div>
              <div className="p-[24px] w-full flex flex-col justify-start items-start gap-[30px]">
                <div className="w-full flex flex-col justify-start items-start gap-[10px]">
                  <p className="text-[14px] text-[#141414] font-[500]">
                    {replaceKeysToData(`${address.title}`)}
                  </p>
                  <p className="text-[14px] text-[#141414] font-[400] whitespace-pre-wrap">
                    {replaceKeysToData(`${address.subtitle}`)}
                  </p>
                </div>
                <div className="w-full flex flex-col justify-start items-start gap-[5px]">
                  <span className="text-[14px] text-[#6E6E6E] font-[600]">
                    {t("addresses.weekends")}: {address.weekend}
                  </span>
                  <span className="text-[14px] text-[#6E6E6E] font-[600]">
                    {t("addresses.delivery")}: {address.delivery_period}
                  </span>
                </div>
                <div className="w-full flex justify-between items-end flex-1">
                  <div className="flex justify-start items-center gap-[10px]">
                    <Button
                      size="small"
                      startIcon={<LocalOfferIcon />}
                      onClick={() => {
                        setModal({
                          ...modalInitialValues,
                          open: true,
                          values: {
                            ...modalInitialValues.values,
                            price_note: address.price_note,
                          },
                        });
                      }}
                    >
                      Прайс
                    </Button>

                    <Calculator address={address} />

                    {address.sms_link !== null && address.sms_link !== "" && (
                      <Button
                        size="small"
                        startIcon={<EmailIcon />}
                        onClick={() => window.open(address.sms_link, "_blank")}
                      >
                        СМС-шлюз
                      </Button>
                    )}
                  </div>
                  <Button
                    variant="contained"
                    color="blue"
                    onClick={() => {
                      navigate({
                        pathname: `/address/${address.id}`,
                      });
                    }}
                  >
                    Подробнее
                  </Button>
                </div>
              </div>
            </div>
          ))
        )}

        <Modal open={modal.open} onClose={() => setModal(modalInitialValues)}>
          <div className="mainModal relative flex flex-col justify-start items-center">
            <IconButton
              sx={{
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
              onClick={() => setModal(modalInitialValues)}
            >
              <CloseIcon />
            </IconButton>
            <h2 className="text-[30px] font-[600] text-[#1E1C2A] mb-[40px]">
              Прайс
            </h2>

            <div
              dangerouslySetInnerHTML={{ __html: modal.values.price_note }}
            ></div>
          </div>
        </Modal>

        <Modal
          open={modal2.open}
          onClose={() => setModal2(modalInitialValues2)}
        >
          <div className="mainModal relative flex flex-col justify-start items-center">
            <IconButton
              sx={{
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
              onClick={() => setModal2(modalInitialValues2)}
            >
              <CloseIcon />
            </IconButton>
            <h2 className="text-[30px] font-[600] text-[#1E1C2A] mb-[40px]">
              {t("home.modal_title")}
            </h2>

            <ul className="text-[14px] mt-[14px]">
              <li>1. {t("home.modal_1")}</li>
              <li>2. {t("home.modal_2")}</li>
              <li>3. {t("home.modal_3")}</li>
              <li>4. {t("home.modal_4")}</li>
              <li>5. {t("home.modal_5")}</li>
              <li>6. {t("home.modal_6")}</li>
            </ul>

            <div className="flex justify-between w-full mt-[60px]">
              <FormControl className="w-[320px]">
                <InputLabel>Выберите ПВЗ</InputLabel>
                <Select
                  label="Выберите ПВЗ"
                  value={
                    modal2.values?.pick_up_point ||
                    user.user.pick_up_point?.id ||
                    ""
                  }
                  onChange={(event: any) => {
                    setModal2({
                      ...modal2,
                      values: {
                        ...modal2.values,
                        pick_up_point: event.target.value,
                      },
                    });
                  }}
                >
                  {!pointsList.loading &&
                    !pointsList.error &&
                    pointsList.result?.data.map(
                      (country: any, index: number) => (
                        <MenuItem key={index} value={country.id}>
                          {country.name}
                        </MenuItem>
                      )
                    )}
                </Select>
              </FormControl>

              <Button
                className="w-[30%] bg-gradient"
                variant="contained"
                color="blue"
                onClick={handleFormSubmit}
              >
                {t("main.button")}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default Home;
