import React, { useEffect, useState } from "react";
import "../../styles/desktopVersion/history.css";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridColDef, GridFooterContainer } from "@mui/x-data-grid";
import { Pagination } from "@mui/material";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { OrderService } from "../../services/OrderService";
import { TextField, InputAdornment, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

function OrderTracking() {
  //translation
  const { t } = useTranslation();

  let navigate = useNavigate();

  const [filterValue, setFilterValue] = React.useState<any>({
    search: "",
  });

  const [historyData, setHistoryData] = useState<any>([]);

  const historyColumns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1, sortable: false, hide: true },
    {
      field: "created_at",
      headerName: `${t("order_table.date")}`,
      sortable: false,
      flex: 1,
    },

    {
      field: "store",
      headerName: `${t("order_table.store")}`,
      sortable: false,
      flex: 1,
    },

    {
      field: "weight",
      headerName: `${t("order_table.weight")}`,
      sortable: false,
      flex: 1,
    },
    {
      field: "type_of_transport",
      headerName: `${t("order_table.type_of_transport")}`,
      sortable: false,
      flex: 1,
      renderCell: (params: any) => <img src={params.value} alt="status-icon" />,
    },
    {
      field: "country",
      headerName: `${t("order_table.country")}`,
      sortable: false,
      flex: 1,
    },
    {
      field: "tracking_number",
      headerName: `${t("order_table.tracking_number")}`,
      sortable: false,
      flex: 1,
    },
    {
      field: "status",
      headerName: `${t("order_table.status")}`,
      sortable: false,
      flex: 1,
      renderCell: (params: any) => <img src={params.value} alt="status-icon" />,
    },
  ];

  let orderTracking = OrderService.GetOrdersList(filterValue);

  useEffect(() => {
    if (!orderTracking.loading && !orderTracking.error) {
      setHistoryData(
        orderTracking.result?.data.results.map((order: any) => ({
          id: order.id,
          created_at: moment(order.created_at).format("DD.MM.YYYY").toString(),
          country: order.country?.name,
          store: order.store?.name,
          type_of_transport: order.type_of_transport?.icon,
          status: order.status?.icon,
          name: order.comment,
          tracking_number: order.tracking_number,
          weight: order.weight,
        }))
      );
    }
  }, [
    orderTracking.error,
    orderTracking.loading,
    orderTracking.result?.data.results,
  ]);

  const handleSearch = () => {
    // setShowDataGrid(true);
    if (filterValue.search.trim() !== "") {
      setShowDataGrid(true);
    }
  };

  const [showDataGrid, setShowDataGrid] = useState<boolean>(false);

  return (
    <>
      <h1 className="text-[30px] text-black font-[400] mb-[50px]">
        {t("order_tracking.title")}
      </h1>

      <div className="bg-white p-[40px] rounded-[10px] shadow-sm w-full">
        <div className="order-tracking flex items-center justify-center gap-[20px]">
          <TextField
            className="w-[70%]"
            label={t("filter.searchTracking")}
            id="search-bar"
            value={filterValue.search}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ fill: "#48A5D1" }} />
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              const searchText = event.target.value;
              setFilterValue({
                ...filterValue,
                search: searchText,
              });
              setShowDataGrid(searchText.trim() === "123456789");
            }}
          />

          <Button
            onClick={handleSearch}
            className="w-[158px] bg-gradient"
            variant="contained"
            color="blue"
          >
            {t("order_tracking.search_button")}
          </Button>
        </div>

        {showDataGrid && (
          <div className="mb-[70px] mt-[40px]">
            <div
              style={{
                width: "100%",
                //   boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <DataGrid
                rows={historyData}
                columns={historyColumns}
                disableColumnFilter
                keepNonExistentRowsSelected
                isRowSelectable={() => false}
                disableColumnMenu
                disableSelectionOnClick
                autoHeight
                localeText={{
                  noRowsLabel: "Нет информации",
                }}
                onRowDoubleClick={(params) => {
                  navigate(`/orders/${params.row.id}`);
                }}
                loading={orderTracking.loading}
                components={{
                  Footer: () => {
                    return (
                      <GridFooterContainer>
                        <Pagination
                          count={
                            !orderTracking.loading && !orderTracking.error
                              ? orderTracking.result?.data.total_pages
                              : 1
                          }
                          page={
                            !orderTracking.loading && !orderTracking.error
                              ? orderTracking.result?.data.current_page
                              : 1
                          }
                          onChange={(event, value: number) => {
                            setFilterValue({
                              ...filterValue,
                              page: value,
                            });
                          }}
                        />
                      </GridFooterContainer>
                    );
                  },
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default OrderTracking;
