import {createTheme, outlinedInputClasses} from "@mui/material";

declare module '@mui/material/styles' {
    interface Palette {
        blue: Palette['primary'];
        purple: Palette['primary'];
        white: Palette['primary'];
    }

    interface PaletteOptions {
        blue?: PaletteOptions['primary'];
        purple?: PaletteOptions['primary'];
        white?: PaletteOptions['primary'];
    }
}

// Update the Button's color options to include an ochre option
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        blue: true;
        purple: true;
        white: true;
    }
}

export const theme = createTheme({
    palette: {
        blue: {
            main: '#48A5D1',
            light: 'rgba(72,165,209,0.73)',
            dark: '#1974a1',
            contrastText: '#FFF',
        },
        purple: {
            main: '#E52EC7',
            light: 'rgba(229,46,199,0.68)',
            dark: '#c01ba6',
            contrastText: '#FFF',
        },
        white: {
            main: '#FFFFFF',
            light: '#FFFFFF',
            dark: '#f1f0f0',
            contrastText: 'black',
        },
    },
    typography: {
        // "fontFamily": `"Futura"`,
    },
    components:{
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    textTransform: 'none',
                },
            },
        },
    }
});