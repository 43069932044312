import React, { useEffect, useState } from "react";
import "../../styles/desktopVersion/header.css";
import logo from "../../assets/images/GlobalExpress-logo-header.png";
import { Link } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";

import List from "@mui/material/List";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOn from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ReceiptIcon from "@mui/icons-material/Receipt";

import { useTranslation } from "react-i18next";
import { StringAvatar } from "../../helpers/helpers";
import Localize from "../../locales/Localize";
import { removeCookie } from "typescript-cookie";
import { useDispatch, useSelector } from "react-redux";
import { UserService } from "../../services/UserService";
import { updateUser } from "../../store/slices/userSlice";

// export const drawerWidth = 224;

const scrollToFooter = () => {
  const footer = document.getElementById("footer");
  if (footer) {
    footer.scrollIntoView({ behavior: "smooth" });
  }
};

function Header() {
  const { t } = useTranslation();
  const user = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const userInfo = UserService.GetUserInfo();

  useEffect(() => {
    if (!userInfo.loading && !userInfo.error) {
      dispatch(
        updateUser({
          authed: true,
          user: {
            ...userInfo.result?.data,
          },
        })
      );
    }
  }, [userInfo.loading, userInfo.error, userInfo.result?.data]);

  const [hoveredItem, setHoveredItem] = useState(false);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          background: "linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%)",
        }}
      >
        <div className="w-full flex justify-between">
          <Toolbar>
            <Link to="/" className="header__logo">
              <img className="h-[40px]" src={logo} alt="logo" />
            </Link>
          </Toolbar>

          <Toolbar sx={{ flex: 1 }}>
            <div className="w-full flex justify-end gap-[30px] items-center">
              <p className="text-[white]">
                {t("personal.points")} {Math.floor(user.user.bonus)}
              </p>
              <p>
                {`${t("header.exchange")} USD:
                                    ${
                                      user.user.currency.find(
                                        (currentValue: any) =>
                                          currentValue.code === "USD"
                                      ) &&
                                      user.user.currency.find(
                                        (currentValue: any) =>
                                          currentValue.code === "USD"
                                      ).value
                                    }`}
              </p>
              <div className="py-[6.5px] px-[8px] rounded-[5px] bg-[#48A5D1] text-white flex items-center gap-[10px]">
                {t("header.code")}:<p className={"font-bold"}>{user.user.id}</p>
              </div>
              <Localize />
              <Link to="notification">
                <NotificationsIcon sx={{ color: "white" }} />
              </Link>
            </div>
          </Toolbar>

          <Toolbar>
            <div className="min-w-[200px] flex justify-end items-center gap-[10px]">
              <p>
                {`${user.user.user.first_name} ${user.user.user.last_name}`}
              </p>
              <Link to="personal">
                <Avatar
                  {...StringAvatar(
                    `${user.user.user.first_name} ${user.user.user.last_name}`
                  )}
                ></Avatar>
              </Link>
            </div>
          </Toolbar>
          
        </div>
      </AppBar>

      <Drawer
        variant="permanent"
        // sx={{
        //   width: drawerWidth,
        //   flexShrink: 0,
        //   [`& .MuiDrawer-paper`]: {
        //     width: drawerWidth,
        //     boxSizing: "border-box",
        //   },
        // }}
      >
        <Toolbar />
        <Box sx={{ overflow: "hidden" }}>
          <List>
            <ListItem disablePadding>
              <Link to={"/"} className="w-full">
                <ListItemButton>
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("header.main")} />
                </ListItemButton>
              </Link>
            </ListItem>
            <ListItem disablePadding>
              <Link to={"/delivery"} className="w-full">
                <ListItemButton>
                  <ListItemIcon>
                    <LocalShippingIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("header.delivery")} />
                </ListItemButton>
              </Link>
            </ListItem>
            <ListItem disablePadding>
              <Link to={"/orders"} className="w-full">
                <ListItemButton>
                  <ListItemIcon>
                    <ShoppingCartIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("header.orders")} />
                </ListItemButton>
              </Link>
            </ListItem>

            <Divider />

            <ListItem disablePadding>
              <Link
                to={"/contacts"}
                className="w-full"
                onClick={scrollToFooter}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <LocalPhoneIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("header.contact")} />
                </ListItemButton>
              </Link>
            </ListItem>

            <ListItem disablePadding>
              <Link to={"/notification"} className="w-full">
                <ListItemButton>
                  <ListItemIcon>
                    <NotificationsIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("header.notification")} />
                </ListItemButton>
              </Link>
            </ListItem>
            <ListItem disablePadding>
              <Link to={"/personal"} className="w-full">
                <ListItemButton>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("header.personal")} />
                </ListItemButton>
              </Link>
            </ListItem>

            <Divider />

            <ListItem disablePadding>
              <Link to={"/history"} className="w-full">
                <ListItemButton>
                  <ListItemIcon>
                    <AccessTimeIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("header.order_history")} />
                </ListItemButton>
              </Link>
            </ListItem>
            <ListItem disablePadding>
              <Link to={"/order-tracking"} className="w-full">
                <ListItemButton>
                  <ListItemIcon>
                    <LocationOn />
                  </ListItemIcon>
                  <ListItemText primary={"Ослеживание заказа"} />
                </ListItemButton>
              </Link>
            </ListItem>
            <ListItem disablePadding>
              <Link to={"/transactions"} className="w-full">
                <ListItemButton>
                  <ListItemIcon>
                    <ReceiptIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("header.transaction")} />
                </ListItemButton>
              </Link>
            </ListItem>
            <Divider />
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  removeCookie("global_user_access_token");
                  removeCookie("global_user_refresh_token");
                  window.location.reload();
                }}
              >
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={t("header.exit")} />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}

export default Header;
