import React, { useEffect, useState } from "react";
import "../../styles/mobileVersion/Mpersonal.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { UserService } from "../../services/UserService";
import {
  checkModalResponse,
  convertImageUrlToFile,
  MyTimer,
  StringAvatar,
} from "../../helpers/helpers";
import { logout } from "../../store/slices/userSlice";
import {
  Avatar,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { LoadingButton } from "@mui/lab";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

const formInitialValues = {
  values: {
    emailForCheck: "",
    id: "",
    user: {
      first_name: "",
      last_name: "",
      email: "",
      country: "",
    },
    phone: "",
    otp: "",
    addresses: [],
    inn: "",
    city: "",
    street: "",
    house_number: "",
    balance: "",
    is_verified: "",
    passport_back_side: null,
    passport_front_side: null,
  },
  validations: {
    messages: {
      first_name: "",
      last_name: "",
      email: "",
      country: "",
      phone: "",
      otp: "",
      addresses: "",
      inn: "",
      city: "",
      street: "",
      house_number: "",
      balance: "",
      is_verified: "",
      passport_back_side: "",
      passport_front_side: "",
    },
    errors: {
      first_name: false,
      last_name: false,
      email: false,
      country: false,
      phone: false,
      otp: false,
      addresses: false,
      inn: false,
      city: false,
      street: false,
      house_number: false,
      balance: false,
      is_verified: false,
      passport_back_side: false,
      passport_front_side: false,
    },
  },
  requested: false,
  sendCode: false,
  edit: false,
};

const modalInitialValues = {
  values: {
    old_password: "",
    password: "",
    confirm_password: "",
  },
  validations: {
    messages: {
      old_password: "",
      password: "",
      confirm_password: "",
    },
    errors: {
      old_password: false,
      password: false,
      confirm_password: false,
    },
  },
  open: false,
  requested: false,
  action: "",
  showPassword: false,
};
function MPersonal() {
  //translation
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form, setForm] = useState<any>(formInitialValues);
  const [modal, setModal] = useState<any>(modalInitialValues);
  const getPersonalCountries = UserService.GetPersonalCountries("customer");

  const getUserInfo = UserService.GetUserInfo();
  console.log("getUserInfo", getUserInfo);

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setForm({
      ...form,
      requested: true,
    });
    const values = form.values;
    const form_data = new FormData();
    for (let key in values) {
      if (Array.isArray(values[key])) {
        for (let i = 0; i < values[key].length; i++) {
          for (let keyI in values[key][i]) {
            form_data.append(`${key}[${i}]${keyI}`, values[key][i][keyI]);
          }
        }
      } else if (
        typeof values[key] === "object" &&
        key !== "passport_front_side" &&
        key !== "passport_back_side"
      ) {
        for (let keyI in values[key]) {
          form_data.append(`${key}.${keyI}`, values[key][keyI]);
        }
      } else {
        form_data.append(key, values[key]);
      }
    }

    UserService.UpdateUserInfo(form_data)
      .then(() => {
        getUserInfo.execute();
      })
      .catch((err) => {
        checkModalResponse(err.response.data, setModal, modal);
      });
  };
  const handleModalSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    setModal({
      ...modal,
      requested: true,
    });

    switch (modal.action) {
      case "deleteAccount":
        UserService.UserProfileDelete().then(() => {
          dispatch(logout());
        });
        break;
      case "passwordChange":
        UserService.PasswordChange(modal.values).then(() => {
          setModal(modalInitialValues);
        });
        break;
    }
  };

  useEffect(() => {
    if (!getUserInfo.loading && !getUserInfo.error) {
      handleConvertImage(getUserInfo.result?.data).then((res) => {
        const transformedData = res.reduce((acc, curr) => {
          const key = Object.keys(curr)[0];
          acc[key] = curr[key];
          return acc;
        }, {});
        setForm({
          ...formInitialValues,
          emailForCheck: getUserInfo.result?.data.user.email,
          values: {
            ...formInitialValues.values,
            ...getUserInfo.result?.data,
            user: {
              ...formInitialValues.values.user,
              ...getUserInfo.result?.data.user,
              country: getUserInfo.result?.data.user.country?.id,
            },
            ...transformedData,
          },
        });
      });
    }
  }, [getUserInfo.loading, getUserInfo.error, getUserInfo.result?.data]);

  const handleConvertImage = async (data: any) => {
    return await Promise.all(
      Object.entries({
        passport_front_side: data.passport_front_side,
        passport_back_side: data.passport_back_side,
      }).map(async ([key, value]) => {
        return {
          [key]: await convertImageUrlToFile(value),
        };
      })
    );
  };

  const handleResendCode = () => {
    setForm({
      ...form,
      sendCode: true,
      validations: {
        ...form.validations,
        messages: {
          ...form.validations.messages,
          otp: `${t("auth.otp_sent")}`,
        },
      },
    });
    const time = new Date();
    time.setSeconds(time.getSeconds() + 59);
    currentTimeLeftForCode.restart(time);
    UserService.PostUserEmailData(form.values.user.email)
      .then(() => {})
      .catch(() => {
        setForm({
          ...form,
          sendCode: false,
          validations: {
            ...form.validations,
            messages: {
              ...form.validations.messages,
              otp: "",
            },
          },
        });
      });
  };

  const time = new Date();
  const currentTimeLeftForCode = MyTimer(time);
  useEffect(() => {
    if (currentTimeLeftForCode.seconds === 0) {
      setForm((prevState: any) => ({
        ...prevState,
        sendCode: false,
      }));
    }
  }, [currentTimeLeftForCode.seconds]);

  return (
    <>
      <div className="w-full flex flex-col justify-start items-center gap-[20px] bg-mobileBackground bg-center bg-cover">
        <div className="w-full flex flex-col justify-center items-center gap-[20px] h-[200px] px-[20px] relative">
          <h1 className="text-[white] text-[30px] font-[400]">
            {t("personal.title")}
          </h1>
        </div>
      </div>
      <div className="w-full flex flex-col justify-start items-center px-[20px] pt-[20px] pb-[100px] gap-[10px]">
        <div
          className="w-full flex flex-col justify-start items-start gap-[30px] pb-[20px] mb-[20px]"
          style={{ borderBottom: "1px solid rgb(72 165 209)" }}
        >
          <div className="flex justify-start items-start gap-[20px]">
            <Avatar
              {...StringAvatar(
                `${form.values.user.first_name} ${form.values.user.last_name}`
              )}
            ></Avatar>
            <div className="flex flex-col justify-start items-start">
              <h1>
                {form.values.user.first_name} {form.values.user.last_name}
              </h1>
              <div className="flex items-center gap-[20px]">
                <p className="text-black text-[16px] font-[500]">
                  {/* ID */}
                  {/* {form.values.id}  */}
                  {getUserInfo.result?.data?.id}
                </p>
                {form.values.is_verified ? (
                  <p style={{ color: "green" }}>{t("personal.verified")}</p>
                ) : (
                  <p style={{ color: "red" }}>{t("personal.not_verified")}</p>
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-start items-center gap-[10px]">
            <Button
              size="small"
              startIcon={<KeyIcon />}
              variant="outlined"
              onClick={() => {
                setModal({
                  ...modalInitialValues,
                  open: true,
                  action: "passwordChange",
                });
              }}
            >
              {t("personal.change_password")}
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                setForm({
                  ...form,
                  edit: true,
                });
              }}
            >
              <EditIcon />
            </Button>
            <Button
              size="small"
              variant="contained"
              color="error"
              onClick={() => {
                setModal({
                  ...modalInitialValues,
                  open: true,
                  action: "deleteAccount",
                });
              }}
            >
              <DeleteIcon />
            </Button>
          </div>
        </div>

        <form
          onSubmit={handleFormSubmit}
          className="w-full flex flex-col justify-start items-start gap-[20px]"
        >
          <div className="w-full flex flex-col justify-start items-start gap-[20px]">
            {form.edit && (
              <TextField
                size="small"
                label={t("input_labels.first_name")}
                variant="outlined"
                fullWidth
                value={
                  form.values.user.first_name ||
                  getUserInfo.result?.data?.user?.first_name ||
                  ""
                }
                helperText={form.validations.messages.first_name}
                error={form.validations.errors.first_name}
                type={"text"}
                onChange={(event) => {
                  setForm({
                    ...form,
                    values: {
                      ...form.values,
                      user: {
                        ...form.values.user,
                        first_name: event.target.value,
                      },
                    },
                  });
                }}
                required
              />
            )}
            {form.edit && (
              <TextField
                label={t("input_labels.last_name")}
                variant="outlined"
                size="small"
                fullWidth
                value={
                  form.values.user.last_name ||
                  getUserInfo.result?.data?.user?.last_name ||
                  ""
                }
                error={form.validations.errors.last_name}
                helperText={form.validations.messages.last_name}
                type={"text"}
                onChange={(event) => {
                  setForm({
                    ...form,
                    values: {
                      ...form.values,
                      user: {
                        ...form.values.user,
                        last_name: event.target.value,
                      },
                    },
                  });
                }}
                required
              />
            )}
            <TextField
              label={t("input_labels.phone")}
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                disabled: !form.edit,
              }}
              value={form.values.phone || getUserInfo.result?.data.phone || ""}
              onChange={(event) => {
                setForm({
                  ...form,
                  values: {
                    ...form.values,
                    phone: event.target.value,
                  },
                });
              }}
              required
              error={form.validations.errors.phone}
              helperText={form.validations.messages.phone}
            />
            <FormControl fullWidth size="small">
              <InputLabel>{t("input_labels.country")}</InputLabel>
              <Select
                value={
                  form.values.user.country ||
                  getUserInfo.result?.data.user?.country?.id ||
                  ""
                }
                label={t("input_labels.country")}
                required
                error={form.validations.errors.country}
                inputProps={{
                  disabled: !form.edit,
                }}
                onChange={(event) => {
                  setForm({
                    ...form,
                    values: {
                      ...form.values,
                      user: {
                        ...form.values.user,
                        country: event.target.value,
                      },
                    },
                  });
                }}
              >
                {!getPersonalCountries.loading &&
                  !getPersonalCountries.error &&
                  getPersonalCountries.result?.data.map((item: any) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.country}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>
                {form.validations.messages.country}
              </FormHelperText>
            </FormControl>
            <TextField
              label={t("input_labels.email")}
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                disabled: !form.edit,
              }}
              value={
                form.values.user.email ||
                getUserInfo.result?.data.user?.email ||
                ""
              }
              error={form.validations.errors.email}
              helperText={form.validations.messages.email}
              type={"email"}
              onChange={(event) => {
                setForm({
                  ...form,
                  values: {
                    ...form.values,
                    user: {
                      ...form.values.user,
                      email: event.target.value,
                    },
                  },
                });
              }}
              required
            />
            {form.emailForCheck !== form.values.user.email && (
              <TextField
                label={t("input_labels.code")}
                variant="outlined"
                size="small"
                fullWidth
                value={form.values.otp}
                onChange={(event) => {
                  setForm({
                    ...form,
                    values: {
                      ...form.values,
                      otp: event.target.value,
                    },
                  });
                }}
                error={form.validations.errors.otp}
                helperText={form.validations.messages.otp}
                required
                InputProps={{
                  disabled: !form.edit,
                  endAdornment: (
                    <InputAdornment position="end">
                      {!form.sendCode ? (
                        <Button onClick={handleResendCode}>
                          {t("input_labels.send_code")}
                        </Button>
                      ) : (
                        <Button>0:{currentTimeLeftForCode.seconds}</Button>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              type="number"
              InputProps={{
                disabled: !form.edit,
              }}
              label={t("input_labels.inn")}
              value={form.values.inn || getUserInfo.result?.data?.inn || ""}
              error={form.validations.errors.inn}
              helperText={form.validations.messages.inn}
              required
              onChange={(event) => {
                if (event.target.value.length <= 14) {
                  setForm({
                    ...form,
                    values: {
                      ...form.values,
                      inn: event.target.value,
                    },
                  });
                }
              }}
            />
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              type="text"
              InputProps={{
                disabled: !form.edit,
              }}
              label={t("input_labels.city")}
              value={form.values.city || getUserInfo.result?.data?.city || ""}
              error={form.validations.errors.city}
              helperText={form.validations.messages.city}
              required
              onChange={(event) => {
                setForm({
                  ...form,
                  values: {
                    ...form.values,
                    city: event.target.value,
                  },
                });
              }}
            />
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              type="text"
              InputProps={{
                disabled: !form.edit,
              }}
              label={t("input_labels.street")}
              value={
                form.values.street || getUserInfo.result?.data?.street || ""
              }
              error={form.validations.errors.street}
              helperText={form.validations.messages.street}
              required
              onChange={(event) => {
                setForm({
                  ...form,
                  values: {
                    ...form.values,
                    street: event.target.value,
                  },
                });
              }}
            />
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              type="text"
              InputProps={{
                disabled: !form.edit,
              }}
              label={t("input_labels.house_number")}
              value={
                form.values.house_number ||
                getUserInfo.result?.data?.house_number ||
                ""
              }
              error={form.validations.errors.house_number}
              helperText={form.validations.messages.house_number}
              required
              onChange={(event) => {
                setForm({
                  ...form,
                  values: {
                    ...form.values,
                    house_number: event.target.value,
                  },
                });
              }}
            />
            {form.values.addresses.map((item: any, index: number) => (
              <TextField
                key={index}
                label={`${t("input_labels.address")} ${index + 1}`}
                variant="outlined"
                size="small"
                fullWidth
                value={
                  item.address ||
                  getUserInfo.result?.data?.[0].addresses?.address ||
                  ""
                }
                onChange={(event) => {
                  const addresses = form.values.addresses;
                  addresses[index].address = event.target.value;
                  setForm({
                    ...form,
                    values: {
                      ...form.values,
                      addresses: addresses,
                    },
                  });
                }}
                required
                InputProps={{
                  disabled: !form.edit,
                  endAdornment: (
                    <InputAdornment position="end">
                      {form.values.addresses.length > 1 && (
                        <IconButton
                          edge="end"
                          onClick={() => {
                            const addresses = form.values.addresses;
                            addresses.splice(index, 1);
                            setForm({
                              ...form,
                              values: {
                                ...form.values,
                                addresses: addresses,
                              },
                            });
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            ))}

            {form.edit && (
              <Button
                className="orderAdd-mainContainer__footerPackageAddButton"
                onClick={() => {
                  setForm({
                    ...form,
                    values: {
                      ...form.values,
                      addresses: [
                        ...form.values.addresses,
                        {
                          address: "",
                        },
                      ],
                    },
                  });
                }}
              >
                <AddCircleIcon style={{ marginRight: "5px" }} />
                {t("personal.add_address")}
              </Button>
            )}
          </div>
          <div className="w-full flex flex-col justify-start items-start gap-[20px]">
            {form.edit ? (
              <div className="w-full flex flex-col justify-start items-start gap-[20px]">
                <div className="w-full flex flex-col justify-start items-start gap-[20px]">
                  <div
                    className="w-full relative h-[150px] border-[1px] border-[#B9B9B9] border-solid rounded-[4px] cursor-pointer bg-center bg-cover"
                    style={{
                      backgroundImage: `url(${
                        form.values.passport_front_side !== null
                          ? URL.createObjectURL(form.values.passport_front_side)
                          : ""
                      })`,
                    }}
                  >
                    <div className="w-full h-full absolute z-[9] bg-[#ffffffdb]"></div>
                    <input
                      type="file"
                      className="absolute w-[1px] h-[1px] bg-transparent border-none top-0 left-0"
                      onChange={(event) => {
                        if (
                          event.target.files &&
                          event.target.files.length > 0
                        ) {
                          setForm({
                            ...form,
                            values: {
                              ...form.values,
                              passport_front_side: event.target.files[0],
                            },
                          });
                        }
                      }}
                      id="actual-btn1"
                      required={form.values.passport_front_side === null}
                    />
                    <label
                      htmlFor="actual-btn1"
                      className="w-full h-full cursor-pointer bg-transparent absolute z-[99] flex flex-col justify-center items-center"
                    >
                      <p className="text-[16px] font-[500]">
                        {t("personal.passport_front_side")}
                      </p>
                      <p className="text-[red] text-[14px] font-[500] underline">
                        {t("personal.change_passport_photo")}
                      </p>
                    </label>
                  </div>
                  <div
                    className="w-full relative h-[150px] border-[1px] border-[#B9B9B9] border-solid rounded-[4px] cursor-pointer bg-center bg-cover"
                    style={{
                      backgroundImage: `url(${
                        form.values.passport_back_side !== null
                          ? URL.createObjectURL(form.values.passport_back_side)
                          : ""
                      })`,
                    }}
                  >
                    <div className="w-full h-full absolute z-[9] bg-[#ffffffdb]"></div>
                    <input
                      type="file"
                      className="absolute w-[1px] h-[1px] bg-transparent border-none top-0 left-0"
                      onChange={(event) => {
                        if (
                          event.target.files &&
                          event.target.files.length > 0
                        ) {
                          setForm({
                            ...form,
                            values: {
                              ...form.values,
                              passport_back_side: event.target.files[0],
                            },
                          });
                        }
                      }}
                      id="actual-btn2"
                      required={form.values.passport_back_side === null}
                    />
                    <label
                      htmlFor="actual-btn2"
                      className="w-full h-full cursor-pointer bg-transparent absolute z-[99] flex flex-col justify-center items-center"
                    >
                      <p className="text-[16px] font-[500]">
                        {t("personal.passport_back_side")}
                      </p>
                      <p className="text-[red] text-[14px] font-[500] underline">
                        {t("personal.change_passport_photo")}
                      </p>
                    </label>
                  </div>
                </div>
                <p className="personalPage-passportInfo__editBoxNoteText">
                  * {t("personal.passport_add_title")}
                </p>
              </div>
            ) : (
              <div className="w-full flex flex-col justify-start items-start gap-[20px]">
                <div
                  className="w-full relative h-[150px] border-[1px] border-[#B9B9B9] border-solid rounded-[4px] cursor-pointer bg-center bg-cover"
                  style={{
                    backgroundImage: `url(${
                      form.values.passport_front_side !== null
                        ? URL.createObjectURL(form.values.passport_front_side)
                        : ""
                    })`,
                  }}
                  onClick={() => {
                    setModal({
                      ...modalInitialValues,
                      open: true,
                      action: "passportFrontView",
                    });
                  }}
                >
                  <div className="w-full h-full absolute z-[9] bg-[#ffffffdb]"></div>
                  <div className="w-full h-full absolute flex flex-col justify-center items-center gap-[10px] z-[99] bg-transparent">
                    <p className="text-[16px] font-[500]">
                      {t("personal.passport_front_side")}
                    </p>
                    <p className="text-[#48A5D1] text-[14px] font-[500] underline">
                      {t("personal.show_passport")}
                    </p>
                  </div>
                </div>
                <div
                  className="w-full relative h-[150px] border-[1px] border-[#B9B9B9] border-solid rounded-[4px] cursor-pointer bg-center bg-cover"
                  style={{
                    backgroundImage: `url(${
                      form.values.passport_back_side !== null
                        ? URL.createObjectURL(form.values.passport_back_side)
                        : ""
                    })`,
                  }}
                  onClick={() => {
                    setModal({
                      ...modalInitialValues,
                      open: true,
                      action: "passportBackView",
                    });
                  }}
                >
                  <div className="w-full h-full absolute z-[9] bg-[#ffffffdb]"></div>
                  <div className="w-full h-full absolute flex flex-col justify-center items-center gap-[10px] z-[99] bg-transparent">
                    <p className="text-[16px] font-[500]">
                      {t("personal.passport_back_side")}
                    </p>
                    <p className="text-[#48A5D1] text-[14px] font-[500] underline">
                      {t("personal.show_passport")}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          {form.edit && (
            <div className="w-full flex justify-center items-center gap-[20px]">
              <Button
                variant="outlined"
                onClick={() => {
                  setForm({
                    ...form,
                    edit: false,
                  });
                }}
              >
                {t("button.cancel")}
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                disabled={form.requested}
                loading={form.requested}
              >
                {t("button.save")}
              </LoadingButton>
            </div>
          )}
        </form>
      </div>

      <Modal
        onClose={() => {
          setModal({
            ...modalInitialValues,
            open: false,
          });
        }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
        open={modal.open}
      >
        <div className=" w-full bg-white p-[20px] rounded-[10px] shadow-md">
          <h1 className="mb-[20px]">
            {modal.action === "passportFrontView" &&
              t("personal.passport_front_side")}
            {modal.action === "passportBackView" &&
              t("personal.passport_back_side")}
            {modal.action === "deleteAccount" && t("deleteConfirmation")}
            {modal.action === "passwordChange" && t("personal.change_password")}
          </h1>
          {modal.action === "passportFrontView" && (
            <div
              className="w-full h-[300px] bg-cover bg-center"
              //   style={{
              //     backgroundImage: `url(${
              //       form.values.passport_front_side !== null
              //         ? URL.createObjectURL(form.values.passport_front_side)
              //         : ""
              //     })`,
              //   }}
              style={{
                backgroundImage: `url(${getUserInfo.result?.data?.passport_front_side})`,
              }}
            ></div>
          )}

          {modal.action === "passportBackView" && (
            <div
              className="w-full h-[300px] bg-cover bg-center"
              //   style={{
              //     backgroundImage: `url(${
              //       form.values.passport_back_side !== null
              //         ? URL.createObjectURL(form.values.passport_back_side)
              //         : ""
              //     })`,
              //   }}
              style={{
                backgroundImage: `url(${getUserInfo.result?.data?.passport_back_side})`,
              }}
            ></div>
          )}

          {modal.action === "deleteAccount" && (
            <form
              onSubmit={handleModalSubmit}
              className="w-full flex justify-between items-center gap-[30px]"
            >
              <Button
                variant="outlined"
                onClick={() => setModal(modalInitialValues)}
                fullWidth
              >
                {t("deleteConfirmationNo")}
              </Button>
              <LoadingButton
                variant="contained"
                color="error"
                type="submit"
                fullWidth
                disabled={modal.requested}
                loading={modal.requested}
              >
                {t("button.delete")}
              </LoadingButton>
            </form>
          )}
          {modal.action === "passwordChange" && (
            <form
              onSubmit={handleModalSubmit}
              className="w-full flex flex-col justify-start items-center gap-[20px]"
            >
              <TextField
                size="small"
                fullWidth
                label={t("input_labels.old_password")}
                variant="outlined"
                type={modal.showPassword ? "text" : "password"}
                value={modal.values.old_password}
                onChange={(event) => {
                  setModal({
                    ...modal,
                    values: {
                      ...modal.values,
                      old_password: event.target.value,
                    },
                  });
                }}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setModal({
                            ...modal,
                            showPassword: !modal.showPassword,
                          });
                        }}
                      >
                        {modal.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                size="small"
                label={t("input_labels.password")}
                variant="outlined"
                fullWidth
                type={modal.showPassword ? "text" : "password"}
                value={modal.values.password}
                onChange={(event) => {
                  setModal({
                    ...modal,
                    values: {
                      ...modal.values,
                      password: event.target.value,
                    },
                  });
                }}
                error={modal.validations.errors.password}
                helperText={modal.validations.messages.password}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setModal({
                            ...modal,
                            showPassword: !modal.showPassword,
                          });
                        }}
                      >
                        {modal.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                size="small"
                label={t("input_labels.repeat_password")}
                variant="outlined"
                fullWidth
                type={modal.showPassword ? "text" : "password"}
                value={modal.values.confirm_password}
                onChange={(event) => {
                  setModal({
                    ...modal,
                    values: {
                      ...modal.values,
                      confirm_password: event.target.value,
                    },
                  });
                }}
                error={modal.values.password !== modal.values.confirm_password}
                helperText={
                  modal.values.password !== modal.values.confirm_password &&
                  "Пароли не соответствуют!"
                }
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setModal({
                            ...modal,
                            showPassword: !modal.showPassword,
                          });
                        }}
                      >
                        {modal.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div className="w-full flex items-center gap-[20px]">
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => setModal(modalInitialValues)}
                >
                  {t("button.cancel")}
                </Button>
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  disabled={modal.requested}
                  loading={modal.requested}
                >
                  {t("button.submit")}
                </LoadingButton>
              </div>
            </form>
          )}
        </div>
      </Modal>
    </>
  );
}

export default MPersonal;
