import React from "react";
import '../../../styles/mobileVersion/MmyOrderView.css'
import {useParams} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment/moment";
import {DataGrid} from "@mui/x-data-grid";
import {useTranslation} from "react-i18next";
import {OrderService} from "../../../services/OrderService";

export default function MOrderView() {
    //translation
    const {t} = useTranslation()

    const {id, status} = useParams()
    const order = OrderService.GetOrderInfo(id)
    return (
        <>
            <div
                className="w-full flex flex-col justify-start items-center gap-[20px] bg-mobileBackground bg-center bg-cover">
                <div className="w-full flex flex-col justify-center items-center gap-[20px] h-[200px] px-[20px] relative">
                    <h1 className="text-[white] text-[30px] font-[400]">
                        {t('view_order.title')}
                    </h1>
                </div>
            </div>
            <div className="w-full flex flex-col justify-start items-center px-[20px] pt-[20px] pb-[100px] gap-[10px]">
                {order.loading
                    ?
                    <div className="MMyOrdersList__mainContainerWrapper">
                        <CircularProgress style={{marginTop: '20px', marginBottom: '20px'}}/>
                    </div>
                    : !order.error &&
                    <div className="MMyOrdersList__mainContainerWrapper">
                        <div className="MMyOrdersList__orderInfoBox">
                            <div className="MMyOrdersList-orderInfoBox__infoItem title">
                                <p>{t('view_order.order')}</p>
                                <span>№{order.result?.data.id}</span>
                            </div>
                            <div className="MMyOrdersList-orderInfoBox__infoItem">
                                <p>{t('input_labels.tracking_number')}</p>
                                <span>{order.result?.data.tracking_number}</span>
                            </div>
                            <div className="MMyOrdersList-orderInfoBox__infoItem">
                                <p>{t('input_labels.store')}</p>
                                <span>{order.result?.data.store?.name}</span>
                            </div>
                            <div className="MMyOrdersList-orderInfoBox__infoItem">
                                <p>{t('input_labels.type_of_transport')}</p>
                                <span><img src={order.result?.data.type_of_transport.icon} alt="img"/></span>
                            </div>
                            <div className="MMyOrdersList-orderInfoBox__infoItem">
                                <p>{t('input_labels.weight')}</p>
                                <span>{order.result?.data.rounded_weight && `${order.result?.data.rounded_weight} ${t('mobile.kilogram')}`}</span>
                            </div>
                            <div className="MMyOrdersList-orderInfoBox__infoItem">
                                <p>{t('input_labels.price')}</p>
                                <span>{order.result?.data.total_price && `${order.result?.data.total_price} USD`}</span>
                            </div>
                            <div className="MMyOrdersList-orderInfoBox__infoItem">
                                <p>{t('input_labels.receiver')}</p>
                                <span>{order.result?.data.user.full_name}</span>
                            </div>
                        </div>
                        <div style={{width: '100%', margin: '20px'}}>
                            <DataGrid
                                rows={order.result?.data.products.map((product: any) => (
                                    {
                                        id: `${product.id}`,
                                        name: `${product.title}`,
                                        quantity: `${product.quantity}`,
                                        price: `${product.price}`,
                                    }
                                ))}
                                columns={[
                                    {field: 'id', headerName: 'ID', width: 80, hide: true},
                                    {
                                        field: 'name',
                                        headerName: `${t('order_table.name_of_product')}`,
                                        width: 170
                                    },
                                    {field: 'quantity', headerName: `${t('order_table.quantity')}`, width: 80},
                                    {field: 'price', headerName: `${t('order_table.price')}`, width: 80},
                                ]}
                                pageSize={7}
                                rowsPerPageOptions={[7]}
                                disableColumnFilter
                                disableSelectionOnClick
                                filterMode='server'
                                autoHeight
                                localeText={{
                                    noRowsLabel: "Нет информации",
                                  }}
                            />
                        </div>
                        <div className="MMyOrdersList__orderInfoBox">
                            {order.result?.data.order_status_history.map((status: any) => (
                                    <div className="MMyOrdersList-orderInfoBox__infoItem">
                                        <p>{status.status.name}</p>
                                        <span>{(moment(status.created_at)).format('DD.MM.YYYY').toString()}</span>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                }
            </div>
        </>
    );
}