import React, {useEffect, useState} from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import '../../styles/mobileVersion/Mfooter.css'
import {AppBar, Box, Button, Fab, IconButton, styled, SwipeableDrawer, Toolbar} from "@mui/material";
import {useTranslation} from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PhoneIcon from "@mui/icons-material/Phone";
import {createSearchParams, useNavigate} from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import {useDispatch} from "react-redux";
import {logout, updateUser} from "../../store/slices/userSlice";
import {UserService} from "../../services/UserService";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
const StyledFab = styled(Fab)({
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
});

export default function MFooter() {
    //translation
    const {t} = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [menu, setMenu] = useState(false)
    const links = [
        {
            startIcon: <LocalShippingIcon className='MFooter-link__icon'/>,
            endIcon: <ArrowForwardIosIcon/>,
            label: t('button.arrange_delivery'),
            route: '/delivery/add'
        },
        {
            startIcon: <NotificationsIcon className='MFooter-link__icon'/>,
            endIcon: <ArrowForwardIosIcon/>,
            label: t('header.notification'),
            route: '/notification'
        },
        {
            startIcon: <PersonIcon className='MFooter-link__icon'/>,
            endIcon: <ArrowForwardIosIcon/>,
            label: t('header.personal'),
            route: '/personal'
        },
        {
            startIcon: <ReceiptIcon className='MFooter-link__icon'/>,
            endIcon: <ArrowForwardIosIcon/>,
            label: t('header.transaction'),
            route: '/transactions'
        },
        {
            startIcon: <AccessTimeIcon className='MFooter-link__icon'/>,
            endIcon: <ArrowForwardIosIcon/>,
            label: "Ослеживание заказа",
            route: '/order-tracking'
        },
        {
            startIcon: <PhoneIcon className='MFooter-link__icon'/>,
            endIcon: <ArrowForwardIosIcon/>,
            label: t('header.contact'),
            route: '/contacts'
        },
    ]

    const userInfo = UserService.GetUserInfo()

    useEffect(()=>{
        if(!userInfo.loading && !userInfo.error){
            dispatch(updateUser({
                authed: true,
                user: {
                    ...userInfo.result?.data,
                }
            }))
        }
    },[userInfo.loading, userInfo.error, userInfo.result?.data])
    return (
        <>
            <SwipeableDrawer
                anchor='bottom'
                open={menu}
                onClose={() => setMenu(false)}
                onOpen={() => setMenu(true)}
            >
                <>
                    <div className="w-full flex flex-col justify-start items-center gap-[20px] bg-mobileBackground bg-center bg-cover">
                        <div className="w-full flex justify-center items-center gap-[20px] h-[200px] px-[20px]">
                            <h1 className="text-[white] text-[30px] font-[400]">{t('mobile.menu')}</h1>
                        </div>
                    </div>
                    <div className="w-full h-screen flex flex-col justify-start items-center pt-[20px] pb-[100px] px-[20px] gap-[10px]">
                        {links.map((item, index: number) => (
                            <Button
                                key={index}
                                fullWidth
                                size='large'
                                variant='contained'
                                color='white'
                                endIcon={item.endIcon}
                                onClick={() => {navigate(item.route); setMenu(false)}}
                            >
                                <div className="w-full flex justify-start">
                                    <div className='MFooter-link__iconBox'>
                                        {item.startIcon}
                                    </div>
                                    <div className="MFooter-link__textBox">
                                        <p>{item.label}</p>
                                    </div>
                                </div>
                            </Button>
                        ))}
                        <Button
                            fullWidth
                            size='large'
                            variant='contained'
                            color='white'
                            endIcon={<ArrowForwardIosIcon/>}
                            onClick={() => dispatch(logout())}
                        >
                            <div className="w-full flex justify-start">
                                <div className='MFooter-link__iconBox'>
                                    <LogoutIcon className='MFooter-link__icon'/>
                                </div>
                                <div className="MFooter-link__textBox">
                                    <p>{t('header.exit')}</p>
                                </div>
                            </div>
                        </Button>
                    </div>
                </>
            </SwipeableDrawer>
            <AppBar position="fixed" sx={{top: 'auto', bottom: 0, zIndex: '9999', background: '#FFFFFF', color: '#48A5D1'}}>
                <Toolbar>
                    <IconButton color="inherit" onClick={()=> {navigate('/');setMenu(false)}}>
                        <HomeIcon/>
                    </IconButton>
                    <StyledFab color="secondary" aria-label="add" onClick={()=>{
                        navigate({
                            pathname: "/orders/details",
                            search: createSearchParams({
                                action: "add",
                            }).toString(),
                        });
                        setMenu(false)
                    }}>
                        <AddIcon/>
                    </StyledFab>
                    <Box sx={{flexGrow: 1}}/>
                    <IconButton
                        color="inherit"
                        onClick={() => setMenu(!menu)}
                    >
                        <MenuIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
        </>
    );
}