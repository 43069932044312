import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {TextField, Avatar, InputAdornment, IconButton} from "@mui/material";
import {MyTimer} from "../../../helpers/helpers";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useTranslation} from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import {UserService} from "../../../services/UserService";

interface IRecoverValues {
    email: string,
    code: string,
}
interface IRecoverValidation {
    codeCheck: boolean,
    codeHelperText: string,
    emailCheck: boolean,
    emailHelperText: string,
    showPassword: boolean,
    sendCode: boolean,
    requestIsSend: boolean,
}

const RecoverModal = ({setAuthSteps, setAuthAgainAfterRecovery}: {setAuthSteps: Dispatch<SetStateAction<string>>, setAuthAgainAfterRecovery: Dispatch<SetStateAction<boolean>>}) => {
    //translation
    const {t} = useTranslation()

    const [values, setValues] = useState<IRecoverValues>({
        email: '',
        code: '',
    })
    const [validation, setValidation] = useState<IRecoverValidation>({
        codeCheck: false,
        codeHelperText: '',
        emailCheck: false,
        emailHelperText: '',
        showPassword: false,
        sendCode: false,
        requestIsSend: false,
    });
    const time = new Date();
    const currentTimeLeftForCode = MyTimer(time)
    const handleChange = (prop: keyof IRecoverValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        clearValidation()
        setValues({ ...values, [prop]: event.target.value });
    };
    const clearValidation = () =>{
        setValidation({
            codeCheck: false,
            codeHelperText: '',
            emailCheck: false,
            emailHelperText: '',
            showPassword: false,
            sendCode: false,
            requestIsSend: false,
        });
    }
    const handleSubmit = (e: React.SyntheticEvent) =>{
        e.preventDefault()
        setValidation({
            ...validation,
            requestIsSend: false,
        });
        UserService.PasswordRecovery(values.email, values.code).then(()=>{
            setAuthAgainAfterRecovery(true)
            setAuthSteps('auth')
        }).catch((err)=>{
            if(err.response.data.user){
                setValidation({
                    ...validation,
                    emailCheck: true,
                    emailHelperText: err.response.data.user[0],
                    codeCheck: true,
                    codeHelperText: err.response.data.user[0],
                    requestIsSend: false,
                })
            }
            if(err.response.data.otp){
                setValidation({
                    ...validation,
                    codeCheck: true,
                    codeHelperText: err.response.data.otp[0],
                    requestIsSend: false,
                })
            }
        })
    }
    const handleResendCode = () =>{
        setValidation({
            ...validation,
            sendCode: true,
            codeHelperText: `${t('auth.otp_sent')}`
        })
        const time = new Date();
        time.setSeconds(time.getSeconds() + 59);
        currentTimeLeftForCode.restart(time)
        UserService.PostUserEmailData(values.email).then(()=>{
        }).catch(()=>{
            setValidation({
                ...validation,
                sendCode: false,
                codeHelperText: ''
            })
        })
    }
    useEffect(()=>{
        if(currentTimeLeftForCode.seconds === 0){
            setValidation({...validation, sendCode: false})
        }
    }, [currentTimeLeftForCode.seconds])
    return (
        <div className='recoverModal' id='recoverModal'>
            <div className='recoverModal__mainContainer'>
                <form onSubmit={handleSubmit} className='recoverModal__form'>
                    <IconButton className='goBackItem'
                                onClick={()=>{setAuthSteps('auth')}}
                    >
                        <ArrowBackIosIcon style={{color:'#48A5D1'}}/> <span>{t('button.back')}</span>
                    </IconButton>
                    <Avatar className='Avatar'><LockOutlinedIcon/></Avatar>
                    <p className='authModal-form__title'>{t('auth.recover_password')}</p>
                    <TextField
                        label={t('input_labels.email')}
                        variant="outlined"
                        margin='normal'
                        type='email'
                        fullWidth={true}
                        value={values.email}
                        onChange={handleChange('email')}
                        error={validation.emailCheck}
                        helperText={validation.emailHelperText}
                        required={true}
                    />
                    {values.email !== '' &&
                        <TextField
                            label={t('input_labels.code')}
                            variant="outlined"
                            margin='normal'
                            fullWidth={true}
                            value={values.code}
                            onChange={handleChange('code')}
                            error={validation.codeCheck}
                            helperText={validation.codeHelperText}
                            required={true}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {!validation.sendCode ?
                                            <span className='registerModal-from__codeRepeatLink'
                                                  style={{cursor: 'pointer', fontSize:'12px', color: '#48A5D1'}}
                                                  onClick={handleResendCode}
                                            >
                                                {t('input_labels.send_code')}
                                            </span>
                                            : <span className='registerModal-from__codeRepeatLink'
                                                    style={{cursor: 'pointer', fontSize:'12px', color: '#48A5D1'}}
                                            >
                                                0:{currentTimeLeftForCode.seconds}
                                            </span>
                                        }
                                    </InputAdornment>
                                ),
                            }}
                        />
                    }

                    <button className='registerModal-form__submitButton' type='submit'>
                        {!validation.requestIsSend
                            ? t('button.recover')
                            : <CircularProgress sx={{color: 'white'}}/>
                        }
                    </button>
                    <div className='authModal-form__subLinks' style={{justifyContent:'flex-end'}}>
                        <p>{t('auth.have_account')}
                            <span
                                style={{cursor: 'pointer'}}
                                onClick={()=>{setAuthSteps('auth')}}
                            >
                                {t('button.sign_in')}
                            </span>
                        </p>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default RecoverModal;
