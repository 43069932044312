export function DeliveryEmptyIcon() {
    return(
        <svg width="67" height="50" viewBox="0 0 67 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M54.5726 15H47.0454V5H11.9181C9.15808 5 6.8999 7.25 6.8999 10V37.5H11.9181C11.9181 41.65 15.2803 45 19.4454 45C23.6104 45 26.9726 41.65 26.9726 37.5H42.0272C42.0272 41.65 45.3894 45 49.5544 45C53.7195 45 57.0817 41.65 57.0817 37.5H62.0999V25L54.5726 15ZM19.4454 41.25C17.3628 41.25 15.6817 39.575 15.6817 37.5C15.6817 35.425 17.3628 33.75 19.4454 33.75C21.5279 33.75 23.209 35.425 23.209 37.5C23.209 39.575 21.5279 41.25 19.4454 41.25ZM53.3181 18.75L58.2359 25H47.0454V18.75H53.3181ZM49.5544 41.25C47.4719 41.25 45.7908 39.575 45.7908 37.5C45.7908 35.425 47.4719 33.75 49.5544 33.75C51.637 33.75 53.3181 35.425 53.3181 37.5C53.3181 39.575 51.637 41.25 49.5544 41.25Z" fill="url(#paint0_linear_2994_8360)" fillOpacity="0.5"/>
            <defs>
                <linearGradient id="paint0_linear_2994_8360" x1="-19.3201" y1="-6.00001" x2="121.242" y2="17.9706" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#48A5D1"/>
                    <stop offset="1" stopColor="#E52EC7"/>
                </linearGradient>
            </defs>
        </svg>

    )
}
export function NotificationsEmptyIcon() {
    return(
        <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.5002 45.8334C27.7918 45.8334 29.6668 43.9584 29.6668 41.6667H21.3335C21.3335 43.9584 23.1877 45.8334 25.5002 45.8334ZM38.0002 33.3334V22.9167C38.0002 16.5209 34.5835 11.1667 28.6252 9.75004V8.33337C28.6252 6.60421 27.2293 5.20837 25.5002 5.20837C23.771 5.20837 22.3752 6.60421 22.3752 8.33337V9.75004C16.396 11.1667 13.0002 16.5 13.0002 22.9167V33.3334L8.8335 37.5V39.5834H42.1668V37.5L38.0002 33.3334Z" fill="url(#paint0_linear_2992_8550)" fillOpacity="0.5"/>
            <defs>
                <linearGradient id="paint0_linear_2992_8550" x1="-6.99984" y1="-5.96351" x2="79.4601" y2="2.80312" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#48A5D1"/>
                    <stop offset="1" stopColor="#E52EC7"/>
                </linearGradient>
            </defs>
        </svg>
    )
}
export function OrdersEmptyIcon() {
    return(
        <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.3" clipPath="url(#clip0_2992_8553)">
                <path d="M15.0835 37.5C12.7918 37.5 10.9377 39.375 10.9377 41.6666C10.9377 43.9583 12.7918 45.8333 15.0835 45.8333C17.3752 45.8333 19.2502 43.9583 19.2502 41.6666C19.2502 39.375 17.3752 37.5 15.0835 37.5ZM2.5835 4.16663V8.33329H6.75016L14.2502 24.1458L11.4377 29.25C11.1043 29.8333 10.9168 30.5208 10.9168 31.25C10.9168 33.5416 12.7918 35.4166 15.0835 35.4166H40.0835V31.25H15.9585C15.6668 31.25 15.4377 31.0208 15.4377 30.7291L15.5002 30.4791L17.3752 27.0833H32.896C34.4585 27.0833 35.8335 26.2291 36.5418 24.9375L44.0002 11.4166C44.1668 11.125 44.2502 10.7708 44.2502 10.4166C44.2502 9.27079 43.3127 8.33329 42.1668 8.33329H11.3543L9.396 4.16663H2.5835ZM35.9168 37.5C33.6252 37.5 31.771 39.375 31.771 41.6666C31.771 43.9583 33.6252 45.8333 35.9168 45.8333C38.2085 45.8333 40.0835 43.9583 40.0835 41.6666C40.0835 39.375 38.2085 37.5 35.9168 37.5Z" fill="url(#paint0_linear_2992_8553)"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_2992_8553" x1="2.5835" y1="4.16663" x2="50.3662" y2="13.0924" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#48A5D1"/>
                    <stop offset="1" stopColor="#E52EC7"/>
                </linearGradient>
                <clipPath id="clip0_2992_8553">
                    <rect width="50" height="50" fill="white" transform="translate(0.5)"/>
                </clipPath>
            </defs>
        </svg>
    )
}