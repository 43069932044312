import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {theme} from "./helpers/muiCustomization";
import {ThemeProvider} from "@mui/material";
import {persistStore} from "redux-persist";
import {store} from "./store/store";
import {PersistGate} from "redux-persist/integration/react";
import {Provider} from "react-redux";
import './i18n'
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers";

let persistor = persistStore(store);
export const {dispatch} = store

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={theme}>
              <Provider store={store}>
                  <PersistGate loading={<>loading...</>} persistor={persistor}>
                      <App />
                  </PersistGate>
              </Provider>
          </ThemeProvider>
      </LocalizationProvider>
  </React.StrictMode>
);

reportWebVitals();
