import {$axios} from "../http";
import {useAsync} from "react-async-hook";
import {CreateSearchParams} from "../helpers/helpers";

export const DeliveryService = {
    async CreateDelivery(deliveryInfo: any) {
        return await $axios.post('/delivery/', {...deliveryInfo})
    },
    GetDeliveryList(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get(`/delivery/` + CreateSearchParams(searchParamsObj));
        }, [CreateSearchParams(searchParamsObj)])
    },
    async DeleteDelivery(id: string) {
        return await $axios.delete(`/delivery/${id}/`)
    },
    GetDelivery(id: string | undefined) {
        return useAsync(async () => {
            return await $axios.get(`/delivery/${id}`);
        }, [id])
    },
}