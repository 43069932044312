import { $axios } from "../http";
import { useAsync } from "react-async-hook";
import { CreateSearchParams } from "../helpers/helpers";
import { GridSelectionModel } from "@mui/x-data-grid";

export const OrderService = {
  GetOrderStatusButtons() {
    return useAsync(async () => {
      return await $axios.get("/customer/statuses/");
    }, []);
  },

  GetOrders(searchParamsObj: any) {
    return useAsync(async () => {
      return await $axios.get("/orders/" + CreateSearchParams(searchParamsObj));
    }, [CreateSearchParams(searchParamsObj)]);
  },

  GetOrderInfo(id: string | undefined) {
    return useAsync(async () => {
      return await $axios.get(`/orders/${id}`);
    }, [id]);
  },
  GetOrdersForDelivery(searchParamsObj: any) {
    return useAsync(async () => {
      return await $axios.get(
        "/sorting-groups/for-delivery/" + CreateSearchParams(searchParamsObj)
      );
    }, [CreateSearchParams(searchParamsObj)]);
  },
  async GetOrder(order_id: string) {
    return await $axios.get(`/orders/${order_id}`);
  },
  async CreateOrder(order: object) {
    return await $axios.post("/orders/", order);
  },
  async DeleteOrder(id: string) {
    return await $axios.delete(`/orders/${id}/`);
  },
  async DeleteSelectedOrders(arrOfId: GridSelectionModel) {
    return await $axios.delete("/orders-bulk-delete/");
  },
  async UpdateOrder(data: any) {
    return await $axios.put(`/orders/${data.id}/`, data);
  },

  GetOrdersList(searchParamsObj: any) {
    return useAsync(async () => {
      return await $axios.get("/customer/orders/" + CreateSearchParams(searchParamsObj));
    }, [CreateSearchParams(searchParamsObj)]);
  },
};
