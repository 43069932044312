import React, {useState} from "react";
import '../../../styles/mobileVersion/Maddresses.css'
import {useTranslation} from "react-i18next";
import {AddressesService} from "../../../services/AddressesService";
import {useSelector} from "react-redux";
import {Button, IconButton, Modal, Skeleton} from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import Calculator from "../../../components/Calculator";
import EmailIcon from "@mui/icons-material/Email";
import CloseIcon from "@mui/icons-material/Close";
import {useNavigate} from "react-router-dom";

const modalInitialValues = {
    open: false,
    values: {
        price_note: '',
    },
};
function MAddresses(){
    //translation
    const {t} = useTranslation()
    const navigate = useNavigate()
    const user = useSelector((state: any)=> state.user)
    const addressesList = AddressesService.GetAddresses()
    const [modal, setModal] = useState<any>(modalInitialValues);

    const replaceKeysToData = (text: string) => {
        return text
            .replace('{client_code}', user.user.id)
            .replace('{client_phone}', user.user.phone)
            .replace('{client_full_name}', user.user.full_name)
            .replace('{client_first_name}', user.user.user.first_name)
            .replace('{client_last_name}', user.user.user.last_name)
    }
    return (
        <>
            <div className="w-full flex flex-col justify-start items-center gap-[20px] bg-mobileBackground bg-center bg-cover">
                <div className="w-full flex flex-col justify-center items-center gap-[20px] h-[200px] px-[20px] relative">
                    <h1 className="text-[white] text-[30px] font-[400]">
                        {t('addresses.title')}
                    </h1>
                </div>
            </div>
            <div className="w-full flex flex-col justify-start items-center px-[20px] pt-[20px] pb-[100px] gap-[10px]">
                {addressesList.loading
                    ?
                    <>
                        <Skeleton variant="rectangular" width={'100%'} height={342} />
                        <Skeleton variant="rectangular" width={'100%'} height={342} />
                        <Skeleton variant="rectangular" width={'100%'} height={342} />
                        <Skeleton variant="rectangular" width={'100%'} height={342} />
                        <Skeleton variant="rectangular" width={'100%'} height={342} />
                        <Skeleton variant="rectangular" width={'100%'} height={342} />
                    </>
                    : addressesList.error
                        ? 'error'
                        : addressesList.result?.data.map((address: any, index: any)=> (
                            <div key={index}
                                 className='bg-[white] w-full flex flex-col justify-start items-start shadow-md'>

                                <div className='w-full p-[10px] bg-[#48A5D11A]'>
                                    <div className='w-full flex justify-between items-center gap-[10px]'>
                                        <div className='flex items-center gap-[10px] text-[14px] text-[#000000] font-[500]'>
                                            <p>
                                                {address.country.name} - {address.type_of_transport.name}
                                            </p>
                                            <img src={address.type_of_transport.icon} alt="img"/>
                                        </div>
                                        <div
                                            className='p-[4px] bg-[#48A5D11A] flex gap-[10px] items-center rounded-[8px]'>
                                            <p className='text-[12px] text-[#48A5D1] font-[500]'>{t('addresses.tariff')}:</p>
                                            <p className='text-[14px] text-[#141414] font-[400]'>{address.tariff} USD</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='p-[10px] w-full flex flex-col justify-start items-start gap-[20px]'>
                                    <div className='w-full flex flex-col justify-start items-start gap-[10px]'>
                                        <p className='text-[12px] text-[#141414] font-[500]'>
                                            {replaceKeysToData(`${address.title}`)}
                                        </p>
                                        <p className='text-[12px] text-[#141414] font-[400] whitespace-pre-wrap'>
                                            {replaceKeysToData(`${address.subtitle}`)}
                                        </p>
                                    </div>
                                    <div className='w-full flex flex-col justify-start items-start'>
                                        <p className='text-[12px] text-[#6E6E6E] font-[600]'>{t('addresses.weekends')}: {address.weekend}</p>
                                        <p className='text-[12px] text-[#6E6E6E] font-[600]'>{t('addresses.delivery')}:  {address.delivery_period}</p>
                                    </div>
                                    <div className='w-full flex flex-col justify-start items-end gap-[20px]'>
                                        <div className='w-full flex justify-start items-center gap-[10px]'>
                                            <Button size='small' startIcon={<LocalOfferIcon />}
                                                    onClick={()=>{
                                                        setModal({
                                                            ...modalInitialValues,
                                                            open: true,
                                                            values:{
                                                                ...modalInitialValues.values,
                                                                price_note: address.price_note
                                                            }
                                                        })
                                                    }}
                                            >
                                                Прайс
                                            </Button>

                                            <Calculator mobile={true} address={address}/>

                                            {address.sms_link !== null && address.sms_link !== '' &&
                                                <Button size='small' startIcon={<EmailIcon/>}
                                                        onClick={()=> window.open(address.sms_link, '_blank')}
                                                >
                                                    СМС-шлюз
                                                </Button>
                                            }
                                        </div>
                                        <Button variant='contained' size='small' color='blue' onClick={()=>{
                                            navigate({
                                                pathname: `/address/${address.id}`
                                            })
                                        }}>
                                            Подробнее
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))
                }
            </div>
            <Modal
                open={modal.open}
                onClose={() => setModal(modalInitialValues)}
                sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingX: '20px'}}
            >
                <div className="w-full flex flex-col justify-start items-center gap-[10px] bg-white p-[20px] relative">
                    <IconButton
                        sx={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                        }}
                        onClick={() => setModal(modalInitialValues)}
                    >
                        <CloseIcon/>
                    </IconButton>
                    <h2 className="text-[30px] font-[600] text-[#1E1C2A] mb-[20px]">
                        Прайс
                    </h2>

                    <div className='w-full text-[12px]' dangerouslySetInnerHTML={{__html: modal.values.price_note}}></div>
                </div>
            </Modal>
        </>
    );
}

export default MAddresses;