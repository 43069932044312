import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import RU from '../src/locales/ru/translation.json'
import EN from '../src/locales/en/translation.json'
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)


i18n
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: true,
        resources:{
            ru: {
                translation: RU
            },
            en: {
                translation: EN
            }
        },
        interpolation: {
            escapeValue: false // react already safes from xss
        }

    });

export default i18n;