import React from "react";
import '../../styles/mobileVersion/Mtransactions.css'
import {Pagination,} from "@mui/material";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import {useTranslation} from "react-i18next";
import {TransactionsService} from "../../services/TransactionsService";


function MTransactions(){
    //translation
    const {t} = useTranslation()

    const [filterValue, setFilterValue] = React.useState<any>({
        page: 1,
    })
    let transactions = TransactionsService.GetTransactions(filterValue)
    return (
        <>
            <div
                className="w-full flex flex-col justify-start items-center gap-[20px] bg-mobileBackground bg-center bg-cover">
                <div
                    className="w-full flex flex-col justify-center items-center gap-[20px] h-[200px] px-[20px] relative">
                    <h1 className="text-[white] text-[30px] font-[400]">
                        {t('transaction.title')}
                    </h1>
                </div>
            </div>
            <div className="w-full flex flex-col justify-start items-center px-[20px] pt-[20px] pb-[100px] gap-[10px]">
                {transactions.loading
                    ? <CircularProgress style={{marginTop: '20px', marginBottom: '20px'}} />
                    :
                    !transactions.error &&
                    transactions.result?.data.results.length === 0
                        ? t('mobile.no_data')
                        :
                        transactions.result?.data.results.map((order: any)=>(
                            <div className="MTransactions__transaction" key={order.id}>
                                <div className="MTransactions-transaction__title">
                                    <p>{order.type.name}</p>
                                    <span>{(moment(order.created_at)).format('DD.MM.YYYY').toString()}</span>
                                </div>
                                <div className="MTransactions-transaction__price">
                                    {order.type.slug === "replenishment_balance" && <p style={{color: 'green'}}>+{order.amount}</p>}
                                    {order.type.slug === "debiting_bonus" && <p style={{color: 'red'}}>-{order.amount}</p>}
                                    {order.type.slug === "debiting_balance" && <p style={{color: 'red'}}>-{order.amount}</p>}
                                    {order.type.slug === "replenishment_bonus" && <p style={{color: 'green'}}>+{order.amount}</p>}

                                    {order.type.slug === "replenishment_balance" && <span style={{color: 'green'}}>Приход</span>}
                                    {order.type.slug === "debiting_bonus" && <span style={{color: 'red'}}>Расход</span>}
                                    {order.type.slug === "debiting_balance" && <span style={{color: 'red'}}>Расход</span>}
                                    {order.type.slug === "replenishment_bonus" && <span style={{color: 'green'}}>Приход</span>}
                                </div>
                            </div>
                        ))
                }
                <Pagination
                    style={{marginTop: '20px'}}
                    count={!transactions.loading && !transactions.error ? transactions.result?.data.total_pages : 1}
                    page={!transactions.loading && !transactions.error ? transactions.result?.data.current_page : 1}
                    onChange={(event, value:number)=>{
                        setFilterValue({
                            ...filterValue,
                            page: value,
                        })
                    }}
                />
            </div>
        </>

    );
}

export default MTransactions;