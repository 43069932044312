import {useAsync} from "react-async-hook";
import {$axios} from "../http";
import {CreateSearchParams} from "../helpers/helpers";
import {createSearchParams} from "react-router-dom";
import {GridSelectionModel} from "@mui/x-data-grid";

export const NotificationsService = {
    GetNotifications(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/notifications/' + CreateSearchParams(searchParamsObj));
        }, [`?${createSearchParams(searchParamsObj)}`])
    },
    async GetNotificationsInfo(id: string) {
        return await $axios.get(`/notifications/${id}/`)
    },
    GetNotificationsInfoByUseAsync(id: string | undefined) {
        return useAsync(async () => {
            return await $axios.get(`/notifications/${id}/`);
        }, [id])
    },
    async DeleteNotifications(id: string) {
        return await $axios.delete(`/notifications/${id}/`)
    },
    async PostSelectedNotifications(arrOfId: GridSelectionModel, flag: string) {
        return await $axios.post('/notification-bulk-update/', {"notification_ids": arrOfId, "flag": flag})
    }
}