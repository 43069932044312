import React, {useEffect, useState} from 'react';
import '../../styles/desktopVersion/history.css'
import {useNavigate} from "react-router-dom";
import {DataGrid, GridColDef, GridFooterContainer} from "@mui/x-data-grid";
import {Pagination} from "@mui/material";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";
import {HistoryService} from "../../services/HistoryService";

function History() {
    //translation
    const {t} = useTranslation()

    let navigate = useNavigate();
    const [filterValue, setFilterValue] = React.useState<any>({
        page: 1,
    })
    const [historyData, setHistoryData] = useState<any>([])
    const historyColumns: GridColDef[] = [
        {field: 'id', headerName: 'ID', flex: 1, sortable: false, hide: true},
        {field: 'created_at', headerName: `${t('order_table.created_at')}`, sortable: false, flex: 1},
        {field: 'country', headerName: `${t('order_table.country')}`, sortable: false, flex: 1},
        {field: 'name', headerName: `${t('order_table.name')}`, sortable: false, flex: 1},
        {field: 'tracking_number', headerName: `${t('order_table.tracking_number')}`, sortable: false, flex: 1},
        {field: 'weight', headerName: `${t('order_table.weight')}`, sortable: false, flex: 1},
        {field: 'total_price', headerName: `${t('order_table.total_price')}`, sortable: false, flex: 1},
    ];
    let historyInfo = HistoryService.GetHistory(filterValue)
    useEffect(() => {
        if (!historyInfo.loading && !historyInfo.error) {
            setHistoryData(historyInfo.result?.data.results.map((order: any) => ({
                id: order.id,
                created_at: (moment(order.created_at)).format('DD.MM.YYYY').toString(),
                country: order.country.name,
                name: order.comment,
                tracking_number: order.tracking_number,
                weight: order.weight,
                total_price: order.total_price,
            })))
        }
    }, [historyInfo.error, historyInfo.loading, historyInfo.result?.data.results])
    return (
        <>
            <h1 className='text-[30px] text-black font-[400] mb-[50px]'>{t('history.title')}</h1>
            <div className="historyPage__mainContainer">
                <div style={{width: '100%', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)'}}>
                    <DataGrid
                        rows={historyData}
                        columns={historyColumns}
                        disableColumnFilter
                        checkboxSelection
                        keepNonExistentRowsSelected
                        isRowSelectable={() => false}
                        disableColumnMenu
                        disableSelectionOnClick
                        autoHeight
                        localeText={{
                            noRowsLabel: "Нет информации",
                          }}
                        onRowDoubleClick={(params) => {
                            navigate(`/orders/${params.row.id}`)
                        }}
                        loading={historyInfo.loading}
                        components={{
                            Footer: () => {
                                return (
                                    <GridFooterContainer>
                                        <Pagination
                                            count={!historyInfo.loading && !historyInfo.error ? historyInfo.result?.data.total_pages : 1}
                                            page={!historyInfo.loading && !historyInfo.error ? historyInfo.result?.data.current_page : 1}
                                            onChange={(event, value: number) => {
                                                setFilterValue({
                                                    ...filterValue,
                                                    page: value,
                                                })
                                            }}
                                        />
                                    </GridFooterContainer>
                                );
                            },
                        }}
                    />
                </div>
            </div>
        </>
    );
}

export default History;