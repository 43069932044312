import {useAsync} from "react-async-hook";
import {$axios} from "../http";
import {CreateSearchParams} from "../helpers/helpers";
import {createSearchParams} from "react-router-dom";

export const HistoryService = {
    GetHistory(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/orders/issued/' + CreateSearchParams(searchParamsObj));
        }, [`?${createSearchParams(searchParamsObj)}`])
    }
}